import React, { useEffect, useState } from 'react'
import '../styles/components/directions.scss'
import { Direction } from '../util/const'
import { useTranslation } from 'react-i18next'

const directionList = [Direction.Up, Direction.Right, Direction.Down, Direction.Left]

const initialDirections = {
    FEEDING: Direction.Up,
    PRINTING: Direction.Up,
    FLUTE: Direction.Up,
}

const Directions = ({
    data,
    onChange,
}) => {

    const { t } = useTranslation()
    const [directions, setDirections] = useState(initialDirections)

    const changeDirection = (type) => {
        const currentIndex = directionList.indexOf(directions[type])
        const updatedDirections = {
            ...directions,
            [type]: currentIndex >= directionList.length - 1 ? directionList[0] : directionList[currentIndex + 1],
        }
        
        setDirections(updatedDirections)
        
        if (onChange) onChange(type, updatedDirections[type])
    }

    useEffect(() => {
        setDirections({
            ...initialDirections,
            ...(data?.FEEDING && {
                FEEDING: data.FEEDING,
            }),
            ...(data?.PRINTING && {
                PRINTING: data.PRINTING,
            }),
            ...(data?.FLUTE && {
                FLUTE: data.FLUTE,
            }),
        })
    }, [data])

    return (
        <div className='directions'>
            <div className='directions-item'>
                <div
                    className={`directions-item-icon directions-feeding direction-${directions.FEEDING}`}
                    onClick={() => changeDirection('FEEDING')}
                />
                <h4>{ t('blueprint.FEEDING') }</h4>
            </div>
            <div className='directions-item'>
                <div
                    className={`directions-item-icon directions-printing direction-${directions.PRINTING}`}
                    onClick={() => changeDirection('PRINTING')}
                />
                <h4>{ t('blueprint.PRINTING') }</h4>
            </div>
            <div className='directions-item'>
                <div
                    className={`directions-item-icon directions-flute direction-${directions.FLUTE}`}
                    onClick={() => changeDirection('FLUTE')}
                />
                <h4>{ t('blueprint.FLUTE') }</h4>
            </div>
        </div>
    )
}

export default Directions