import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateClientContact, RemoveClientContact, UpdateClientContact } from '../graphql/mutations'
import { GetClientContacts } from '../graphql/queries'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import Checkbox from './Checkbox'

const initialContact = {
    CONTACT: null,
    PHONE: null,
    KLIENT_ID: null,
    FAX: null,
    EMAIL: null,
    SKYPE: null,
    OLD: null,
    MAIN: null,
    MOBILE: null,
}

const rowHeaders = [
    {
        value: 'CONTACT',
        label: 'Kontaktisik',
    },
    {
        value: 'PHONE',
        label: 'Telefon',
    },
    {
        value: 'MOBILE',
        label: 'Mobiil',
    },
    {
        value: 'FAX',
        label: 'Fax',
    },
    {
        value: 'EMAIL',
        label: 'Email',
    },
    {
        value: 'SKYPE',
        label: 'Skype',
    },
    {
        value: 'OLD',
        label: 'Aegunud',
    },
]

const ClientContacts = ({ clientId }) => {

    const { t } = useTranslation()
    const [contacts, setContacts] = useState([])
    const [selectedContact, setSelectedContact] = useState(null)
    const [newContact, setNewContact] = useState(initialContact)
    const [showModal, setShowModal] = useState(false)

    const [createItem] = useMutation(CreateClientContact)
    const [removeItem] = useMutation(RemoveClientContact)
    const [updateItem] = useMutation(UpdateClientContact)

    const { refetch } = useQuery(GetClientContacts, {
        variables: {
            clientId: parseInt(clientId)
        },
        onCompleted: (data) => {
            const { getClientContacts } = data
            if (getClientContacts) setContacts(getClientContacts)
        }
    })

    const handleAdd = async () => {
        try {
            const res = await createItem({
                variables: {
                    data: {
                        ...newContact,
                        KLIENT_ID: parseInt(clientId),
                    },
                },
            })
            closeModal()
        } catch (err) {
            console.log('handleAdd', err)
        }
    }

    const handleDelete = async (id) => {
        try {
            const res = await removeItem({
                variables: {
                    id: parseInt(id),
                },
            })

            refetch()
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const closeModal = () => {
        setNewContact(initialContact)
        setSelectedContact(null)
        setShowModal(false)
        refetch()
    }

    const setField = (field, value) => {
        setNewContact({
            ...newContact,
            [field]: value,
        })
    }

    const setSelectedField = (field, value) => {
        setSelectedContact({
            ...selectedContact,
            [field]: value,
        })
    }

    const handleUpdate = async () => {
        try {
            const res = await updateItem({
                variables: {
                    id: parseInt(selectedContact.ID),
                    data: {
                        CONTACT: selectedContact.CONTACT,
                        PHONE: selectedContact.PHONE,
                        EMAIL: selectedContact.EMAIL,
                        MOBILE: selectedContact.MOBILE,
                        SKYPE: selectedContact.SKYPE,
                        FAX: selectedContact.FAX,
                        OLD: selectedContact.OLD,
                    },
                },
            })
            closeModal()
        } catch (err) {
            console.log('handleUpdate', err)
        }
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions justify-content--flex-end'>
                {
                    selectedContact ?
                    <Button
                        label={t('Uuenda kontakti')}
                        onClick={handleUpdate}
                    />
                    :
                    <Button
                        label={t('Lisa kontakt')}
                        onClick={handleAdd}
                    />
                }
            </div>
        )
    }

    return (
        <div className='printouts'>
            <div className='printouts-list'>
                { contacts.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                contacts.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        onClick={() => {
                                            setSelectedContact(row)
                                            setShowModal(true)
                                        }}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { row[headerItem.value] }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={t('Kustuta')}
                                                onClick={() => handleDelete(row.ID)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Kontakte pole lisatud') }
                    </div>
                }
            </div>
            <Button
                label={t('Lisa kontakt')}
                onClick={() => setShowModal(true)}
            />

            <Modal
                title={selectedContact ? t('Uuenda kontakti') : t('Lisa kontakt')}
                show={showModal}
                close={closeModal}
                renderActions={renderModalActions}
            >
                {
                    selectedContact ?
                    <>
                        <Input
                            label={t('Kontaktisik')}
                            value={selectedContact.CONTACT}
                            onChange={(e) => setSelectedField('CONTACT', e.target.value)}
                        />
                        <Input
                            label={t('Telefon')}
                            value={selectedContact.PHONE}
                            onChange={(e) => setSelectedField('PHONE', e.target.value)}
                        />
                        <Input
                            label={t('Mobiil')}
                            value={selectedContact.MOBILE}
                            onChange={(e) => setSelectedField('MOBILE', e.target.value)}
                        />
                        <Input
                            label={t('Fax')}
                            value={selectedContact.FAX}
                            onChange={(e) => setSelectedField('FAX', e.target.value)}
                        />
                        <Input
                            label={t('Email')}
                            value={selectedContact.EMAIL}
                            onChange={(e) => setSelectedField('EMAIL', e.target.value)}
                        />
                        <Input
                            label={t('Skype')}
                            value={selectedContact.SKYPE}
                            onChange={(e) => setSelectedField('SKYPE', e.target.value)}
                        />
                        <Checkbox
                            label={t('Aegunud')}
                            value={selectedContact.OLD ? true : false}
                            onChange={(e) => setSelectedField('OLD', e.target.checked ? 1 : 0)}
                        />
                    </>
                    :
                    <>
                        <Input
                            label={t('Kontaktisik')}
                            value={newContact.CONTACT}
                            onChange={(e) => setField('CONTACT', e.target.value)}
                        />
                        <Input
                            label={t('Telefon')}
                            value={newContact.PHONE}
                            onChange={(e) => setField('PHONE', e.target.value)}
                        />
                        <Input
                            label={t('Mobiil')}
                            value={newContact.MOBILE}
                            onChange={(e) => setField('MOBILE', e.target.value)}
                        />
                        <Input
                            label={t('Fax')}
                            value={newContact.FAX}
                            onChange={(e) => setField('FAX', e.target.value)}
                        />
                        <Input
                            label={t('Email')}
                            value={newContact.EMAIL}
                            onChange={(e) => setField('EMAIL', e.target.value)}
                        />
                        <Input
                            label={t('Skype')}
                            value={newContact.SKYPE}
                            onChange={(e) => setField('SKYPE', e.target.value)}
                        />
                        <Checkbox
                            label={t('Aegunud')}
                            value={newContact.OLD ? true : false}
                            onChange={(e) => setField('OLD', e.target.checked ? 1 : 0)}
                        />
                    </>
                }
            </Modal>
        </div>
    )
}

export default ClientContacts