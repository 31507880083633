import { ApolloClient, InMemoryCache, from, ApolloProvider, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from 'apollo-link-context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import jwtDecode from 'jwt-decode'
import { useMemo } from 'react'
import { useAuth } from './providers/Auth'
import { IsDev } from './util/const'

const wsLink = new GraphQLWsLink(createClient({
    url: !IsDev ? process.env.REACT_APP_WS : 'ws://localhost:3050/subscriptions',
    connectionParams: {
        authToken: () => {
            const token = localStorage.getItem('token')
            return token
        },
    },
}))

const httpLink = createUploadLink({
    uri: !IsDev ? process.env.REACT_APP_GRAPHQL : 'http://localhost:3050/graphql',
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    httpLink,
)


const AppWrapper = ({ children }) => {

    const { signOut, signIn, loaded } = useAuth()

    const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
        console.log('ERROR link:', graphQLErrors)
        if (graphQLErrors?.length > 0 && graphQLErrors[0].message === 'Invalid credentials') {
            signOut()
        }
    })

    const authLink = setContext( async (_, { headers }) => {
        let token = localStorage.getItem('token')

        if (token) {
            const decoded = jwtDecode(token)

            if ((Date.now() + (5000 * 60)) >= decoded.exp * 1000) {
                const res = await fetch(`${!IsDev ? process.env.REACT_APP_API : 'http://localhost:3050'}/refreshtoken`, {
                    method: 'POST',
                    body: JSON.stringify({ token: token }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                if (res.status === 200) {
                    const json = await res.json()

                    if (json?.token) signIn(json?.token)
                    token = json?.token
                }
            }
        }

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    })


    const client = useMemo(() => new ApolloClient({
        link: from([
            authLink,
            errorLink,
            splitLink,
        ]),
        connectToDevTools: IsDev,
        cache: new InMemoryCache(),
    }), [])

    if (!loaded) return <></>

    return (
        <ApolloProvider client={client}>
            { children }
        </ApolloProvider>
    )
}

export default AppWrapper