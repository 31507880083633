import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { BufferQuery, GetDeliveryNote, GetDeliveryNoteRows } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateDeliveryNote, CreateDeliveryNoteRow, DuplicateBufferItem, RemoveBufferItem, SendOrderEmail, SendToBookkeeping, SetBufferToOrder } from '../../graphql/mutations'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import { useNotification } from '../../providers/Notification'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import { formatDate, removeTags } from '../../util/format'
import Filters from '../../components/Filters'
import FilterTable from '../../components/FilterTable'
import { getProductStatusClass } from '../../util/colors'
import { pdf } from '@react-pdf/renderer'
import { BufferCreated, BuffersUpdated } from '../../graphql/subscriptions'
import PdfViewer from '../../components/PdfViewer'
import DeliveryNoteInvoiceDocument from '../../documents/deliverynote-invoice-document'
import DateInput from '../../components/DateInput'
import BufferOrderDocument from '../../documents/buffer-order-document'
import ConfirmModal from '../../components/ConfirmModal'
import BufferModal from '../../components/buffer-modal'
import { useConstants } from '../../providers/Constants'
import writeXlsxFile from 'write-excel-file'
import { useAuth } from '../../providers/Auth'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Rida number',
    },
    {
        value: 'ORDER_DATE',
        label: 'Tellimuse kuup.',
    },
    {
        value: 'PRODUCTION_CODE',
        label: 'Toote kood',
    },
    {
        value: 'NAME',
        label: 'Klient',
    },
    {
        value: 'KLIENT_ORDER',
        label: 'Kliendi tellimus',
    },
    {
        value: 'TELLIMUSNO',
        label: 'Tellimus',
    },
    {
        value: 'KLIENT_BOXCODE',
        label: 'Kliendi tootekood',
    },
    {
        value: 'MOOT',
        label: 'Mõõt',
    },
    {
        value: 'MATERIAL',
        label: 'Materjal',
    },
    {
        value: 'FSC',
        label: 'FSC',
    },
    {
        value: 'PRICE',
        label: 'Hind',
    },
    {
        value: 'KOGUS',
        label: 'Kogus',
    },
    {
        value: 'RKOGUS',
        label: 'Tegelik kogus',
    },
    {
        value: 'BOX',
        label: 'FEFCO',
    },
    {
        value: 'TRYKK',
        label: 'Trükk',
    },
    {
        value: 'DEADLINE',
        label: 'Tähtaeg',
    },
    {
        value: 'NEW_DEADLINE',
        label: 'Reaalne tähtaeg',
    },
    {
        value: 'START_DATE',
        label: 'Tootmise algus',
    },
    {
        value: 'FIN_DATE',
        label: 'Tootmise lõpp',
    },
    {
        value: 'ALUSEID',
        label: 'Aluseid',
    },
    {
        value: 'TRANSPORDIFIRMA',
        label: 'Transport',
    },
    {
        value: 'KLIENT_INFORMED',
        label: 'Teavitatud',
    },
    {
        value: 'ADDRESS',
        label: 'Tarne aadress',
    },
    {
        value: 'KOMMENTAAR',
        label: 'Kommentaar',
    },
    {
        value: 'SISESTAJA_NIMI',
        label: 'Sisestaja',
    },
    {
        value: 'HALDURNAME',
        label: 'Müügihaldur',
    },
    {
        value: 'ETTEMAKS',
        label: 'Ettemaks',
    },
    {
        value: 'HINNAPAKKUMINE',
        label: 'Hinnapakkumine',
    },
    {
        value: 'DEPT',
        label: 'Võlglane',
    },
    {
        value: 'TRANSPORT_DATE',
        label: 'Tellitud',
    },
    {
        value: 'ALUS_MOOT',
        label: 'Aluse mõõt',
    },
    {
        value: 'MAKSEAEG',
        label: 'Makseaeg',
    },
    {
        value: 'SLITTER',
        label: 'Slitter',
    },
    {
        value: 'FLEXO',
        label: 'Flexo',
    },
    {
        value: 'TIGEL',
        label: 'Tigel',
    },
    {
        value: 'VERONA',
        label: 'Verona',
    },
    {
        value: 'TITAN',
        label: 'Titan',
    },
    {
        value: 'SRE',
        label: 'SRE',
    },
    {
        value: 'INLINE',
        label: 'In-Line',
    },
    {
        value: 'SLOTTER',
        label: 'Slotter',
    },
    {
        value: 'KLIIM',
        label: 'K. liim',
    },
    {
        value: 'ALIIM',
        label: 'A. liim',
    },
    {
        value: '_3LIIM',
        label: '3p. liim',
    },
    {
        value: 'LAMINAATOR',
        label: 'Laminaator',
    },
    {
        value: 'AFD',
        label: 'AFD',
    },
    {
        value: 'FPR',
        label: 'FPR',
    },
    {
        value: 'PALLET',
        label: 'PALLET',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.buffersUpdated
    if (!newData || !prev.bufferQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    const itemRows = prev.bufferQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })

    return Object.assign({}, prev, {
        bufferQuery: {
            ...prev.bufferQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.bufferCreated
    if (!newData || prev.bufferQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    return Object.assign({}, prev, {
        bufferQuery: {
            ...prev.bufferQuery,
            rows: [
                newData,
                ...prev.bufferQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: BuffersUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: BufferCreated,
        updateQuery: handleCreateSubscription,
    },
]

const BuffersScreen = () => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const tableRef = useRef()
    const { user } = useAuth()
    const { constants } = useConstants()
    const [deleteModal, setDeleteModal] = useState(false)
    const [noteCreated, setNoteCreated] = useState(false)
    const [notePallets, setNotePallets] = useState(1)
    const [notePaidPallets, setNotePaidPallets] = useState(false)
    const [noteDate, setNoteDate] = useState(new Date())
    const [selectedRows, setSelectedRows] = useState([])
    const [extraFilters, setExtraFilters] = useState()
    const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false)
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [showBufferModal, setShowBufferModal] = useState(false)

    const [createNote] = useMutation(CreateDeliveryNote)
    const [createNoteRow] = useMutation(CreateDeliveryNoteRow)
    const [sendToBookkeeping] = useMutation(SendToBookkeeping)
    const [makeOrder] = useMutation(SetBufferToOrder)
    const [sendEmail] = useMutation(SendOrderEmail)
    const [removeBufferItem] = useMutation(RemoveBufferItem)
    const [duplicateRow] = useMutation(DuplicateBufferItem)

    const { refetch: getDeliveryNote } = useQuery(GetDeliveryNote, {
        fetchPolicy: 'no-cache',
    })

    const { refetch: getRows } = useQuery(GetDeliveryNoteRows, { skip: true })

    useEffect(() => {
        const currentDate = new Date()
        const filters = {
            year: `${currentDate.getFullYear()}`,
            month: `${currentDate.getMonth() + 1}`,
        }
        handleFilterChange(filters)
    }, [])

    const handleRowDoubleClick = (item) => {
        const rows = tableRef.current?.getTableRowsData()
        localStorage.setItem('buffer', JSON.stringify(rows))
        window.open(`/buffer/${item.ID}`, 'Buffer andmed')
    }

    const handleRowClick = (item) => {
        if (selectedRows.some(x => x.ID === item.ID)) {
            setSelectedRows(selectedRows.filter(x => x.ID !== item.ID))
            return
        }
        setSelectedRows([
            ...selectedRows,
            item,
        ])
    }

    const handleCreateDelivery = async () => {
        if (selectedRows.length < 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Ühtegi rida pole valitud'),
                    type: 'error',
                },
            })
            return
        }
        const client = selectedRows[0].KLIENT_ID
        let errors = false

        selectedRows.forEach(x => {
            if (!x.START_DATE) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: `${x.ORDERNO} ${t(`ei ole veel tootmises`)}`,
                    },
                })
                errors = true
            }

            if (!x.PARTIAL_AMOUNT) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: `${x.ORDERNO} ${t(`puudub väljastamiseks kogus`)}`,
                    },
                })
                errors = true
            }
        })

        if (errors) return

        try {
            const { data } = await createNote({
                variables: {
                    data: {
                        CLIENT_ID: client,
                        PALLETS: parseInt(notePallets),
                        PALLET_COSTS: notePaidPallets ? 1 : 0,
                    },
                },
            })

            if (data && data.createDeliveryNote) {
                await Promise.all(selectedRows.map(async (item) => {
                    await createNoteRow({
                        variables: {
                            data: {
                                INVOICE_ID: parseInt(data.createDeliveryNote),
                                PRODUCT_ID: parseInt(item.ID),
                                AMOUNT: parseInt(item.PARTIAL_AMOUNT),
                            },
                        },
                    })
                }))
                const { data: deliveryNote } = await getDeliveryNote({
                    id: parseInt(data.createDeliveryNote),
                })
                const { data: deliveryNoteRows } = await getRows({
                    noteId: parseInt(data.createDeliveryNote),
                })

                if (deliveryNote?.getDeliveryNote && deliveryNoteRows?.getDeliveryNoteRows) {
                    setNoteCreated({
                        ...deliveryNote.getDeliveryNote,
                        rows: deliveryNoteRows.getDeliveryNoteRows,
                        PALLETPRICE: constants?.PALLETPRICE_DOM,
                    })

                    dispatch({
                        type: 'ADD',
                        payload: {
                            content: t('Saateleht loodud'),
                            type: 'success',
                        },
                    })

                    return
                }
            }
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Viga saatelehe loomisel'),
                    type: 'error',
                },
            })
        } catch (err) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Viga saatelehe loomisel'),
                    type: 'error',
                },
            })
            console.log('createInvoice', err)
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters({
            ...filters,
            ...(filters?.exported === 'true' && {
                exported: true,
            }),
        })
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ORDER_DATE':
            case 'FIN_DATE':
            case 'START_DATE':
            case 'DEADLINE':
            case 'NEW_DEADLINE':
            case 'TRANSPORT_DATE':
            case 'SHIPPED':
                return formatDate(new Date(row[field]))
            case 'client.NAME':
                return row?.client?.NAME
            case 'order.ORDERNO':
                return row?.order?.ORDERNO
            case 'client.MAKSEAEG':
                return row?.client?.MAKSEAEG
            case 'client.DEPT':
                return row?.client?.DEPT
            case 'client.manager.RNAME':
                return row?.client?.manager?.RNAME
            case 'creator.RNAME':
                return row?.creator?.RNAME
        }
    }

    const handleBookkeeping = async () => {
        try {
            const pdfFile = pdf(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            pdfFile.updateContainer(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf' })

            const { data } = await sendToBookkeeping({
                variables: {
                    id: parseInt(noteCreated.ID),
                    file,
                },
            })

            if (data?.sendToBookkeeping === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Edastatud raamatupidamisse'),
                        type: 'success',
                    },
                })
                return
            }

            dispatch({
                type: 'ADD',
                payload: {
                    content: t(`Viga raamatupidamisse saatmisel: ${data?.sendToBookkeeping}`),
                    type: 'error',
                },
            })
        } catch (err) {
            console.log('handleBookkeeping:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    content: err,
                    type: 'error',
                },
            })
        }
    }

    const renderModalActions = () => {
        if (noteCreated) return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Ei')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Ainult prindi')}
                    onClick={async () => {
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
                <Button
                    label={t('Jah')}
                    onClick={async () => {
                        await handleBookkeeping()
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
            </div>
        )
        return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Katkesta')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Loo saateleht')}
                    onClick={handleCreateDelivery}
                />
            </div>
        )
    }

    const closeDeliveryNoteModal = () => {
        setShowDeliveryNoteModal(false)
    }

    const resetNoteState = () => {
        setNoteCreated(null)
        setNotePaidPallets(false)
        setNotePallets(1)
        setNoteDate(new Date())
    }

    const handleShowModal = () => {
        setShowPdfModal(true)
    }

    const handleCloseModal = () => {
        setShowPdfModal(false)
    }

    const handleEmail = async (documentData, orderNo) => {
        if (documentData?.length < 1) return

        try {
            const pdfFile = pdf(<BufferOrderDocument data={documentData} constants={constants} />)
            pdfFile.updateContainer(<BufferOrderDocument data={documentData} constants={constants} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf' })

            const res = await sendEmail({
                variables: {
                    orderNo,
                    file,
                    buffer: true,
                },
            })

            if (res?.data?.sendOrderEmail === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Email saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Emaili saatmine ebaõnnestus')
                    },
                })
            }
        } catch (err) {
            console.log('handleEmail:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const handleMakeOrderPreview = () => {
        const orderPdf = pdf(<BufferOrderDocument data={selectedRows} constants={constants} />)
        orderPdf.updateContainer(<BufferOrderDocument data={selectedRows} constants={constants} />)

        handleShowModal()
    }

    const handleMakeOrder = async () => {
        try {
            const ids = selectedRows.map(x => x.ID)
            const { data } = await makeOrder({
                variables: {
                    ids,
                },
            })

            if (data?.setBufferToOrder) {
                const tableRows = tableRef.current?.getTableRowsData()
                const updatedRows = []

                selectedRows.forEach(x => {
                    const matchRow = tableRows.find(i => x.ID === i.ID)

                    if (matchRow) updatedRows.push({
                        ...x,
                        TELLIMUSNO: data.setBufferToOrder,
                    })
                })

                handleCloseModal()

                setSelectedRows(updatedRows)

                handleShowModal()

                await handleEmail(updatedRows, parseInt(data.setBufferToOrder))
            }
        } catch (err) {
            console.log('openPdf:', err)
        }
    }

    const handleRemoveItem = async () => {
        if (selectedRows.length > 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Rohkem kui üks tellimus valitud'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const res = await removeBufferItem({
                variables: {
                    id: parseInt(selectedRows[0].ID),
                },
            })

            if (res?.data?.removeBufferItem === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t(`Toode ${selectedRows[0].ORDERNO} kustutatud`)
                    },
                })
                setSelectedRows([])
                setDeleteModal(false)
                tableRef.current?.refresh()
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t(`Toote ${selectedRows[0].ORDERNO} kustutamine ebaõnnestus`)
                    },
                })
            }
        } catch (err) {
            console.log('removeBufferItem', err)
        }
    }

    const handleIssueSuccess = ({ amount }) => {
        setShowBufferModal(false)
        tableRef.current?.refresh()

        dispatch({
            type: 'ADD',
            payload: {
                type: 'success',
                content: `${amount > 1 ? `${amount} toodet` : `Toode`} edukalt väljastatud`
            },
        })
    }

    const handleExcel = async () => {
        if (!tableRef.current) return
        const dataset = {
            columns: [],
            data: [],
        }
        Array.from(tableRef.current.getTableRows()).map((row, index) => {
            const items = []
            Array.from(row.cells).map((cell, cellIndex) => {
                if (index === 0) {
                    items.push({
                        value: removeTags(cell.innerHTML),
                        fontWeight: 'bold',
                    })
                } else {
                    switch (cellIndex) {
                        case 1:
                        case 15:
                        case 16:
                        case 17:
                        case 18: {
                            const value = removeTags(cell.innerHTML).split('.')
                            const date = value?.length > 2 ? new Date(`${value[2]}-${value[1]}-${value[0]}T00:00:00.000Z`) : null
                            items.push({
                                value: date,
                                type: Date,
                                format: 'DD.MM.YYYY',
                            })
                            break
                        }
                        case 0:
                        case 5:
                        case 10:
                        case 11:
                        case 12:
                        case 14:
                        case 19:
                        case 21:
                        case 26:
                        case 27:
                        case 28:
                        case 32:
                        case 33:
                        case 34:
                        case 35: 
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 42:
                        case 43:
                        case 44:
                        case 45: {
                            const value = removeTags(cell.innerHTML)
                            items.push({
                                value: value ? parseInt(value, 10) : null,
                                type: Number,
                            })
                            break
                        }
                        case 46: {
                            const value = removeTags(cell.innerHTML)
                            items.push({
                                value: value ? parseFloat(value) : null,
                                type: Number,
                            })
                            break
                        }
                        default: {
                            items.push({
                                value: removeTags(cell.innerHTML),
                                type: String,
                            })
                            break
                        }
                    }
                }
            })

            if (index === 0) {
                dataset.columns = items
            }
            else dataset.data.push(items)
        })
        
        await writeXlsxFile([
            dataset.columns,
            ...dataset.data,
        ], {
            fileName: `buffers-${new Date().getTime()}.xlsx`,
            stickyRowsCount: 1
        })
        
    }

    const duplicate = async () => {
        if (selectedRows.length > 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Rohkem kui üks tellimus valitud'),
                    type: 'error',
                },
            })
            return
        }
        if (selectedRows.length <= 0) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Vali tellimus'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const { data } = await duplicateRow({
                variables: {
                    id: parseInt(selectedRows[0].ID),
                },
            })
            if (data && data.duplicateBufferItem !== 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Tellimus kopeeritud'),
                        type: 'success',
                    },
                })
                tableRef.current.refresh()
            }
        } catch (err) {
            console.log('duplicate', err)
        }
    }

    return (
        <div className='productions'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Buffer ladu')}</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Koosta tellimus')}
                            disabled={selectedRows?.length < 1}
                            onClick={() => handleMakeOrderPreview()}
                        />
                        <Button
                            label={t('Tee tellimusest koopia')}
                            onClick={duplicate}
                        />
                        {
                            user?.permissions?.excel === 1 ?
                            <Button
                                label={t('Excelisse')}
                                onClick={handleExcel}
                            />
                            :
                            <></>
                        }
                        <Button
                            label={t('Väljasta')}
                            onClick={() => setShowBufferModal(true)}
                            disabled={selectedRows?.length !== 1 || selectedRows[0].SHIPPED || selectedRows[0].KOGUS < 1}
                        />
                        <Button
                            label={t('Tühista valik')}
                            onClick={() => setSelectedRows([])}
                            disabled={selectedRows?.length < 1}
                        />
                        <Button
                            label={t('Kustuta tellimus')}
                            onClick={() => setDeleteModal(true)}
                            disabled={selectedRows?.length < 1}
                        />
                    </div>
                </div>
                <Filters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        query={BufferQuery}
                        queryKey={'bufferQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ORDERNO'}
                        extraFilters={extraFilters}
                        statusColorEnabled={true}
                        onRowDoubleClick={handleRowDoubleClick}
                        onRowClick={handleRowClick}
                        activeRows={selectedRows}
                        ref={tableRef}
                        rowClass={getProductStatusClass}
                        useExtraFilters={true}
                        subscriptions={subscriptions}
                        onFiltersChanged={() => setSelectedRows([])}
                    />
                </div>
            </div>
            <Modal
                show={showDeliveryNoteModal}
                close={() => {
                    resetNoteState()
                    closeDeliveryNoteModal()
                }}
                renderActions={renderModalActions}
                className={'modal--create-deliverynote'}
            >
                {
                    !noteCreated ?
                        <div className='modal--create-deliverynote--content'>
                            <DateInput
                                label={t('Kuupäev')}
                                onChange={(val) => setNoteDate(new Date(val))}
                                value={noteDate}
                            />
                            <Input
                                label={t('Aluseid')}
                                value={notePallets}
                                onChange={(e) => setNotePallets(e.target.value)}
                                type={'number'}
                            />
                            <Checkbox
                                label={t('Tasulised alused')}
                                value={notePaidPallets}
                                onChange={(e) => setNotePaidPallets(e.target.checked)}
                            />
                        </div>
                        :
                        <p>
                            {t('Saateleht on tekitatud. Kas soovite seda välja trükkida ja raamatupidamisse saata?')}
                        </p>
                }
            </Modal>
            <Modal
                show={showPdfModal}
                className={'make-order-modal'}
                close={handleCloseModal}
            >
                <PdfViewer document={'buffer-order'} data={selectedRows} onSendEmail={handleMakeOrder} />
            </Modal>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('Kustuta toode')}
                close={() => setDeleteModal(false)}
                onConfirm={handleRemoveItem}
                title={t('Kas soovid kustutada järgmise toodangu?')}
            >
                {
                    selectedRows.map((x, i) =>
                        <div key={`delete-${i}`}>{x.ORDERNO}</div>
                    )
                }
            </ConfirmModal>
            <BufferModal
                show={showBufferModal}
                close={() => setShowBufferModal(false)}
                data={selectedRows?.length === 1 ? selectedRows[0].ID : undefined}
                onSuccess={handleIssueSuccess}
            />
        </div>
    )
}

export default BuffersScreen