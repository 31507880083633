import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';

const Modal = ({
  show,
  close,
  title,
  children,
  className,
  renderActions,
  renderTabs,
  backgroundColor,
}) => {

  useEffect(() => {
    document.body.addEventListener('keyup', handleEsc)

    return () => {
      document.body.removeEventListener('keyup', handleEsc)
    }
  }, [])

  const handleEsc = (e) => {
    if (!close) return
    
    if (e.key === 'Escape') {
      close()
    }
  }

  return ReactDOM.createPortal(
    <>
      { show &&
        <>
          <div
            onClick={close}
            className={`modal-background`}
            style={{
              backgroundColor: backgroundColor || undefined,
            }}
          >
          </div>
          <div
            className={`modal-inner${className ? ' ' + className : ''}`}
          >
            <div className='modal-header'>
              <div className='modal-header--inner'>
                {
                  close ?
                  <button className="modal-close" onClick={close}></button>
                  :
                  <></>
                }
                <h2 className="modal-title">{title}</h2>
              </div>
              { renderTabs ? renderTabs() : <></>}
            </div>
            <div className="modal-content">{children}</div>
            {
              renderActions ?
              renderActions()
              :
              <></>
            }
          </div>
        </>
      }
    </>,
    document.getElementById('modal'),
  );
};

export default Modal;
