import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'
import { getDeliveryNoteRowAddress } from '../util/helpers'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
    width: 400,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  infoRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 10,
  },
  tableColText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  strong: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  col0: {
    width: '4%',
  },
  col1: {
    width: '20%',
    paddingRight: 3,
  },
  col2: {
    width: '20%',
    paddingRight: 3,
  },
  col3: {
    width: '20%',
    paddingRight: 3,
  },
  col4: {
    width: '30%',
    paddingRight: 3,
  },
  col5: {
    width: '10%',
    paddingRight: 3,
  },
  col6: {
    width: '10%',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    width: 70,
  },
  value: {
    fontFamily: 'Roboto',
    marginBottom: 4,
  },
  info: {
    fontSize: 10,
  },
  image: {
    width: 70,
    height: 50,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 80,
  },
})

const DeliveryNoteDocument = ({
  data,
}) => {

  const { t } = useTranslation()

  if (!data || !data.client) return <></>

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'portrait'}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: '20%',
              marginRight: 20,
            }}
          >
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          </View>
          <Text style={styles.title}>Multipakend Tootmine</Text>
        </View>
        <View
          style={[
            styles.infoRow,
            {
              fontFamily: 'Roboto',
              justifyContent: 'space-between',
              fontWeight: 700,
              marginBottom: 20,
              marginTop: 20,
            },
          ]}
        >
          <Text>{`SAATELEHT ${data.ORDERNO}`}</Text>
          <Text>{`${formatDate(new Date(data.CREATED_DATE))}`}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={[
              styles.infoRow,
              {
                marginRight: 20,
                width: 300,
              },
            ]}
          >
            <View style={styles.label}>
              <Text>{`${t('Väljastaja')}:`}</Text>
            </View>
            <View>
              <Text style={[styles.value, styles.strong]}>{`OÜ MULTIPAKEND TOOTMINE`}</Text>
              <Text style={styles.value}>{`Reg. No 12843560`}</Text>
              <Text style={styles.value}>{`Tuuliku tee 4 C`}</Text>
              <Text style={styles.value}>{`10621 Tallinn`}</Text>
              <Text style={styles.value}>{`Eesti`}</Text>
              <Text style={styles.value}>{``}</Text>
              <Text style={styles.value}>{`Pank: Swedbank`}</Text>
              <Text style={styles.value}>{`SWIFT: HABA EE2X`}</Text>
              <Text style={styles.value}>{`IBAN: EE622200221061907439`}</Text>
              <Text style={styles.value}>{`VAT: EE101789738`}</Text>
            </View>
          </View>

          <View
            style={{
              ...styles.infoRow,
              width: 300,
            }}
          >
            <View style={{
              ...styles.label,
              width: 40,
            }}>
              <Text>{`${t('Saaja')}:`}</Text>
            </View>
            {
              data && data.client ?
                <View style={{
                  flex: 1,
                }}>
                  <Text style={[styles.value, styles.strong, { flexShrink: 1 }]}>{data.client.NAME}</Text>
                  <Text style={styles.value}>{getDeliveryNoteRowAddress(data?.rows)}</Text>
                </View>
                :
                <></>
            }
          </View>
        </View>

        <View>
          <View style={[styles.tableRow, { borderBottomWidth: 1, borderBottomColor: '#000', marginTop: 20 }]}>
            <View style={styles.col0} />
            <View style={styles.col1}>
              <Text style={styles.tableHeadLabel}>{t('Positsioon')}</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.tableHeadLabel}>{t('Kliendi tellimus')}</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.tableHeadLabel}>{t('Kliendi tootekood')}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={styles.tableHeadLabel}>{t('FSC')}</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.tableHeadLabel}>{t('Kogus')}</Text>
            </View>
          </View>
          {
            
            data && [...data.rows]
            .sort((a, b) => a?.ID - b?.ID)
            .map((row, i) =>
              <View key={row.ID} style={[styles.tableRow, { borderBottomWidth: 0, borderBottomColor: '#000' }]}>
                <View style={styles.col0}>
                  <Text style={styles.tableColText}>{i + 1}</Text>
                </View>
                <View style={styles.col1}>
                  <Text style={styles.tableColText}>{row.production?.MOOT}</Text>
                </View>
                <View style={styles.col2}>
                  <Text style={styles.tableColText}>{row.production?.KLIENT_ORDER}</Text>
                </View>
                <View style={styles.col3}>
                  <Text style={styles.tableColText}>{row.production?.KLIENT_BOXCODE}</Text>
                </View>
                <View style={styles.col4}>
                  <Text style={styles.tableColText}>{row.production?.FSC || ''}</Text>
                </View>
                <View style={styles.col5}>
                  <Text style={styles.tableColText}>{row.AMOUNT}</Text>
                </View>
              </View>
            )
          }
          {
            data.PALLETS && data.PALLETS > 0 ?
              <View key={'pallet-row'} style={[styles.tableRow, { borderBottomWidth: 0, borderBottomColor: '#000' }]}>
                <View style={styles.col0}>
                  <Text style={styles.tableColText}>{data?.rows?.length + 1}</Text>
                </View>
                <View style={styles.col1}>
                  <Text style={styles.tableColText}>{'Alused'}</Text>
                </View>
                <View style={styles.col2} />
                <View style={styles.col3} />
                <View style={styles.col4} />
                <View style={styles.col5}>
                  <Text style={styles.tableColText}>{data.PALLETS}</Text>
                </View>
              </View>
              :
              <></>
          }
        </View>
        <View style={styles.footer}>
          <View>
            <Text>{t('Kauba väljastas')}</Text>
          </View>
          <View style={{ marginRight: 180 }}>
            <Text style={{ marginBottom: 30 }}>{t('Kauba võttis vastu')}</Text>
            <Text style={{ marginBottom: 30 }}>{t('Auto number')}</Text>
            <Text style={{ marginBottom: 30 }}>{t('Kuupäev')}</Text>
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <Text>{t('Kaubaalus on Multipakend Tootmine OÜ omand ja kuulub tasumisele või tagastamisele.')}</Text>
          <Text>{`Aluse hind vastavalt hinnapakkumisele`}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default DeliveryNoteDocument