import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllUsers, GetBoxes, GetFactories } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateBox, CreateFactory, CreateUser, RemoveBox, RemoveFactory, RemoveUser, UpdateBox, UpdateFactory, UpdateUser } from '../../graphql/mutations'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import { calculateSpread, replaceFormulaValues } from '../../util/calc'
import { useNotification } from '../../providers/Notification'

const BoxesScreen = () => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [tableHeaders, setTableHeaders] = useState([])
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState({})

    const { data, loading, refetch } = useQuery(GetBoxes, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getBoxes } = data
            if (getBoxes?.length > 0) {
                let keys = []
                for (const key in getBoxes[0]) {
                    if (['__typename', 'ID'].includes(key)) continue
                    keys.push(key)
                }
                setTableHeaders(keys)
            }
        }
    })
    
    const [updateItem] = useMutation(UpdateBox)
    const [createItem] = useMutation(CreateBox)
    const [removeItem] = useMutation(RemoveBox)

    const addItem = async () => {
        if (!validateFields()) return
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createBox) {
                setShowModal(false)
                setNewItem({})
                refetch()
            }
        } catch (err) {
            console.log('Error creating box', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(selectedRow.ID)
                },
            })
            setSelectedRow(null)
            if (data.removeBox) refetch()
        } catch (err) {
            console.log('Error deleting box', err)
        }
    }

    const updateProperty = async (id, field, value) => {
        try {
            await updateItem({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const validateFields = () => {
        if (!newItem.NAME || !newItem.FORMULA_L || !newItem.FORMULA_B) return false
        return true
    }

    const testFormula = (layers) => {
        if (!selectedRow) return
        try {
            const forumulaL = replaceFormulaValues(selectedRow.FORMULA_L, 200, 200, 100, layers)
            const forumulaB = replaceFormulaValues(selectedRow.FORMULA_B, 200, 200, 100, layers)
            const spread = calculateSpread(forumulaL, forumulaB)
    
            dispatch({
                type: 'ADD',
                payload: {
                    content: `${layers} ${t('kihti')} | L=${spread.length}, B=${spread.width}, S=${spread.length * spread.length * 1e-6}`,
                },
            })
        } catch (err) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: `Vigane valem`,
                    type: 'error',
                },
            })
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='boxes'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Karbid') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>
                </div>
                { tableHeaders && tableHeaders.length > 0 ?
                    <div className='table-wrapper'>
                        <table className='input-table'>
                            <thead>
                                <tr>
                                {
                                    tableHeaders.map((x, i) =>
                                        <th key={`table-header-col-${i}`} className='grid-list-item--col'>{ t(x) }</th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.getBoxes.map((item) =>
                                    <tr
                                        key={item.ID}
                                        onClick={() => setSelectedRow(item)}
                                        className={`table-row${item.ID === selectedRow?.ID ? ' table-row--selected' : ''}`}
                                    >
                                        {
                                            tableHeaders.map((x) =>
                                                <td key={`${item.ID}-${x}`}>
                                                    <TableInput
                                                        initialValue={item[x]}
                                                        label={x}
                                                        fieldUpdated={(field, value) => {
                                                            updateProperty(item.ID, field, value)
                                                        }}
                                                    />
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Karpe ei leitud') }</p>
                    </div>
                }
                <div className='boxes-actions'>
                    <Button
                        label={t('Proovi (3-kihti)')}
                        onClick={() => testFormula(3)}
                        disabled={!selectedRow}
                    />
                    <Button
                        label={t('Proovi (5-kihti)')}
                        onClick={() => testFormula(5)}
                        disabled={!selectedRow}
                    />
                </div>
            </div>
            <Modal
                title={t('Uus karp')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus karp') }</h5>
                <Input
                    label={`${t('Tüüp')}:`}
                    value={newItem.NAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        NAME: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Pikkuse valem')}:`}
                    value={newItem.FORMULA_L}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        FORMULA_L: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Laiuse valem')}:`}
                    value={newItem.FORMULA_B}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        FORMULA_B: e.target.value,
                    })}
                />
                <Button
                    label={t('Lisa karp')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default BoxesScreen