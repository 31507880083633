import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetCompanyReport } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'
import { parseDate } from '../../util/format'
import DateInput from '../../components/DateInput'

const initialResult = {
    labels: [],
    datasets: [],
}

const ReportCompanyScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)

    const { refetch } = useQuery(GetCompanyReport, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start,
            end,
            shipped,
        },
        onCompleted: (data) => {
            if (data?.getCompanyReport) {
                setResult({
                    labels: data?.getCompanyReport.map(x => x.label),
                    datasets: [{
                        id: 1,
                        label: 'Realisatsioon',
                        data: data?.getCompanyReport.map(x => x.value),
                        backgroundColor: chartColors,
                    }],
                })
            }
        },
    })

    useEffect(() => {
        (async () => {
            await refetch()
        })()
    }, [])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        start,
        end,
        shipped,
    ])

    return (
        <div className='report-company'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Firmade realisatsioon') }</h2>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                <div className='chart-wrapper'>
                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value) {
                                        return value ? Number(parseFloat(value).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                        plugins={[ChartDataLabels]}
                        data={result}
                    />
                }
                </div>
            </div>
        </div>
    )
}

export default ReportCompanyScreen