import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetDeliveryNote, GetInvoice, GetInvoices, SearchClients } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import Input from '../../components/Input'
import { parseDate } from '../../util/format'
import DateInput from '../../components/DateInput'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import Textarea from '../../components/Textarea'
import InvoiceRows from '../../components/InvoiceRows'
import { RemoveDeliveryNote, RemoveInvoice, UpdateDeliveryNote, UpdateInvoice } from '../../graphql/mutations'
import DeliveryNoteRows from '../../components/DeliveryNoteRows'

const initialNoteState = {
    CREATED_DATE: null,
    CLIENT_ID: null,
    CREATOR: null,
    ORDERNO: null,
    PALLETS: null,
    PALLET_COSTS: null,
    BOOKKEEPING: null,
    COMMENT: null,
}

const DeliveryNoteScreen = () => {

    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [note, setNote] = useState(initialNoteState)
    const [updateDeliveryNote] = useMutation(UpdateDeliveryNote)
    const [removeDeliveryNote] = useMutation(RemoveDeliveryNote)

    const { refetch: searchClients } = useQuery(SearchClients, {
        skip: true,
    })

    const { loading } = useQuery(GetDeliveryNote, {
        variables: {
          id: parseInt(id)
        },
        skip: !id,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getDeliveryNote } = data
            if (getDeliveryNote) setNote(getDeliveryNote)
        },
    })

    const setField = (field, value) => {
        setNote({
            ...note,
            [field]: value,
        })
        updateProperty(field, value)
    }

    const loadClients = async (input) => {
        if (input.length < 3) return
        const res = await searchClients({
            searchInput: input,
        })
        return res.data.searchClients
    }

    const handleClientChange = (client) => {
        if (!client || !client.ID) return

        setNote({
            ...note,
            client,
        })
        updateProperty('CLIENT_ID', client.ID)
    }

    const handleDeleteNote = async () => {
        try {
            await removeDeliveryNote({
                variables: {
                    id: parseInt(id),
                },
            })
            navigate(-1)
        } catch (err) {
            console.log('handleDeleteNote:', err)
        }
    }

    const updateProperty = async (field, value) => {
        try {
            await updateDeliveryNote({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='delivery-note'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Saateleht') }</h2>
                    <Button
                        label={t('Kustuta saateleht')}
                        onClick={handleDeleteNote}
                    />
                </div>

                <div className='delivery-note-fields'>
                    <div className='field-row'>
                        <div className='field-col'>
                            <Input
                                label={t('Number')}
                                type={'number'}
                                value={note.ORDERNO}
                                onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                            />
                            <DateInput
                                label={t('Kuupäev')}
                                value={note.CREATED_DATE ? new Date(note.CREATED_DATE) : null}
                                onChange={(val) => setField('CREATED_DATE', parseDate(val))}
                            />
                            <SelectInput
                                label={`${t('Klient')}`}
                                value={note.client}
                                load={loadClients}
                                getOptionLabel={(option) => option.NAME}
                                getOptionValue={(option) => option.ID}
                                loadingMessage={() => t('Laen kliente')}
                                noOptionsMessage={() => t('Kirjuta, et otsida kliente')}
                                onChange={handleClientChange}
                                defaultValue={note.client}
                            />
                        </div>
                        <div className='field-col'>
                            <div className='pallets'>
                                <Input
                                    label={t('Aluseid')}
                                    type={'number'}
                                    value={note.PALLETS}
                                    onChange={(e) => setField('PALLETS', parseInt(e.target.value))}
                                />
                                <Checkbox
                                    label={t('Tasulised')}
                                    value={note.PALLET_COSTS === 1}
                                    onChange={(e) => setField('PALLET_COSTS', e.target.checked ? 1 : 0)}
                                />
                            </div>
                            <Textarea
                                label={t('Kommentaar')}
                                value={note.COMMENT}
                                onChange={e => setField('COMMENT', e.target.value)}
                            />
                            <Checkbox
                                label={t('Raamatupidamisse saadetud')}
                                value={note.BOOKKEEPING === 1}
                                onChange={(e) => setField('BOOKKEEPING', e.target.checked ? 1 : 0)}
                            />
                        </div>
                    </div>
                    <div className='field-item creator'>
                        <span className='field-item--label'>{ t('Koostaja') }</span>
                        <span className='field-item--value'>{ note.author && note.author.RNAME }</span>
                    </div>
                </div>
                <DeliveryNoteRows
                    noteId={id}
                />
            </div>
        </div>
    )
}

export default DeliveryNoteScreen