import React, { useEffect, useState } from 'react'
import Input from './Input'
import Button from './Button'
import { UpdateClientBoxcode } from '../graphql/mutations'
import { useMutation } from '@apollo/client'

const ClientBoxcode = ({
    production,
    buffer,
}) => {

    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [btnText, setBtnText] = useState('Salvesta tootekood')
    const [update] = useMutation(UpdateClientBoxcode)

    useEffect(() => {
        if (!production) return
        setCode(production?.KLIENT_BOXCODE)
    }, [production])

    useEffect(() => {
        if (!buffer) return
        setCode(buffer?.KLIENT_BOXCODE)
    }, [buffer])

    const handleSave = async () => {
        setLoading(true)

        try {
            const result = await update({
                variables: {
                    ...(production && {
                        prodId: production.ID,
                    }),
                    ...(buffer && {
                        bufferId: buffer.ID,
                    }),
                    code,
                },
            })
    
            if (result?.data?.updateClientBoxcode === 'Success') {
                setBtnText('Tootekood salvestatud')
                setDisabled(true)
    
                setTimeout(() => {
                    setBtnText('Salvesta tootekood')
                    setDisabled(false)
                }, 3000)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='client-boxcode'>
            <Input
                label={'Kliendi tootekood'}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                maxLength={50}
            />
            <Button
                label={btnText}
                onClick={handleSave}
                loading={loading}
                disabled={loading || disabled}
                className={'btn-small'}
            />
        </div>
    )
}

export default ClientBoxcode