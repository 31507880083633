import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllUsers, GetFactories } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateFactory, CreateUser, RemoveFactory, RemoveUser, UpdateFactory, UpdateUser } from '../../graphql/mutations'
import Pagination from '../../components/Pagination'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'

const FactoriesScreen = () => {

    const { t } = useTranslation()
    const [tableHeaders, setTableHeaders] = useState([])
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState({})

    const { data, loading, refetch } = useQuery(GetFactories, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getFactories } = data
            if (getFactories?.length > 0) {
                let keys = []
                for (const key in getFactories[0]) {
                    if (['__typename', 'ID'].includes(key)) continue
                    keys.push(key)
                }
                setTableHeaders(keys)
            }
        }
    })
    
    const [updateItem] = useMutation(UpdateFactory)
    const [createItem] = useMutation(CreateFactory)
    const [removeItem] = useMutation(RemoveFactory)

    const addItem = async () => {
        if (!validateFields()) return
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createFactory) {
                setShowModal(false)
                setNewItem({})
                refetch()
            }
        } catch (err) {
            console.log('Error creating factory', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(selectedRow.ID)
                },
            })
            setSelectedRow(null)
            if (data.removeFactory) refetch()
        } catch (err) {
            console.log('Error deleting factory', err)
        }
    }

    const updateProperty = async (id, field, value) => {
        try {
            await updateItem({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const validateFields = () => {
        if (!newItem.NAME || !newItem.EMAIL || !newItem.CONTACT || !newItem.ADDRESS) return false
        return true
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='factories'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Tehased') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>

                </div>
                { tableHeaders && tableHeaders.length > 0 ?
                    <div className='table-wrapper'>
                        <table className='input-table'>
                            <thead>
                                <tr>
                                {
                                    tableHeaders.map((x, i) =>
                                        <th key={`table-header-col-${i}`} className='grid-list-item--col'>{ t(x) }</th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.getFactories.map((item) =>
                                    <tr
                                        key={item.ID}
                                        onClick={() => setSelectedRow(item)}
                                        className={`table-row${item.ID === selectedRow?.ID ? ' table-row--selected' : ''}`}
                                    >
                                        {
                                            tableHeaders.map((x) =>
                                                <td key={`${item.ID}-${x}`}>
                                                    <TableInput
                                                        initialValue={item[x]}
                                                        label={x}
                                                        fieldUpdated={(field, value) => {
                                                            updateProperty(item.ID, field, value)
                                                        }}
                                                    />
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Tehaseid ei leitud') }</p>
                    </div>
                }
            </div>
            <Modal
                title={t('Uus tehas')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus tehas') }</h5>
                <Input
                    label={`${t('Nimi')}:`}
                    value={newItem.NAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        NAME: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Kontakt')}:`}
                    value={newItem.CONTACT}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        CONTACT: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Email')}:`}
                    value={newItem.EMAIL}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        EMAIL: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Aadress')}:`}
                    value={newItem.ADDRESS}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        ADDRESS: e.target.value,
                    })}
                />
                <Button
                    label={t('Lisa tehas')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default FactoriesScreen