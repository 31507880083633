import React, { useState } from 'react'
import Modal from './Modal'
import { useMutation } from '@apollo/client'
import { ChangePassword } from '../graphql/mutations'
import Input from './Input'
import Button from './Button'
import BoxNotification from './BoxNotification'
import { useNotification } from '../providers/Notification'

const passwordValidate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/

const PasswordChangeModal = ({
    show,
    title,
}) => {
    const { dispatch } = useNotification()
    const [fields, setFields] = useState({
        newPassword: '',
        confirmPassword: ''
    })
    const [hidePass, setHidePass] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [changePass] = useMutation(ChangePassword)
    
    const setField = (field, value) => {
        setError(false)
        setFields({
            ...fields,
            [field]: value,
        })
    }

    const handleChange = async () => {
        setLoading(true)
        
        try {
            if (!fields.newPassword || !fields.confirmPassword) {
                setError('Täida mõlemad salasõna väljad')
                return
            }
    
            if (fields.newPassword !== fields.confirmPassword) {
                setError('Salasõnad peavad kattuma')
                return
            }
    
            if (fields.newPassword.length < 6) {
                setError('Salasõna peab olema 6-20 tähemärki pikk')
                return
            }
    
            if (!fields.newPassword.match(passwordValidate)) {
                setError('Salasõna peab sisaldama vähemalt ühte numbrit, suurt ja väikest tähte')
                return
            }
    
            const res = await changePass({
                variables: {
                    password: fields.newPassword,
                },
            })
    
            if (res?.data?.changePassword !== 'Success') {
                setError('Viga salasõna uuendamisel. Proovi hiljem uuesti.')
                return
            }
    
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'success',
                    content: 'Salasõna edukalt uuendatud.'
                },
            })
    
            window.location.reload()
        } catch (err) {
            setError('Viga salasõna uuendamisel. Proovi hiljem uuesti.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            show={show}
            title={title}
            className={'modal-password'}
            backgroundColor={'rgba(53, 39, 36, 0.99)'}
        >
            <Input
                label={'Uus salasõna'}
                onChange={(e) => setField('newPassword', e.target.value)}
                value={fields.newPassword}
                type={hidePass ? 'password' : 'text'}
                autoComplete='new-password'
            />
            <Input
                label={'Korda uut salasõna'}
                onChange={(e) => setField('confirmPassword', e.target.value)}
                value={fields.confirmPassword}
                type={hidePass ? 'password' : 'text'}
                autoComplete='new-password'
            />
            <BoxNotification
                message={error}
                type={'error'}
            />
            <Button
                label={'Uuenda salasõna'}
                onClick={handleChange}
                loading={loading}
                disabled={loading}
            />
        </Modal>
    )
}

export default PasswordChangeModal