import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState, useRef } from 'react'
import { CreateBlueprint, UpdateBufferItem, UpdateProductionItem, UploadFile } from '../graphql/mutations'
import { GetAllBlueprints } from '../graphql/queries'
import Button from './Button'
import FileInput from './FileInput'
import { useTranslation } from 'react-i18next'
import BoxWorkflow from './BoxWorkflow'
import BoxColorFields from './BoxColorFields'
import BoxNotification from './BoxNotification'
import Input from './Input'
import Directions from './Directions'
import ConfirmModal from './ConfirmModal'
import Textarea from './Textarea'
import SelectInput from './SelectInput'
import AdditionalModal from './AdditionalModal'
import { GetSimilarBlueprint } from '../graphql/queries'
import { FileUrl } from '../util/const'
import FilterTable from './FilterTable'


const initialBlueprint = {
    COMMENT: '',
    PCS_PER_HIT: '',
    PCS_PER_STOCK: '',
    SLITTER: '',
    FLEXO: '',
    TIGEL: '',
    SRE: '',
    INLINE: '',
    SLOTTER: '',
    KLIIM: '',
    ALIIM: '',
    _3LIIM: '',
    LAMINAATOR: '',
    STOCK_AMOUNT: '',
    STOCK_MOOT: '',
    OFFSET_AMOUNT: '',
    OFFSET_MOOT: '',
    TOOTLEMINE: '',
    EKSTSEN: '',
    PREMAID: '',
    PRODUCTION_CODE: '',
    KLISHEE_LOCATION: '',
    MATRIITS_LOCATION: '',
    VERONA: '',
    TITAN: '',
    AFD: '',
    FPR: '',
    KLISHEE: '',
    MATRIITS: '',
    TT: '',
    PANTONE1: '',
    PANTONE2: '',
    PANTONE3: '',
    PANTONE4: '',
    PACKAGING_METHOD: '',
    AMOUNT_PALLET: '',
    SLOTS: '',
    FILE: null,
    CONSTRUCTOR: '',
    DESIGNER: '',
    PALLET_TYPE: '',
    PALLET_LAYOUT: '',
    NUUT: '',
}

const includeFields = [
    {
        value: 'PRODUCTION_CODE',
        label: 'Toote kood',
    },
    {   
        value: 'client.NAME',
        label: 'Klient',
    },
    {
        value: 'KLIENT_BOXCODE',
        label: 'Kliendi tootekood',
    },
    {
        value: 'MOOT',
        label: 'Mõõt',
    },
    {
        value: 'blueprint.KLISHEE',
        label: 'Klishee',
    },
    {
        value: 'blueprint.MATRIITS',
        label: 'Matriits',
    },
]


const AddBlueprint = ({
    orderRowId,
    productionCode,
    production,
    isBuffer,
    onSuccess,
    constructors,
    blueprints,
}) => {

    const { t } = useTranslation()
    const [createBlueprint] = useMutation(CreateBlueprint)
    const [updateProd] = useMutation(UpdateProductionItem)
    const [updateBuffer] = useMutation(UpdateBufferItem)
    const [data, setData] = useState(initialBlueprint)
    const [errors, setErrors] = useState(initialBlueprint)
    const [upload] = useMutation(UploadFile)
    const [loading, setLoading] = useState(false)
    const [loadingSelected,setLoadingSelected] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [emailComment, setEmailComment] = useState('')
    const [selectedBlueprint, setSelectedBlueprint] = useState('')
    const [copiedBlueprint, setCopiedBlueprint] = useState('')

    const tableRef = useRef()
    const { data: similarBlueprint } = useQuery(GetSimilarBlueprint, {
        skip: !production?.ORDER_ROW_ID,
        fetchPolicy: 'cache-and-network',
        variables: {
            orderRowId: production?.ORDER_ROW_ID,
        },
    })

    useEffect(() => {
        if (similarBlueprint?.getSimilarBlueprint) {
            const filled = { ...initialBlueprint }
            Object.entries(initialBlueprint).forEach(([key]) => {
                if (similarBlueprint.getSimilarBlueprint[key]) {
                    filled[key] = similarBlueprint.getSimilarBlueprint[key]
                }
            })
            setData(filled)
        setSelectedBlueprint(filled?.PRODUCTION_CODE || '')
        }
    }, [similarBlueprint])

    const handleBlueprintChange = (selectedValue) => {
        const selectedBlueprintData = blueprints.find(blueprint => blueprint.PRODUCTION_CODE === selectedValue.value)
    
        if (selectedBlueprintData) {
            const filled = { ...initialBlueprint }
            Object.entries(initialBlueprint).forEach(([key]) => {
                if (selectedBlueprintData[key]) {
                    filled[key] = selectedBlueprintData[key]
                }
            })
            setSelectedBlueprint(selectedValue.value)
            setData(filled)
        } else {
            console.log('Blueprint not found for value:', selectedValue.value)
        }
    }
    
    const setField = (field, value) => {
        setErrors({ ...initialBlueprint })
        setData({ ...data, [field]: value })
    }

    const handleCreate = async ({
        sendEmail,
    } = {
            sendEmail: false,
        }) => {
        setLoading(true)

        try {
            const fileName = await handleUpload()
            const blueprintData = {}
            const prodData = {}

            for (const [key, value] of Object.entries(data)) {
                if (!value) continue

                switch (key) {
                    case 'FILE':
                        blueprintData.FILE = fileName
                        break
                    case 'PACKAGING_METHOD':
                    case 'TT':
                    case 'KLISHEE':
                    case 'KLISHEE_LOCATION':
                    case 'MATRIITS':
                    case 'MATRIITS_LOCATION':
                    case 'COMMENT':
                    case 'OFFSET_MOOT':
                    case 'STOCK_MOOT':
                    case 'PANTONE1':
                    case 'PANTONE2':
                    case 'PANTONE3':
                    case 'PANTONE4':
                    case 'FLUTE':
                    case 'PRINTING':
                    case 'FEEDING':
                    case 'NUUT':
                    case 'PALLET_LAYOUT':
                    case 'PALLET_TYPE':
                        blueprintData[key] = value
                        break
                    case 'PCS_PER_STOCK':
                    case 'PCS_PER_HIT':
                        blueprintData[key] = parseFloat(value)
                        break
                    case 'STOCK_AMOUNT':
                        prodData[key] = parseInt(value, 10)
                        blueprintData[key] = parseInt(value, 10)
                        break
                    default:
                        blueprintData[key] = parseInt(value, 10)
                }
            }

            blueprintData.PRODUCTION_CODE = productionCode

            if (isBuffer) {
                updateBuffer({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            } else {
                updateProd({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            }

            const res = await createBlueprint({
                variables: {
                    data: {
                        ...blueprintData,
                        ...(sendEmail === true && {
                            sendMail: true,
                            emailComment,
                        }),
                    },
                },
            })

            if (res?.data?.createBlueprint !== 'Success') return

            if (onSuccess) onSuccess()
        } catch (err) {
            console.log('Error creating blueprint', err)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const handleUpload = async () => {
        try {
            if ((!data.FILE || typeof data.FILE === 'string') && similarBlueprint?.getSimilarBlueprint?.FILE) {
                return similarBlueprint?.getSimilarBlueprint?.FILE
            }
            
            if (!data.FILE || typeof data.FILE === 'string') {
                return data?.FILE
            }
           
            const res = await upload({
                variables: {
                    file: data.FILE,
                    orderRowId: parseInt(orderRowId),
                },
            })

            return res?.data?.singleUpload?.filename
        } catch (err) {
            console.log('handleUpload', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ID':
                return <div>{row[field]}</div>
            case 'PRODUCTION_CODE':
                return <div>{row[field]}</div>
            case 'client.NAME':
                return <div>{row.client ? row.client.NAME : ''}</div>
            case 'blueprint.KLISHEE':
                return <div>{row.blueprint ? row.blueprint.KLISHEE : null}</div>
            case 'blueprint.MATRIITS':
                return <div>{row.blueprint ? row.blueprint.MATRIITS : null}</div>
            default:
                return <div>{row[field]}</div>
        }
    }
    const handleShowModal = () => setShowModal(true)
    const handleCloseModal = () => setShowModal(false)
    const [selectedRow, setSelectedRow] = useState()
    
    const onRowSelect = (row) => {
        setSelectedRow(row) 
    }
    const handleRowClick = async (row) => {
        if (!selectedRow) return 
        const val = { value: selectedRow.PRODUCTION_CODE, label: selectedRow.PRODUCTION_CODE }
        handleBlueprintChange(val)
        setCopiedBlueprint(selectedRow.PRODUCTION_CODE)
    }

    return (
        <div className='blueprint-new'>
            <div className='upload-file'>
                {
                    !data?.FILE ?
                        <></>
                        :
                        <div className='blueprint-file'>
                            {
                                data?.FILE?.name ?
                                    <div className='upload-file--filename'>{data?.FILE?.name}</div>
                                    :
                                    <a href={`${FileUrl}${data.FILE}`} target='_blank'>{`${t('blueprint.view_blueprint')}`}</a>
                            }
                        </div>
                }
                <div className='upload-file-wrapper'>
                    <div className='upload-file--type'>
                        <FileInput
                            label={t('Lisa joonis arvutist')}
                            onChange={(e) => setField('FILE', e.target.files[0])}
                            allowedTypes={'.pdf'}
                        />
                    </div>
                    <Button 
                        className='upload-file--button'
                        label={t('Kopeeri tehnoloogiline kaart tootekoodi põhjal')} 
                        onClick={handleShowModal} 
                    />
            </div>
      
            <AdditionalModal
                className='additional-modal-filter'
                title={t('Kopeeri tehnoloogiline kaart')}
                show={showModal}
                close={handleCloseModal}
            >
                <Button 
                    className='btn-copy'
                    label={t('Kopeeri')} 
                    onClick={async () => {
                        await handleRowClick() 
                        handleCloseModal()     
                    }}
                    disabled={!selectedRow || loadingSelected}
                    loading={loadingSelected}
                >    
                </Button>
                
                <div className='scroll-container'>
                    <FilterTable 
                        ref={tableRef}
                        query={GetAllBlueprints}
                        queryKey={'getAllBlueprints'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        onRowClick={onRowSelect}
                        activeRows={selectedRow ? [selectedRow] : []}
                        initialOrderBy={'ID'}
                        rowKey={'ID'}
                    />
                </div>
            </AdditionalModal>
            {
                copiedBlueprint ? (
                    <BoxNotification message={`Väljad on automaatselt täidetud tootekoodi ${copiedBlueprint} põhjal`} type={'info'} />
                ) : similarBlueprint?.getSimilarBlueprint ? (
                    <BoxNotification message={`Väljad on automaatselt täidetud tootekoodi ${similarBlueprint.getSimilarBlueprint.PRODUCTION_CODE} põhjal`} type={'info'} />
                ) : null
            }
            </div>
            <h4>{t('blueprint.machine_order')}</h4>
            <BoxWorkflow
                onChange={setField}
                items={[
                    {
                        field: 'SLITTER',
                        value: data.SLITTER,
                    },
                    {
                        field: 'TIGEL',
                        value: data.TIGEL,
                    },
                    {
                        field: 'SRE',
                        value: data.SRE,
                    },
                    {
                        field: 'INLINE',
                        value: data.INLINE,
                    },
                    {
                        field: 'SLOTTER',
                        value: data.SLOTTER,
                    },
                    {
                        field: 'KLIIM',
                        value: data.KLIIM,
                    },
                    {
                        field: 'ALIIM',
                        value: data.ALIIM,
                    },
                    {
                        field: '_3LIIM',
                        value: data._3LIIM,
                    },
                    {
                        field: 'LAMINAATOR',
                        value: data.LAMINAATOR,
                    },
                    {
                        field: 'TOOTLEMINE',
                        value: data.TOOTLEMINE,
                    },
                    {
                        field: 'EKSTSEN',
                        value: data.EKSTSEN,
                    },
                    {
                        field: 'AFD',
                        value: data.AFD,
                    },
                    {
                        field: 'FPR',
                        value: data.FPR,
                    },
                    {
                        field: 'PREMAID',
                        value: data.PREMAID,
                    },
                    {
                        field: 'TITAN',
                        value: data.TITAN,
                    },
                    {
                        field: 'VERONA',
                        value: data.VERONA,
                    },
                ]}
            />
            <h4>{t('blueprint.colors')}</h4>
            <BoxColorFields
                pantone1={data.PANTONE1}
                pantone2={data.PANTONE2}
                pantone3={data.PANTONE3}
                pantone4={data.PANTONE4}
                onColorChange={setField}
                pantoneOnly
            />
            <Directions
                onChange={setField}
                data={{
                    FLUTE: data.FLUTE,
                    PRINTING: data.PRINTING,
                    FEEDING: data.FEEDING,
                }}
            />
            <div className='blueprint-fields'>
                <Input
                    label={'Terast terani'}
                    value={data.TT}
                    onChange={(e) => setField('TT', e.target.value)}
                />
                <Input
                    label={'Tükki löögist'}
                    value={data.PCS_PER_HIT}
                    onChange={(e) => setField('PCS_PER_HIT', e.target.value)}
                    type={'number'}
                />
                <div className='field-row'>
                    <Input
                        label={'Offset mõõt'}
                        value={data.OFFSET_MOOT}
                        onChange={(e) => setField('OFFSET_MOOT', e.target.value)}
                    />
                    <Input
                        label={'Offset kogus'}
                        value={data.OFFSET_AMOUNT}
                        onChange={(e) => setField('OFFSET_AMOUNT', e.target.value)}
                        type={'number'}
                    />
                </div>
                <Input
                    label={'Nuudid'}
                    value={data.NUUT}
                    onChange={(e) => setField('NUUT', e.target.value)}
                />
                <div className='field-row'>
                    <Input
                        label={'Klishee number'}
                        value={data.KLISHEE}
                        onChange={(e) => setField('KLISHEE', e.target.value)}
                    />
                    <Input
                        label={'Klishee asukoht'}
                        value={data.KLISHEE_LOCATION}
                        onChange={(e) => setField('KLISHEE_LOCATION', e.target.value)}
                    />
                </div>
                <div className='field-row'>
                    <Input
                        label={'Matriitsi number'}
                        value={data.MATRIITS}
                        onChange={(e) => setField('MATRIITS', e.target.value)}
                    />
                    <Input
                        label={'Matriitsi asukoht'}
                        value={data.MATRIITS_LOCATION}
                        onChange={(e) => setField('MATRIITS_LOCATION', e.target.value)}
                    />
                </div>
            </div>
            <div className='field-row justify-content--flex-start blueprint-constructors'>
                <SelectInput
                    options={constructors}
                    value={constructors?.find(i => i.ID === data.CONSTRUCTOR)}
                    label={'Stantsi konstruktor'}
                    onChange={(option) => setField('CONSTRUCTOR', option?.ID)}
                    getOptionLabel={(option) => option?.RNAME}
                    getOptionValue={(option) => option?.ID}
                />
                <SelectInput
                    options={constructors}
                    value={constructors?.find(i => i.ID === data.DESIGNER)}
                    label={'Klishee disainer'}
                    onChange={(option) => setField('DESIGNER', option?.ID)}
                    getOptionLabel={(option) => option?.RNAME}
                    getOptionValue={(option) => option?.ID}
                />
            </div>
            <div className='blueprint-fields'>
                <Input
                    label={'Pakkeviis'}
                    value={data.PACKAGING_METHOD}
                    onChange={(e) => setField('PACKAGING_METHOD', e.target.value)}
                />
                <Input
                    label={'Kogus alusel'}
                    value={data.AMOUNT_PALLET}
                    onChange={(e) => setField('AMOUNT_PALLET', e.target.value)}
                    type={'number'}
                />
                <Input
                    label={'Kohtade arv'}
                    value={data.SLOTS}
                    onChange={(e) => setField('SLOTS', e.target.value)}
                    type={'number'}
                />
                <Input
                    label={'Tooriku mõõt'}
                    value={data.STOCK_MOOT}
                    onChange={(e) => setField('STOCK_MOOT', e.target.value)}
                />
                <Input
                    label={'Tooriku kogus'}
                    value={data.STOCK_AMOUNT}
                    onChange={(e) => setField('STOCK_AMOUNT', e.target.value)}
                />
                <Input
                    label={'Tükki toorikust'}
                    value={data.PCS_PER_STOCK}
                    onChange={(e) => setField('PCS_PER_STOCK', e.target.value)}
                    type={'number'}
                />
                <Textarea
                    label={'Kommentaar'}
                    value={data.COMMENT}
                    onChange={(e) => setField('COMMENT', e.target.value)}
                />
                <Input
                    label={'Aluse tüüp'}
                    value={data.PALLET_TYPE}
                    onChange={(e) => setField('PALLET_TYPE', e.target.value)}
                />
                <Input
                    label={'Paigutus alusel'}
                    value={data.PALLET_LAYOUT}
                    onChange={(e) => setField('PALLET_LAYOUT', e.target.value)}
                />
            </div>
            <Button
                label={'Salvesta'}
                onClick={() => setShowConfirmModal(true)}
                loading={loading}
            />
            <ConfirmModal
                show={showConfirmModal}
                close={() => {
                    setShowConfirmModal(false)
                    handleCreate()
                }}
                title={`Tootekood ${productionCode} on valmis, kas saadan teate?`}
                confirmLabel={'Jah'}
                cancelLabel={'Ei'}
                onConfirm={() => handleCreate({ sendEmail: true })}
            >
                <Textarea
                    value={emailComment}
                    onChange={(e) => setEmailComment(e.target.value)}
                    placeholder={'Lisa kommentaar'}
                />
            </ConfirmModal>
        </div>
    )
}

export default AddBlueprint