import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import Button from './Button'
import { useQuery } from '@apollo/client'
import { GetProfile } from '../graphql/queries'
import { forcePasswordChange } from '../util/helpers'
import PasswordChangeModal from './PasswordChangeModal'

const AppHeader = () => {

    const { t } = useTranslation()
    const { user, signOut, setTheme, theme } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [navOpen, setNavOpen] = useState(false)
    const [showPassModal, setShowPassModal] = useState(false)
    const [subs, setSubs] = useState({
        orders: false,
        material: false,
        report: false,
        data: false,
        user: false,
    })

    const toggleMenu = () => setNavOpen(!navOpen)
    const closeMenu = () => setNavOpen(false)

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile) return
            setShowPassModal(forcePasswordChange(data?.getProfile?.PASSWORD_UPDATED))
        },
    }, [user])

    return (
        <header className={`app-header${navOpen ? ' nav-open' : ''}`}>
            <div className='app-header--inner'>
                <div className='brand'>
                    <div onClick={() => navigate('/')} className='brand-logo'></div>
                </div>
                <nav>
                    <div className={`nav-sub${subs.orders ? ' nav-sub--open' : ''}`}>
                        <a
                            className={['/orders', '/sales', '/productions', '/invoices', '/deliverynotes'].includes(pathname) ? 'active' : ''}
                            onClick={() => setSubs({ ...subs, orders: !subs.orders })}
                        >
                            { t('Tootmine & Hinnapakkumised') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            <Link onClick={closeMenu} className={pathname === '/orders' ? 'active' : ''} to={'/orders'}>
                                { t('Hinnapakkumised') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/sales' ? 'active' : ''} target='Müük' to={'/sales'}>
                                { t('Müük') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/productions' ? 'active' : ''} target='Tootmine' to={'/productions'}>
                                { t('Tootmine') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/buffers' ? 'active' : ''} to={'/buffers'}>
                                { t('Buffer ladu') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/deliverynotes' ? 'active' : ''} to={'/deliverynotes'}>
                                { t('Saatelehed') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/operator' ? 'active' : ''} to={'/operator'}>
                                { t('Operaator') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/planning' ? 'active' : ''} target='Planeerimine' to={'/planning'}>
                                { t('Planeerimine') }
                            </Link>
                        </div>
                    </div>
                    {
                        user.role === 1 || user.permissions?.material === 1 ?
                        <div className={`nav-sub${subs.material ? ' nav-sub--open' : ''}`}>
                            <a
                                className={['/material-orders', '/material-order', '/material-report'].includes(pathname) ? 'active' : ''}
                                onClick={() => setSubs({ ...subs, material: !subs.material })}
                            >
                                { t('Materjali tellimine') }
                            </a>
                            <div className='nav-sub--dropdown'>
                                <Link onClick={closeMenu} className={pathname === '/material-orders' ? 'active' : ''} to={'/material-orders'}>
                                    { t('Materjali tellimine') }
                                </Link>
                                <Link onClick={closeMenu} className={pathname === '/material-deliveries' ? 'active' : ''} to={'/material-deliveries'}>
                                    { t('Materjali saabumised') }
                                </Link>
                                <Link onClick={closeMenu} className={pathname === '/material-report' ? 'active' : ''} to={'/material-report'}>
                                    { t('Laekumiste aruanne') }
                                </Link>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {
                        user.role === 1 ?
                        <div className={`nav-sub${subs.report ? ' nav-sub--open' : ''}`}>
                            <a
                                className={['/report-basic','/report-yearly','/report-sales','/report-company', '/report-quotations', '/report-one-company', '/report-user'].includes(pathname) ? 'active' : ''}
                                onClick={() => setSubs({ ...subs, report: !subs.report })}
                            >
                                { t('Aruanded') }
                            </a>
                            <div className='nav-sub--dropdown'>
                                <Link onClick={closeMenu} className={pathname === '/lost-clients' ? 'active' : ''} to={'/lost-clients'}>
                                    { t('Kadunud kliendid') }
                                </Link>
                                <Link onClick={closeMenu} className={pathname === '/report-one-company' ? 'active' : ''} to={'/report-one-company'}>
                                    { t('Ühe firma realisatsioon') }
                                </Link>
                                {
                                    ['BORISS', 'rein'].includes(user.user) &&
                                    <>
                                        <Link onClick={closeMenu} className={pathname === '/report-basic' ? 'active' : ''} to={'/report-basic'}>
                                            { t('Realisatsioon') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-yearly' ? 'active' : ''} to={'/report-yearly'}>
                                            { t('Realisatsioon aastate lõikes') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-user' ? 'active' : ''} to={'/report-user'}>
                                            { t('Realisatsioon eraldi') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-sales' ? 'active' : ''} to={'/report-sales'}>
                                            { t('Realisatsiooni diagramm') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-company' ? 'active' : ''} to={'/report-company'}>
                                            { t('Firmade realisatsiooni edetabel') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-quotations' ? 'active' : ''} to={'/report-quotations'}>
                                            { t('Hinnapakkumised') }
                                        </Link>
                                        <Link onClick={closeMenu} className={pathname === '/report-user-quotations' ? 'active' : ''} to={'/report-user-quotations'}>
                                            { t('Hinnapakkumiste diagramm') }
                                        </Link>
                                    </>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className={`nav-sub${subs.data ? ' nav-sub--open' : ''}`}>
                        <a
                            className={['/users', '/clients', '/factories'].includes(pathname) ? 'active' : ''}
                            onClick={() => setSubs({ ...subs, data: !subs.data })}
                        >
                            { t('Püsiandmed') }
                        </a>
                        <div className='nav-sub--dropdown'>
                            {
                                ['BORISS', 'ADMIN'].includes(user?.user) ?
                                <Link onClick={closeMenu} className={pathname === '/users' ? 'active' : ''} to={'/users'}>
                                    { t('Kasutajad') }
                                </Link>
                                :
                                <></>
                            }
                            {
                                user?.role === 1 ?
                                <>
                                    <Link onClick={closeMenu} className={pathname === '/paper-factories' ? 'active' : ''} to={'/paper-factories'}>
                                        { t('Paberitehased') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/boxes' ? 'active' : ''} to={'/boxes'}>
                                        { t('Karbid') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/extra-products' ? 'active' : ''} to={'/extra-products'}>
                                        { t('Lisatooted') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/materials' ? 'active' : ''} to={'/materials'}>
                                        { t('Materjalid') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/materialgroups' ? 'active' : ''} to={'/materialgroups'}>
                                        { t('Materjali Grupid') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/constants' ? 'active' : ''} to={'/constants'}>
                                        { t('Parameetrid') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/locations' ? 'active' : ''} to={'/locations'}>
                                        { t('Asukohad') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/machines' ? 'active' : ''} to={'/machines'}>
                                        { t('Masinad') }
                                    </Link>
                                    <Link onClick={closeMenu} className={pathname === '/material-codes' ? 'active' : ''} to={'/material-codes'}>
                                        { t('Materjali koodid') }
                                    </Link>
                                </>

                                :
                                <></>
                            }
                            <Link onClick={closeMenu} className={pathname === '/clients' ? 'active' : ''} to={'/clients'}>
                                { t('Kliendid') }
                            </Link>
                            <Link onClick={closeMenu} className={pathname === '/classes' ? 'active' : ''} to={'/classes'}>
                                { t('Klassid') }
                            </Link>
                        </div>
                    </div>
                    {
                        user &&
                        <>
                            <div className={`nav-sub${subs.user ? ' nav-sub--open' : ''}`}>
                                <div
                                    className='app-header--info'
                                    onClick={() => setSubs({ ...subs, user: !subs.user })}    
                                >
                                    <span className='app-header--info-name'>{ user.name }</span>
                                    <span className='app-header--info-title'>{ user.title }</span>
                                </div>
                                <div className='nav-sub--dropdown'>
                                    <a className={theme === 'dark' ? 'active' : ''} onClick={() => setTheme('dark')}>
                                        { t('Tume välimus') }
                                    </a>
                                    <a className={theme === 'light' ? 'active' : ''} onClick={() => setTheme('light')}>
                                        { t('Hele välimus') }
                                    </a>
                                </div>
                            </div>
                            <div className='app-header--actions'>
                                <Button
                                    label={t('Sign out')}
                                    onClick={signOut}
                                />
                            </div>
                        </>
                    }
                </nav>
                <div className="menu-btn" onClick={toggleMenu}>
                    <div></div>
                </div>
            </div>
            <PasswordChangeModal
                show={showPassModal}
                title={'Sinu salasõna on aegunud, palun vali uus salasõna.'}
            />
        </header>
    )
}

export default AppHeader