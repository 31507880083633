import React, { createContext, useReducer, useContext } from 'react'
import { createPortal } from 'react-dom'
import Notification from '../components/Notifiction'

const initialState = []

export const NotificationContext = createContext()

export const ADD = 'ADD'
export const REMOVE = 'REMOVE'
export const REMOVE_ALL = 'REMOVE_ALL'

export const notificationReducer = (state, { type, payload }) => {
  switch (type) {
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          content: payload.content,
          type: payload.type
        }
      ]
    case REMOVE:
      return state.filter(t => t.id !== payload.id)
    case REMOVE_ALL:
      return initialState
    default:
      return state
  }
}

export const NotificationProvider = (props) => {
  const [notifications, dispatch] = useReducer(notificationReducer, initialState)
  const notificationData = { notifications, dispatch }

  return (
    <NotificationContext.Provider value={notificationData}>
      { props.children }
      { createPortal(<Notification />, document.body) }
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  return useContext(NotificationContext)
}