import React from 'react'

const MinusIcon = ({ fill }) => {

    return (
        <svg version="1.1" baseProfile="tiny" fill={fill ? fill : '#000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.161 52.161" xmlSpace="preserve">
            <path d="M52.161,26.081c0,3.246-2.63,5.875-5.875,5.875H5.875C2.63,31.956,0,29.327,0,26.081l0,0c0-3.245,2.63-5.875,5.875-5.875
		        h40.411C49.531,20.206,52.161,22.835,52.161,26.081L52.161,26.081z"/>
        </svg>

    )
}

export default MinusIcon