import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const BoxColorFields = ({
    onColorChange,
    color1,
    color2,
    color3,
    color4,
    pantone1,
    pantone2,
    pantone3,
    pantone4,
    onFieldBlur,
    pantoneOnly,
}) => {

    const { t } = useTranslation()

    return (
        <div className='box-fields--colors'>
            { !pantoneOnly ? <h5>{t('Värvid')}</h5> : <></> }
            <div>
                {
                    !pantoneOnly ?
                        <div className='box-fields--colors-column'>
                            <Input
                                label={`${t('Värv')}:`}
                                value={color1}
                                onChange={(e) => onColorChange('COLOR1', e.target.value)}
                                onBlur={onFieldBlur}
                            />
                            <Input
                                label={`${t('Värv')}:`}
                                value={color2}
                                onChange={(e) => onColorChange('COLOR2', e.target.value)}
                                onBlur={onFieldBlur}
                            />
                            <Input
                                label={`${t('Värv')}:`}
                                value={color3}
                                onChange={(e) => onColorChange('COLOR3', e.target.value)}
                                onBlur={onFieldBlur}
                            />
                            <Input
                                label={`${t('Värv')}:`}
                                value={color4}
                                onChange={(e) => onColorChange('COLOR4', e.target.value)}
                                onBlur={onFieldBlur}
                            />
                        </div>
                        :
                        <></>
                }
                <div className='box-fields--colors-column'>
                    <Input
                        label={`${t('Pantone nr')}:`}
                        value={pantone1}
                        onChange={(e) => onColorChange('PANTONE1', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                    <Input
                        label={`${t('Pantone nr')}:`}
                        value={pantone2}
                        onChange={(e) => onColorChange('PANTONE2', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                    <Input
                        label={`${t('Pantone nr')}:`}
                        value={pantone3}
                        onChange={(e) => onColorChange('PANTONE3', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                    <Input
                        label={`${t('Pantone nr')}:`}
                        value={pantone4}
                        onChange={(e) => onColorChange('PANTONE4', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                </div>
            </div>
        </div>
    )
}

export default BoxColorFields