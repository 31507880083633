import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation } from '@apollo/client'
import { GetLocations } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateLocation, RemoveLocation, UpdateLocation } from '../../graphql/mutations'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import FilterTable from '../../components/FilterTable'

const includeFields = [
    {
        value: 'ID',
        label: 'ID',
    },
    {
        value: 'LABEL',
        label: 'Nimetus',
    },
]

const initialState = {
    label: '',
}

const LocationsScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState(initialState)
    
    const [updateItem] = useMutation(UpdateLocation)
    const [createItem] = useMutation(CreateLocation)
    const [removeItem] = useMutation(RemoveLocation)

    const addItem = async () => {
        if (!validateFields()) return

        try {
            const { data } = await createItem({
                variables: newItem,
            })
            if (data.createLocation) {
                setShowModal(false)
                setNewItem({...initialState})
                tableRef.current?.refresh()
            }
        } catch (err) {
            console.log('Error creating location', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return

        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(selectedRow.ID, 10),
                },
            })

            if (data?.removeLocation === 'Success') {
                setSelectedRow(null)
                tableRef.current?.refresh()
            }
        } catch (err) {
            console.log('Error deleting material', err)
        }
    }

    const updateProperty = async (item, field, value) => {
        if (!item) return

        try {
            await updateItem({
                variables: {
                    id: parseInt(item.ID, 10),
                    [field.toLowerCase()]: value,
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ID':
                return row.ID
            default:
                return <TableInput
                    initialValue={row[field]}
                    label={field}
                    fieldUpdated={(field, value) => {
                        updateProperty(row, field, value)
                    }}
                />
        }
    }

    const handleRowClick = async (row) => {
        setSelectedRow(row)
    }

    const validateFields = () => {
        if (!newItem.label) return false
        return true
    }

    return (
        <div className='locations-screen'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Asukohad') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>
                </div>
                <FilterTable
                    ref={tableRef}
                    query={GetLocations}
                    queryKey={'getLocations'}
                    fieldConditions={fieldConditions}
                    includeFields={includeFields}
                    initialOrderBy={'ID'}
                    onRowClick={handleRowClick}
                    activeRows={selectedRow ? [selectedRow] : []}
                />
            </div>
            <Modal
                title={t('Uus asukoht')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus asukoht') }</h5>
                <Input
                    label={`${t('Nimetus')}*:`}
                    value={newItem.label}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        label: e.target.value,
                    })}
                />
                <Button
                    label={t('Lisa asukoht')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default LocationsScreen