import { useMutation } from '@apollo/client'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Button from '../../components/Button'
import MaterialOrderEngDocument from '../../documents/material-order-en'
import MaterialOrderInternalDocument from '../../documents/material-order-internal'
import MaterialOrderRusDocument from '../../documents/material-order-ru'
import OrderDocument from '../../documents/order-document'
import { SendOrderEmail } from '../../graphql/mutations'
import { useNotification } from '../../providers/Notification'
import LostClientsReport from '../../reports/lost-clients'
import './style.scss'
import { useConstants } from '../../providers/Constants'

const PdfViewerScreen = () => {

    const { state } = useLocation()
    const { constants } = useConstants()
    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [sendEmail] = useMutation(SendOrderEmail)

    const getDocument = () => {
        switch (state.document) {
            case 'order':
                return <OrderDocument data={state.data} constants={constants} />
            case 'material-eng':
                return <MaterialOrderEngDocument data={state.data} />
            case 'material-rus':
                return <MaterialOrderRusDocument data={state.data} />
            case 'material-internal':
                return <MaterialOrderInternalDocument data={state.data} />
            case 'lost-clients':
                return <LostClientsReport data={state.data} />
        }
    }

    const handleEmail = async () => {
        if (state?.data?.length < 1) return
        try {
            const pdfFile = pdf(getDocument())
            pdfFile.updateContainer(getDocument())
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const res = await sendEmail({
                variables: {
                    orderNo: parseInt(state.data[0].TELLIMUSNO),
                    file,
                },
            })

            if (res?.data?.sendOrderEmail === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Email saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Emaili saatmine ebaõnnestus')
                    },
                })
            }
        } catch (err) {
            console.log('handleEmail:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    return (
        <div className='pdf-viewer'>
            <div className='inner'>
                <div className='pdf-viewer--actions'>
                    {
                        state?.document === 'order' ?
                        <Button
                            label={t('Saada email')}
                            onClick={handleEmail}
                        />
                        :
                        <></>
                    }
                </div>
                <PDFViewer>
                    { getDocument() }
                </PDFViewer>
            </div>
        </div>
    )
}

export default PdfViewerScreen