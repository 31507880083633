import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateInvoiceExtraRow, CreateInvoiceRow, RemoveInvoiceExtraRow, RemoveInvoiceRow } from '../graphql/mutations'
import { GetExtraProducts, GetInvoiceExtraRows, GetInvoiceRows, SearchProduction } from '../graphql/queries'
import Button from './Button'
import Input from './Input'
import SelectInput from './SelectInput'

const InvoiceExtraRows = ({ invoiceId }) => {

    const { t } = useTranslation()
    const [rows, setRows] = useState([])
    const [rowHeaders, setRowHeaders] = useState([
        {
            value: 'product',
            label: t('Kauba nimetus'),
        },
        {
            value: 'AMOUNT',
            label: t('Kogus'),
        },
        {
            value: 'PRICE',
            label: t('Hind'),
        },
    ])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [price, setPrice] = useState(0)
    const [amount, setAmount] = useState(0)
    const [products, setProducts] = useState([])
    const [createInvoiceRow] = useMutation(CreateInvoiceExtraRow)
    const [removeInvoiceRow] = useMutation(RemoveInvoiceExtraRow)

    const { refetch } = useQuery(GetInvoiceExtraRows, {
        variables: {
            invoiceId: parseInt(invoiceId),
        },
        onCompleted: (data) => {
            const { getInvoiceExtraRows } = data
            if (getInvoiceExtraRows) setRows(getInvoiceExtraRows)
        }
    })

    useQuery(GetExtraProducts, {
        onCompleted: (data) => {
            const { getExtraProducts } = data
            if (getExtraProducts) setProducts(getExtraProducts)
        }
    })

    const handleAddRow = async (prodId) => {
        try {
            await createInvoiceRow({
                variables: {
                    data: {
                        INVOICE_ID: parseInt(invoiceId),
                        PRODUCT_ID: parseInt(prodId),
                        PRICE: parseFloat(price),
                        AMOUNT: parseInt(amount),
                    },
                },
            })
            setSelectedProduct(null)
            setPrice(0)
            setAmount(0)
        } catch (err) {
            console.log('handleAddRow', err)
        }
    }

    const handleDeleteRow = async (id) => {
        try {
            await removeInvoiceRow({
                variables: {
                    id: parseInt(id),
                },
            })
            refetch()
        } catch (err) {
            console.log('handleDeleteRow', err)
        }
    }

    const handleAddProduct = async () => {
        if (selectedProduct && selectedProduct.ID) {
            await handleAddRow(selectedProduct.ID)
            refetch()
        }
    }

    const getColumnValue = (row, field) => {
        if (field === 'product') {
            return `${row.product.PRODUCT}`
        }
        return row[field]
    }

    return (
        <div className='invoice-rows'>
            <div className='product-search'>
                <h5>{ `${t('Lisatooted')}` }</h5>
                <div className='product-search--inner'>
                    <SelectInput
                        options={products}
                        value={selectedProduct}
                        getOptionLabel={(option) => option.PRODUCT}
                        getOptionValue={(option) => option.ID}
                        loadingMessage={() => t('Laen tooteid')}
                        noOptionsMessage={() => t('Kirjuta, et otsida tooteid')}
                        onChange={(option) => setSelectedProduct(option)}
                    />
                    <Input
                        placeholder={t('Kogus')}
                        value={amount}
                        type={'number'}
                        onChange={(e) => setAmount(parseInt(e.target.value))}
                    />
                    <Input
                        placeholder={t('Hind')}
                        value={price}
                        type={'number'}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                    />
                    <Button
                        label={t('Lisa arvele')}
                        onClick={handleAddProduct}
                    />
                </div>
            </div>
            <div className='invoice-rows-list'>
                { rows.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                rows.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { getColumnValue(row, headerItem.value) }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={'Kustuta'}
                                                onClick={() => handleDeleteRow(row.ID)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Ühtegi rida pole lisatud') }
                    </div>
                }
            </div>
        </div>
    )
}

export default InvoiceExtraRows