import React from 'react'
import { useTranslation } from 'react-i18next'
import BoxColorFields from './BoxColorFields'
import Input from './Input'
import Textarea from './Textarea'

const BoxMaterialFields = ({
    perSheet,
    matrix,
    printingPress,
    onPerSheetChange,
    onMatrixChange,
    onPrintingPressChange,
    onColorChange,
    onCommentChange,
    color1,
    color2,
    color3,
    color4,
    pantone1,
    pantone2,
    pantone3,
    pantone4,
    comment,
    onFieldBlur,
}) => {

    const { t } = useTranslation()

    return (
        <div className='box-fields--material-tab'>
            <div className='field-row'>
                <div className='field-col'>
                    <Input
                        label={`${t('Lehest karpe')}:`}
                        value={perSheet}
                        type={'number'}
                        onChange={(e) => onPerSheetChange('PER_SHEET', parseInt(e.target.value))}
                        onBlur={onFieldBlur}
                    />
                    <Input
                        label={`${t('Matriitsi nr.')}:`}
                        value={matrix}
                        onChange={(e) => onMatrixChange('MATRIITS', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                    <Input
                        label={`${t('Trükiklišee nr.')}:`}
                        value={printingPress}
                        onChange={(e) => onPrintingPressChange('KLISHEE', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                </div>
                <div className='field-col'>
                    <Textarea
                        label={`${t('Märkus')}`}
                        value={comment}
                        onChange={(e) => onCommentChange('COMMENT', e.target.value)}
                        onBlur={onFieldBlur}
                    />
                </div>
            </div>
            <BoxColorFields
                color1={color1}
                color2={color2}
                color3={color3}
                color4={color4}
                pantone1={pantone1}
                pantone2={pantone2}
                pantone3={pantone3}
                pantone4={pantone4}
                onColorChange={onColorChange}
                onFieldBlur={onFieldBlur}
            />
        </div>
    )
}

export default BoxMaterialFields