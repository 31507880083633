import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from './icons/search'
import Input from './Input'

const Search = ({
    search,
    placeholder,
}) => {

    const { t } = useTranslation()
    const [input, setInput] = useState('')

    const handleChange = ({ target }) => {
        setInput(target.value)
        if (search) search(target.value)
    }

    return (
        <div className='search'>
            <div className='search-inner'>
                <Input
                    placeholder={placeholder ? placeholder : t('Otsi')}
                    value={input}
                    onChange={handleChange}
                />
                <div className='search-icon'>
                    <SearchIcon />
                </div>
            </div>
        </div>
    )
}

export default Search