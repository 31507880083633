import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetSalesReport } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Pie } from 'react-chartjs-2'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'
import { parseDate } from '../../util/format'
import DateInput from '../../components/DateInput'

const initialResult = {
    labels: [],
    datasets: [],
}

const ReportSalesScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)

    const { refetch } = useQuery(GetSalesReport, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start,
            end,
            shipped,
        },
        onCompleted: (data) => {
            if (data?.getSalesReport) {
                const sorted = data.getSalesReport.sort((a, b) => (a.value - b.value))
                setResult({
                    labels: sorted.map(x => x.label),
                    datasets: [{
                        id: 1,
                        label: 'Realisatsioon',
                        data: sorted.map(x => x.value),
                        backgroundColor: chartColors,
                    }],
                })
            }
        },
    })

    useEffect(() => {
        (async () => {
            await refetch()
        })()
    }, [])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        start,
        end,
        shipped,
    ])

    return (
        <div className='report-sales'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Realisatsioon') }</h2>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                <div className='chart-wrapper'>
                {
                    result &&
                    <Pie
                        ref={chartRef}
                        datasetIdKey='1'
                        plugins={[ChartDataLabels]}
                        options={{
                            layout: {
                                padding: 100,
                            },
                            plugins: {
                                legend: {
                                    position: 'right',
                                    align: 'start',
                                    labels: {
                                        generateLabels(chart) {
                                            const data = chart.data
                                            if (data.labels.length && data.datasets.length) {
                                              const {labels: {pointStyle}} = chart.legend.options
                                  
                                              return data.labels.map((label, i) => {
                                                const meta = chart.getDatasetMeta(0)
                                                const style = meta.controller.getStyle(i)
                                                const labelValue = result.datasets[0].data[i]
                                  
                                                return {
                                                  text: `${Number(labelValue).toLocaleString('et')} - ${label}`,
                                                  fillStyle: style.backgroundColor,
                                                  strokeStyle: style.borderColor,
                                                  lineWidth: style.borderWidth,
                                                  pointStyle: pointStyle,
                                                  hidden: !chart.getDataVisibility(i),
                                                  index: i,
                                                }
                                              })
                                            }
                                            return []
                                        },
                                    },
                                },
                                datalabels: {
                                    align: 'end',
                                    anchor: 'end',
                                    clip: false,
                                    offset: 10,
                                    formatter: (value, ctx) => {
                                        console.log(ctx)
                                        const total = ctx.dataset.data.reduce((acc, curr) => (acc+parseFloat(curr)), 0)
                                        const percentage = `${result.labels[ctx.dataIndex]}\n${(value * 100 / total).toFixed(2)}%`
                                        return percentage
                                    }
                                },
                            },
                        }}
                        data={result}
                    />
                }
                </div>
            </div>
        </div>
    )
}

export default ReportSalesScreen