import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateDeliveryNoteRow, RemoveDeliveryNoteRow, UpdateDeliveryNoteRow } from '../graphql/mutations'
import { GetDeliveryNoteRows, SearchProduction } from '../graphql/queries'
import Button from './Button'
import Input from './Input'
import SelectInput from './SelectInput'

const DeliveryNoteRows = ({ noteId }) => {

    const { t } = useTranslation()
    const [rows, setRows] = useState([])
    const rowHeaders = [
        {
            value: 'product',
            label: t('Toodangu nr'),
        },
        {
            value: 'AMOUNT',
            label: t('Kogus'),
        },
        {
            value: 'KLISHEE',
            label: t('Klishee'),
        },
        {
            value: 'STANTS',
            label: t('Stants'),
        },
        {
            value: 'TRANSPORT',
            label: t('Transport'),
        },
        {
            value: 'KLIENT_ORDER',
            label: t('Tellimus'),
        },
        {
            value: 'PALLET',
            label: t('Alus'),
        },
    ]
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [amount, setAmount] = useState(null)
    const [createRow] = useMutation(CreateDeliveryNoteRow)
    const [removeRow] = useMutation(RemoveDeliveryNoteRow)
    const [updateRow] = useMutation(UpdateDeliveryNoteRow)

    const { refetch } = useQuery(GetDeliveryNoteRows, {
        variables: {
            noteId: parseInt(noteId),
        },
        onCompleted: (data) => {
            const { getDeliveryNoteRows } = data
            if (getDeliveryNoteRows) setRows(getDeliveryNoteRows)
        }
    })

    const { refetch: searchProduction } = useQuery(SearchProduction, {
        skip: true,
    })

    const handleAddRow = async (prodId) => {
        try {
            await createRow({
                variables: {
                    data: {
                        INVOICE_ID: parseInt(noteId),
                        PRODUCT_ID: parseInt(prodId),
                        AMOUNT: parseInt(amount),
                    },
                },
            })
            setSelectedProduct(null)
            setAmount(null)
        } catch (err) {
            console.log('handleAddRow', err)
        }
    }

    const handleDeleteRow = async (id) => {
        try {
            await removeRow({
                variables: {
                    id: parseInt(id),
                },
            })
            refetch()
        } catch (err) {
            console.log('handleDeleteRow', err)
        }
    }

    const updateProperty = async (row, field, value) => {
        try {
            await updateRow({
                variables: {
                    id: parseInt(row),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const setField = (row, field, value) => {
        const newRows = rows.map(x => {
            if (x.ID === row) return {
                ...x,
                [field]: value,
            }
            return x
        })
        setRows(newRows)
        updateProperty(row, field, value)
    }

    const handleRowProductChange = async (row, option) => {
        const newRows = rows.map(x => {
            if (x.ID === row) return {
                ...x,
                production: option,
            }
            return x
        })
        setRows(newRows)
        updateProperty(row, 'PRODUCT_ID', parseInt(option.ID))
    }

    const getColumnValue = (row, field) => {
        if (field === 'AMOUNT') return (
            <Input
                className={'amount'}
                value={row.AMOUNT}
                placeholder={t('Kogus')}
                onChange={(e) => setField(row.ID, field, parseInt(e.target.value))}
            />
        )

        const { production } = row

        if (production) {
            if (field === 'product') {
                return (
                    <SelectInput
                        load={loadProducts}
                        value={rows.find(x => x.ID === row.ID)?.production}
                        getOptionLabel={displayProductLabel}
                        getOptionValue={(option) => option.ID}
                        loadingMessage={() => t('Laen tooteid')}
                        noOptionsMessage={() => t('Kirjuta, et tooteid otsida')}
                        onChange={(option) => handleRowProductChange(row.ID, option)}
                    />
                )
            }
            return production[field]
        }

        return ''
    }

    const loadProducts = async (input) => {
        if (input.length < 2) return
        const res = await searchProduction({
            searchInput: input,
        })
        return res.data.searchProduction
    }

    const handleAddProduct = async () => {
        if (selectedProduct && selectedProduct.ID) {
            await handleAddRow(selectedProduct.ID)
            refetch()
        }
    }

    const displayProductLabel = (option) => {
        return `${option.ORDERNO}, ${option.MOOT}${option.ALUSEID ? ', ' + option.ALUSEID : ''}`
    }

    return (
        <div className='delivery-note-rows'>
            <div className='product-search'>
                <h5>{ `${t('Toote otsing')}` }</h5>
                <div className='product-search--inner'>
                    <SelectInput
                        load={loadProducts}
                        value={selectedProduct}
                        getOptionLabel={displayProductLabel}
                        getOptionValue={(option) => option.ID}
                        loadingMessage={() => t('Laen tooteid')}
                        noOptionsMessage={() => t('Kirjuta, et tooteid otsida')}
                        onChange={(option) => setSelectedProduct(option)}
                    />
                    <Input
                        className={'amount'}
                        value={amount}
                        placeholder={t('Kogus')}
                        onChange={(e) => setAmount(parseInt(e.target.value))}
                    />
                    <Button
                        label={t('Lisa saatelehele')}
                        onClick={handleAddProduct}
                    />
                </div>
            </div>
            <div className='delivery-note-rows-list'>
                { rows.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                rows.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`,
                                            position: 'relative',
                                            zIndex: rows?.length - index,
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { getColumnValue(row, headerItem.value) }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={'Kustuta'}
                                                onClick={() => handleDeleteRow(row.ID)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Andmed puuduvad') }
                    </div>
                }
            </div>
        </div>
    )
}

export default DeliveryNoteRows