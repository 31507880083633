import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import FileUpload from './FileUpload'
import Modal from './Modal'
import { ImportMaterialCodes } from '../graphql/mutations'

const ImportMaterialCodeModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [importMaterialCodes] = useMutation(ImportMaterialCodes)

    const handleImport = async (file) => {
        setLoading(true)
        
        try {
            const res = await importMaterialCodes({
                variables: {
                    file,
                },
            })

            if (!res?.data?.importMaterialCodes?.message === 'FAIL') {
                if (res.data.importMaterialCodes.failed?.length > 0) {
                    const failList = res.data.importMaterialCodes.failed.map(x => `Klass: ${x.CLASS}, Mark: ${x.MARK}, Reason: ${x.error}\n`)
                    setError(`${t('Importimisel tekkisid järgmised vead')}\n\n${failList.join('\n')}`)
                    return
                }
                setError(t('Viga importimisel'))
                return
            }

            if (onSuccess) onSuccess()
        } catch (err) {
            setError(t('Viga importimisel'))
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
    }

    return (
        <Modal
            className={'modal-materials'}
            show={show}
            close={handleClose}
            title={ t('Impordi materjali koodid') }
        >
            <FileUpload
                handleUpload={handleImport}
                loading={loading}
            />

            {
                error ?
                <div className='error-message'>
                    <span>{ error }</span>
                </div>
                :
                <></>
            }
        </Modal>
    )
}

export default ImportMaterialCodeModal