import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { DeliveryNotesQuery, GetDeliveryNoteRows } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CreateDeliveryNote, RemoveDeliveryNote, SendToBookkeeping } from '../../graphql/mutations'
import Button from '../../components/Button'
import { formatDate } from '../../util/format'
import { BlobProvider, pdf } from '@react-pdf/renderer'
import DeliveryNoteDocument from '../../documents/deliverynote-document'
import DeliveryNoteInvoiceDocument from '../../documents/deliverynote-invoice-document'
import { useNotification } from '../../providers/Notification'
import FilterTable from '../../components/FilterTable'
import ConfirmModal from '../../components/ConfirmModal'
import { useConstants } from '../../providers/Constants'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Number',
    },
    {
        value: 'CREATED_DATE',
        label: 'Kuupäev',
    },
    {
        value: 'client.NAME',
        label: 'Klient',
    },
    {
        value: 'client.ADDRESS',
        label: 'Aadress',
    },
    {
        value: 'author.RNAME',
        label: 'Koostaja',
    },
    {
        value: 'PALLETS',
        label: 'Aluseid',
    },
    {
        value: 'PALLET_COSTS',
        label: 'Tasuline alus',
    },
    {
        value: 'BOOKKEEPING',
        label: 'Raamatupidamisse',
    },
]

const DeliveryNotesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const { dispatch } = useNotification()
    const { constants } = useConstants()
    const navigate = useNavigate()
    const [selectedRow, setSelectedRow] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    
    const [createDeliveryNote] = useMutation(CreateDeliveryNote)
    const [sendToBookkeeping] = useMutation(SendToBookkeeping)
    const [deleteNote] = useMutation(RemoveDeliveryNote)

    const { refetch: getRows } = useQuery(GetDeliveryNoteRows, { skip: true, fetchPolicy: 'no-cache' })

    const handleRowDoubleClick = (row) => {
        window.open(`/deliverynote/${row.ID}`)
    }

    const createItem = async () => {
        try {
            const { data } = await createDeliveryNote()

            if (data?.createDeliveryNote) {
                navigate(`/deliverynote/${data.createDeliveryNote}`)
            }
        } catch (err) {
            console.log('Error creating delivery note', err)
        }
    }

    const handleRowClick = async (row) => {
        if (!Object.is(row, selectedRow)) {
            const selected = {
                ...row,
                PALLETPRICE: constants.PALLETPRICE_DOM,
            }

            try {
                const { data } = await getRows({
                    noteId: parseInt(row.ID),
                })
                if (data && data.getDeliveryNoteRows) {
                    selected.rows = data.getDeliveryNoteRows
                }
                setSelectedRow(selected)
            } catch (err) {
                console.log('Error selecting row:', err)
                setSelectedRow(row)
            }

            return
        }
        setSelectedRow(null)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'CREATED_DATE':
                return formatDate(new Date(row[field]))
            case 'author.RNAME':
                return row?.author?.RNAME
            case 'client.NAME':
                return row?.client?.NAME
            case 'client.ADDRESS':
                return row?.client?.ADDRESS
        }
    }

    const handleBookkeeping = async () => {
        try {
            const pdfFile = pdf(<DeliveryNoteInvoiceDocument data={selectedRow} />)
            pdfFile.updateContainer(<DeliveryNoteInvoiceDocument data={selectedRow} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const { data } = await sendToBookkeeping({
                variables: {
                    id: parseInt(selectedRow.ID),
                    file,
                },
            })

            if (data?.sendToBookkeeping === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Edastatud raamatupidamisse'),
                        type: 'success',
                    },
                })
                return
            } 

            dispatch({
                type: 'ADD',
                payload: {
                    content: t(`Viga raamatupidamisse saatmisel: ${data?.sendToBookkeeping}`),
                    type: 'error',
                },
            })
        } catch (err) {
            console.log('handleBookkeeping:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    content: err,
                    type: 'error',
                },
            })
        }
    }

    const handleDelete = async () => {
        try {
            await deleteNote({
                variables: {
                    id: parseInt(selectedRow.ID),
                },
            })
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Saateleht') + ` ${selectedRow.ORDERNO} ` + t('kustutatud'),
                    type: 'success',
                },
            })
            tableRef.current?.refresh()
            setSelectedRow(null)
            setDeleteModal(false)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const openPdf = (url) => {
        window.open(url, '_blank')
    }

    return (
        <div className='delivery-notes'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Saatelehed') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Lisa saateleht')}
                            onClick={createItem}
                        />
                        {
                            selectedRow ?
                            <BlobProvider document={<DeliveryNoteDocument data={selectedRow} />}>
                                {
                                    ({url}) => {
                                        return (
                                            <Button
                                                label={t('Prindi')}
                                                onClick={() => openPdf(url)}
                                            />
                                        )
                                    }
                                }
                            </BlobProvider>
                            :
                            <Button
                                disabled
                                label={t('Prindi')}
                            />
                        }
                        <Button
                            label={t('Kustuta arve')}
                            onClick={() => setDeleteModal(true)}
                        />
                        <Button
                            label={t('Raamatupidamisse')}
                            onClick={handleBookkeeping}
                            disabled={!selectedRow}
                        />
                    </div>
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={DeliveryNotesQuery}
                        queryKey={'deliveryNotesQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ID'}
                        activeRows={selectedRow ? [selectedRow] : []}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                    />
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('Kustuta')}
                close={() => setDeleteModal(false)}
                onConfirm={handleDelete}
                title={t('Kas soovid saatelehe kustutada?')}
            />
        </div>
    )
}

export default DeliveryNotesScreen