import { useMutation, useQuery } from '@apollo/client'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { PlanningQuery } from '../graphql/queries'
import { formatDate } from '../util/format'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../providers/Notification'
import PlanTableRow from './PlanTableRow'
import { UpdatePlan, CreatePlan, RemovePlan, AssignPlanDate } from '../graphql/mutations'
import { DateTime } from 'luxon'

const getStatusColor = (row, isActive) => {
    if (new Date().toISOString() > row.PLANNED_ENDDATE && !row.COMPLETED) return { backgroundColor: 'rgb(255 145 178)' } 
    if (row.LOADED) return { backgroundColor: '#5e155e', color: !isActive ? '#dfdfe6' : undefined } 
    if (row.ARRIVED_AMOUNT > 0) return { backgroundColor: 'rgb(185 146 41)' }
    if (row.FIN_DATE) return { backgroundColor: '#01a045' } 
    if (row.HALTED) return { backgroundColor:  'rgb(233 142 118)' }
    if (row.STARTED) return { backgroundColor: 'rgb(116 157 193)' } 
    return { backgroundColor: 'transparent' }
}

const PlanTable = forwardRef(({
    machineId,
    machineMaxVolume,
    includeFields,
    onRowClick,
    onRowDoubleClick,
    fieldConditions,
    subscriptions,
    allowSelect,
    activeRows,
}, ref) => {
    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [items, setItems] = useState([])
    const { data, refetch, subscribeToMore, loading } = useQuery(PlanningQuery, {
        notifyOnNetworkStatusChange: true,
        skip: !machineId,
        fetchPolicy: 'cache-and-network',
        variables: {
            machineId: parseInt(machineId, 10),
        },
    })
    const [updatePlan] = useMutation(UpdatePlan)
    const [savePlan] = useMutation(CreatePlan)
    const [removePlan] = useMutation(RemovePlan)
    const [assignDate] = useMutation(AssignPlanDate)
    const [selectedRow, setSelectedRow] = useState(null)


    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 5000)

        return () => clearInterval(interval)
    }, [machineId])
    
    useEffect(() => {
        if (loading) return

        setItems(data?.planningQuery || [])
    }, [data, loading])

    useEffect(() => {
        const unsubscribe = []

        if (subscriptions?.length > 0) {
            subscriptions.forEach(({ document, updateQuery }) => {
                const unsub = subscribeToMore({
                    document,
                    updateQuery,
                })
                unsubscribe.push(unsub)
            })
        }

        return () => {
            unsubscribe.forEach(unsub => {
                unsub()
            })
        }
    }, [])

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refetch()
        },
    }))

    const getColValue = (row, field) => {
        const conditionResult = fieldConditions ? fieldConditions(row, field) : null
        return conditionResult ? conditionResult : (typeof row[field] === 'object' ? '' : row[field])
    }

    const handleAssignDate = async (newItem, newItemIndex, prevItem, prevItemIndex) => {
       try {
            const prevStartDate = prevItem.PLANNED_STARTDATE ? new Date(prevItem.PLANNED_STARTDATE) : undefined
            if (newItem.PLANNING_ID) {
                const { data } = await assignDate({
                    variables: {
                        planId: newItem.PLANNING_ID,
                        date: prevStartDate,
                    },
                })
                if (data.assignPlanDate.status !== 200 && data.assignPlanDate.error) {
                    handleErrors(data.assignPlanDate)
                } else {
                    ref.current.refresh()
                }
            }
            else {
                const { data } =
                    await savePlan({
                        variables: {
                            data: {
                                MACHINE_ID: machineId,
                                PROD_ID: newItem?.ISBUFFER ? undefined : newItem.ID,
                                BUFFER_ID: newItem?.ISBUFFER ? newItem.ID : undefined,
                                PRODUCTION_CODE: newItem?.PRODUCTION_CODE ? newItem.PRODUCTION_CODE : undefined,
                            },
                            date: prevStartDate
                        },
                    })
                if (data.createPlan.status !== 200 && data.createPlan.error) {
                    handleErrors(data.createPlan)
                } else {
                    ref.current.refresh()
                }
            }

        } catch (e) {
            {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: e.message,
                    },
                })
            }
        }
    }

    const handleErrors = (response) => {
        let errorMessage = null
        const error = response.error
        switch (error) {
            case 'INVALID_PLAN_START_DATE':
                errorMessage = `Plaan ei saa olla varasem eelmise masina planeeritud lõpukuupäevast ${response.data}`
                break
            case 'MACHINE_NOT_FIRST_IN_ORDER':
                const parsedData = JSON.parse(response.data)
                errorMessage = `Selle toote planeerimine algab masinast ${parsedData.label} (${parsedData.code})`
                break
            case 'MOVING_COMPLETED_PLAN_TO_UNPLANNED':
                errorMessage = `Lõppenud plaani ei saa planeerimata alasse liigutada`
                break
            default:
                break
        }
        dispatch({
            type: 'ADD',
            payload: {
                type: 'error',
                content: errorMessage,
            },
        })
    }

    const getVolumeClass = (itemList) => {
        let dateVolume = itemList.reduce((acc, item) => acc += item.KOGUS, 0)

        // dateVolume += 100000

        return dateVolume > machineMaxVolume ? ' plan-table-volume-warning' : ''
    }

    const handleRowClick = (row, e, index, itemsIndex) => {
        if (!onRowClick) return

        if (allowSelect && e.shiftKey && selectedRow) {
            const first = items[itemsIndex].ITEMS.findIndex(i => i.ID === selectedRow.ID)
            const currentIndex = index
            let selectedItems = []

            if (first > currentIndex) selectedItems = items[itemsIndex].ITEMS.filter((x, i) => i <= first && i >= currentIndex)
            if (first < currentIndex) selectedItems = items[itemsIndex].ITEMS.filter((x, i) => i >= first && i <= currentIndex)

            onRowClick(selectedItems)
            return
        }

        if (allowSelect && (e.ctrlKey || e.metaKey) && activeRows?.length > 0) {
            onRowClick([row, ...activeRows])
            return
        }
        setSelectedRow(row)
        onRowClick(row)
    }

    const getUnplannedItems = (itemsList) => {
        return !itemsList?.length ? [] : itemsList.reduce((acc, curr) => {
            const startDate = curr.STARTDATE 
                ? DateTime.fromISO(curr.STARTDATE).startOf('day') 
                : null
        
            if (!startDate) {
                const existingIndex = acc.findIndex(i => i.DATE === null)
                if (existingIndex > -1) {
                    // If an existing group is found, add the current item to it
                    acc[existingIndex].ITEMS.push(curr)
                } else {
                    
                    acc.push({
                        DATE: null, 
                        ITEMS: [curr],
                    })
                }
            } else {
                const existingIndex = acc.findIndex(i => i.DATE === startDate.toISODate())
        
                if (existingIndex > -1) {
                    acc[existingIndex].ITEMS.push(curr)
                } else {
                    acc.push({
                        DATE: startDate.toISODate(), 
                        ITEMS: [curr],
                    })
                }
            }
        
            return acc
        }, []).sort((a, b) => (a.DATE < b.DATE ? 1 : -1))
    }

    const getPlannedItems = (itemsList) => {
        return !itemsList?.length ? [] : itemsList.reduce((acc, curr) => {
            const startDate = curr.PLANNED_STARTDATE ?
                DateTime.fromISO(curr.PLANNED_STARTDATE).startOf('day')
                : DateTime.fromISO(curr?.DATE).startOf('day')
        
            if (!startDate) {
                return acc
            }
        
            const existingIndex = acc.findIndex(i => {
                const existingDate = i.PLANNED_STARTDATE 
                    ? DateTime.fromJSDate(i.PLANNED_STARTDATE).startOf('day') 
                    : DateTime.fromISO(i.DATE).startOf('day')

                return existingDate.toISODate() === startDate.toISODate()
            })
        
            if (existingIndex > -1) {
                acc[existingIndex].ITEMS.push(curr)
            } else {
                acc.push({
                    DATE: startDate.toISODate(),
                    ITEMS: [curr],
                })
            }
            
            return acc
        }, []).sort((a, b) => b.DATE < a.DATE ? 1 : -1)
    }

    return (
        <div className='plan-table'>
            <div className='plan-table-wrapper'>
            <table id="plan-table">
                <thead>
                    <tr>
                        {
                            includeFields.map((header, index) =>
                                <th key={`plan-table-header-${header.value}`}>
                                    <span>{t(header.label)}</span>
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        getPlannedItems(items.planned).map((item, itemsIndex) =>
                            <React.Fragment key={`plan-table-date-${item.DATE || 'unplanned'}-${itemsIndex}`}>
                                <tr>
                                    <td className={`plan-table-date${item?.DATE ? getVolumeClass(item.ITEMS) : ''}`} colSpan={includeFields.length}>{!item?.DATE ? 'Planeerimata' : formatDate(new Date(item.DATE))}</td>
                                </tr>
                                {
                                    item.ITEMS?.map((row, index) =>
                                    {
                                        return (
                                            <PlanTableRow
                                            key={`plan-row-${row.ID}-${item.DATE}`} 
                                            index={index}
                                            rowData={row}
                                            group={item.DATE}
                                            includeFields={includeFields}
                                            getStatusColor={getStatusColor}
                                            onRowClick={(rowData, e) => handleRowClick(rowData, e, index, itemsIndex)}
                                            onRowDoubleClick={onRowDoubleClick}
                                            getColValue={getColValue}
                                            onOrderChange={handleAssignDate}
                                            isActive={activeRows?.some(i => i.ID === row.ID)}
                                        />
                                        )
                                    }
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </tbody>
            </table>
            </div>
            <div className='plan-table-wrapper'>
            <table id="plan-table-unplanned">
                <thead>
                    <tr>
                        {
                            includeFields.map((header, index) =>
                                <th key={`plan-table-unplanned-header-${header.value}`}>
                                    <span>{t(header.label)}</span>
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        getUnplannedItems(items?.unplanned)?.map((item, itemsIndex) =>
                            <React.Fragment key={`plan-table-date-${item.DATE || 'unplanned'}`}>
                                <tr>
                                    <td className={`plan-table-date${item?.DATE ? getVolumeClass(item.ITEMS) : ''}`} colSpan={includeFields.length}>{!item?.DATE ? 'Planeerimata' : formatDate(new Date(item.DATE))}</td>
                                </tr>
                                {
                                    item.ITEMS.map((row, index) =>
                                        <PlanTableRow
                                            key={`plan-row-${row.ID}-${item.DATE}`} 
                                            index={index}
                                            rowData={row}
                                            group={item.DATE}
                                            includeFields={includeFields}
                                            getStatusColor={getStatusColor}
                                            onRowClick={(rowData, e) => handleRowClick(rowData, e, index, itemsIndex)}
                                            onRowDoubleClick={onRowDoubleClick}
                                            getColValue={getColValue}
                                            onOrderChange={handleAssignDate}
                                            isActive={activeRows?.some(i => i.ID === row.ID)}
                                        />
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </tbody>
            </table>
            </div>
        </div>
    )
})

export default PlanTable