import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GetBlueprint, GetRelatedPlans } from '../graphql/queries'
import { useTranslation } from 'react-i18next'
import ChevronIcon from './icons/chevron'
import { PlanUpdated } from '../graphql/subscriptions'
import ActivityIndicator from './ActivityIndicator'
import { HaltReasons } from '../util/const'

const getStatusValue = (item) => {
    const haltReason = HaltReasons.find(r => r.value === item?.HALTED_REASON)

    if (item.HALTED) return `Seisak${haltReason ? ` (${haltReason.label}${item.HALTED_COMMENT ? ` - ${item.HALTED_COMMENT}` : ''})` : ''}`
    if (item.COMPLETED) return `Valmis`
    if (item.STARTED) return `Töös`
    if (item.CONFIGURATION_STARTED) return `Seadistamine`
    return 'Pole alustatud'
}

const PlanningStatus = ({
    planId,
    productionCode,
}) => {

    const { t } = useTranslation()
    const [blueprint, setBlueprint] = useState(null)
    const [orderedPlans, setOrderedPlans] = useState(null)

    const { refetch, subscribeToMore, loading } = useQuery(GetRelatedPlans, {
        skip: !planId,
        variables: {
            planId: parseInt(planId, 10),
        },
        onCompleted: (data) => {
            const { getRelatedPlans } = data
            setOrderedPlans(getRelatedPlans)
        },
    })

    const { refetch: fetchBlueprint } = useQuery(GetBlueprint, {
        skip: !productionCode || !orderedPlans?.length,
        variables: {
            productionCode: productionCode,
        },
        onCompleted: (data) => {
            const { getBlueprint } = data
            setBlueprint(getBlueprint)
        },
    })

    useEffect(() => {
        const unsub = subscribeToMore({
            document: PlanUpdated,
            variables: {
                id: parseInt(planId, 10),
            },
            updateQuery: async (prev, { subscriptionData }) => {
                refetch()
                fetchBlueprint()

                return prev
            },
        })

        return () => {
            unsub()
            setBlueprint(null)
            setOrderedPlans(null)
        }
    }, [])

    useEffect(() => {
        if (!blueprint || !orderedPlans?.length) return

        setOrderedPlans([...orderedPlans].sort((a, b) => blueprint[a.machine?.CODE] - blueprint[b.machine?.CODE]))
    }, [blueprint])

    return (
        <div className='planning-status'>
            {
                loading ?
                <ActivityIndicator />
                :
                <></>
            }
            {
                orderedPlans?.map(i =>
                    <div key={i.ID} className='planning-status-item'>
                        <div className='planning-status-item--inner'>
                            <div className='planning-status-item-label'>
                                { i.machine?.LABEL }
                            </div>
                            <div className='planning-status-item-type'>
                                { t(i.machine?.CODE) }
                            </div>
                            <div className='planning-status-item-value'>
                                { getStatusValue(i )}
                            </div>
                            {
                                i.COMPLETED ?
                                <div className='planning-status-item-yield'>
                                    { `S: ${i.PRODUCT_IN || '0'}, V: ${i.PRODUCT_OUT || '0'}, P: ${i.PRODUCT_WASTE || '0'}` }
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <ChevronIcon />
                    </div>    
                )
            }
        </div>
    )
}

export default PlanningStatus