import React from 'react'
import Button from './Button'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hasError: false }
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true }
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo)
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div className='error-view'>
            <div className='error-view--inner'>
              <h3>Midagi läks valesti.</h3>
              <div>
                <Button
                  label={'Proovi uuesti'}
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </div>
        )
      }
  
      return this.props.children
    }
}

export default ErrorBoundary