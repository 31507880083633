import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetBasicReport } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import DateInput from '../../components/DateInput'
import { parseDate } from '../../util/format'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'

const periods = [
    {
        label: 'Päev',
        value: 'day',
    },
    {
        label: 'Nädal',
        value: 'week',
    },
    {
        label: 'Kuu',
        value: 'month',
    },
    {
        label: 'Kvartal',
        value: 'quarter',
    },
    {
        label: 'Aasta',
        value: 'year',
    },
]

const initialResult = {
    labels: [],
    data: [],
}

const ReportBasicScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [period, setPeriod] = useState('week')
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)

    const { refetch } = useQuery(GetBasicReport, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start,
            end,
            period,
            shipped,
        },
        onCompleted: (data) => {
            if (data?.getBasicReport) {
                setResult({
                    labels: data.getBasicReport.map(x => x.label),
                    data: data.getBasicReport.map(x => x.value),
                })
            }
        },
    })

    useEffect(() => {
        (async () => {
            await refetch()
        })()
    }, [])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        period,
        start,
        end,
        shipped,
    ])

    return (
        <div className='report-basic'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Realisatsioon') }</h2>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <SelectInput
                        label={t('Periood')}
                        options={periods}
                        value={periods.find(x => x.value === period)}
                        onChange={(option) => setPeriod(option.value)}
                    />
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value) {
                                        return value ? Number(parseFloat(value).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                        plugins={[ChartDataLabels]}
                        data={{
                            labels: result.labels,
                            datasets: [
                                {
                                    id: 1,
                                    label: periods.find(x => x.value === period).label,
                                    data: result.data,
                                    backgroundColor: chartColors[0],
                                },
                            ],
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default ReportBasicScreen