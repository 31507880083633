import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const BoxWorkflow = ({
    items,
    onChange,
}) => {

    const { t } = useTranslation()

    return (
        <div className='box-fields--workflow'>
            {
                items && items.map((x, i) =>
                    <Input
                        type='number'
                        key={`workflow-${i}`}
                        value={x.value}
                        label={t(x.field)}
                        onChange={(e) => {
                            if (!onChange) return
                            onChange(x.field, parseInt(e.target.value))
                        }}
                    />
                )
            }
        </div>
    )
}

export default BoxWorkflow