import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'

import { useTranslation } from 'react-i18next'

const Machines = [
  {
    label: 'SLT',
    value: 'SLITTER',
  },
  {
    label: 'LMN',
    value: 'LAMINAATOR',
  },
  {
    label: 'SLO',
    value: 'SLOTTER',
  },
  {
    label: '1P.L.',
    value: 'ALIIM',
  },
  {
    label: 'KLM',
    value: 'KLIIM',
  },
  {
    label: '3P.L.',
    value: '_3LIIM',
  },
  {
    label: 'EKS',
    value: 'EKSTSEN',
  },
  {
    label: 'FPR',
    value: 'FPR',
  },
  {
    label: 'SRE',
    value: 'SRE',
  },
  {
    label: 'VER',
    value: 'VERONA',
  },
  {
    label: 'TIT',
    value: 'TITAN',
  },
  {
    label: 'INL',
    value: 'INLINE',
  },
  {
    label: 'AFD',
    value: 'AFD',
  },
  {
    label: 'TÖÖ',
    value: 'TOOTLEMINE',
  },
]

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 5,
    fontSize: 9,
  },
  header: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  headerTable: {
    display: 'table',
  },
  bottomTable: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 9,
    lineHeight: 1.2,
  },
  tableRow: {
    flexDirection: 'row',
    height: 30,
  },
  col: {
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderWidth: 2,
    borderColor: '#000',
  },
  colHeading: {
    fontSize: 6,
    fontWeight: 'bold',
  },
  colContent: {
    fontSize: 8,
  },
  image: {
    width: 40,
    height: 40,
    minWidth: 40,
    marginRight: 10,
  },
  strong: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  middleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  wrapper: {
    flex: 1,
    justifyContent: 'space-between',
  },
  technicalImage: {
    width: 770,
    height: 440,
    resizeMode: 'contain',
    objectFit: 'scale-down',
    objectPositionY: '50%',
    objectPositionX: '50%',
    marginTop: 10,
    marginRight: 10,
    marginLeft: 10,
  },
  machineList: {
    borderWidth: 2,
    borderColor: '#000',
    display: 'table',
    maxWidth: 50,
  },
  machineListRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  machineListHeading: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 7,
  },
  machineListText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  machineListCol1: {
    width: 60,
    flexDirection: 'row',
  },
  machineListCol1_1: {
    width: 50,
    paddingHorizontal: 2,
    paddingTop: 5,
    paddingBottom: 2,
  },
  machineListCol1_2: {
    borderLeftWidth: 1,
    borderColor: '#000',
    width: 36,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 2,
    paddingTop: 5,
    paddingBottom: 2,
  },
  machineListCol2: {
    width: 60,
    borderLeftWidth: 1,
    borderColor: '#000',
    paddingHorizontal: 2,
    paddingTop: 5,
    paddingBottom: 2,
  },
  machineListCol3: {
    width: 60,
    borderLeftWidth: 1,
    borderColor: '#000',
    paddingHorizontal: 2,
    paddingTop: 5,
    paddingBottom: 2,
  },
  machineListCol4: {
    width: 60,
    borderLeftWidth: 1,
    borderColor: '#000',
    paddingHorizontal: 2,
    paddingTop: 5,
    paddingBottom: 2,
  },
  directionImage: {
    width: 30,
    height: 30,
  },
  directionItem: {
    marginBottom: 10,
  },
  directionText: {
    fontSize: 10,
    marginTop: 4,
  },
  imageWrapper: {
    zIndex: 2,
    position: 'relative',
  },
  up: {
    transform: 'rotate(0deg)',
  },
  right: {
    transform: 'rotate(90deg)',
  },
  down: {
    transform: 'rotate(180deg)',
  },
  left: {
    transform: 'rotate(270deg)',
  },
})

const TechnicalCardDocument = ({
  data,
}) => {

  const { t } = useTranslation()

  const displayWeight = () => {
    if (!data?.blueprint?.TT || !data?.blueprint?.PCS_PER_HIT || !data?.material?.DENSITY) return

    try {
      const ttValues = data?.blueprint?.TT.split('x')

      if (ttValues.length < 2) return ''

      const weight = ((parseInt(ttValues[0], 10) * parseInt(ttValues[1], 10)) / parseInt(data.blueprint.PCS_PER_HIT) * data.material.DENSITY) / 1000

      return `${weight.toFixed(2)} kg`
    } catch (error) {
      return ''
    }
  }

  if (!data) return (
    <Document>
      <Text>Andmed on puudu</Text>
    </Document>
  )

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'landscape'}>
        <View
          style={styles.header}
        >
          <View style={styles.tableRow}>
            <View style={{ ...styles.col, width: 310, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>RIDA NUMBER</Text>
              <Text style={styles.colContent}>{data?.production?.ORDERNO || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 200, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>TOOTEKOOD</Text>
              <Text style={styles.colContent}>{data?.production?.PRODUCTION_CODE || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 200, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>KLIENDI TELLIMUS</Text>
              <Text style={styles.colContent}>{data?.production?.KLIENT_ORDER || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 130, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>TELLITUD KOGUS</Text>
              <Text style={styles.colContent}>{data?.production?.KOGUS || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 120, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>MÜÜGIESINDAJA</Text>
              <Text style={styles.colContent}>{data?.production?.client?.manager?.UNAME || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 150, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>Stantsi konstruktor</Text>
              <Text style={styles.colContent}>{data?.blueprint?.constructorUser?.RNAME || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 130, borderRightWidth: 0, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>TRANSPORT</Text>
              <Text style={styles.colContent}>{data?.production?.TRANSPORDIFIRMA || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 310, height: styles.tableRow.height * 2, borderBottomWidth: 0 }}>
              <Text style={styles.colHeading}>MÄRKUSED</Text>
              <Text style={styles.colContent}>{data?.blueprint?.COMMENT || ''}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.col, width: 310, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>KLIENT</Text>
              <Text style={{...styles.colContent, fontSize: 10, fontFamily: 'Roboto', fontWeight: 700 }}>{data?.production?.client?.NAME || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 200, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>TOOTE NIMETUS</Text>
              <Text style={styles.colContent}>{''}</Text>
            </View>
            <View style={{ ...styles.col, width: 200, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>KLIENDI TOOTEKOOD</Text>
              <Text style={styles.colContent}>{data?.production?.KLIENT_BOXCODE || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 130, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>TARNE KUUPÄEV</Text>
              <Text style={styles.colContent}>{data?.production?.NEW_DEADLINE ? formatDate(new Date(data?.production?.NEW_DEADLINE)) : formatDate(new Date(data.production.DEADLINE))}</Text>
            </View>
            <View style={{ ...styles.col, width: 120, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>KOOSTAJA</Text>
              <Text style={styles.colContent}>{'MARINA'}</Text>
            </View>
            <View style={{ ...styles.col, width: 150, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>Klishee disainer</Text>
              <Text style={styles.colContent}>{data?.blueprint?.designer?.RNAME || ''}</Text>
            </View>
            <View style={{ ...styles.col, width: 130, borderRightWidth: 0 }}>
              <Text style={styles.colHeading}>ESMATELLIMUS</Text>
            </View>
            <View style={{ ...styles.col, width: 310, borderTopWidth: 0 }}>
            </View>
          </View>
        </View>

        <View style={styles.wrapper}>
          <View style={styles.middleContainer}>
            <View>
            <View
              style={{
                zIndex: 4,
              }}
            >
              <View
                style={{
                  paddingTop: 10,
                }}
              >
                <View style={styles.directionItem}>
                  <Image
                    source={require('../assets/flute.png')}
                    style={{
                      ...styles.directionImage,
                      ...styles[data?.blueprint?.FLUTE],
                    }}
                  />
                  <Text style={styles.directionText}>{ 'Flute' }</Text>
                </View>
                <View style={styles.directionItem}>
                  <Image
                    source={require('../assets/printing.png')}
                    style={{
                      ...styles.directionImage,
                      ...styles[data?.blueprint?.PRINTING],
                    }}
                  />
                  <Text style={styles.directionText}>{ 'Printing' }</Text>
                </View>
                <View style={styles.directionItem}>
                  <Image
                    source={require('../assets/feeding.png')}
                    style={{
                      ...styles.directionImage,
                      ...styles[data?.blueprint?.FEEDING],
                    }}
                  />
                  <Text style={styles.directionText}>{ 'Feeding' }</Text>
                </View>
              </View>
            </View>
            <View style={{
              ...styles.machineList,
              alignSelf: 'center',
            }}>
              {
                Machines.map((x, i) =>
                  <View key={`machine-${i}`} style={[styles.machineListRow, {
                    borderBottomWidth: i < Machines.length - 1 ? 1 : 0
                  }]}>
                    <View style={styles.machineListCol1}>
                      <View style={styles.machineListCol1_1}>
                        <Text style={styles.machineListText}>{x.label}</Text>
                      </View>
                      <View style={styles.machineListCol1_2}>
                        <Text style={styles.machineListText}>{data?.blueprint ? `${data.blueprint[x.value] || ''}` : ''}</Text>
                      </View>
                    </View>
                  </View>
                )
              }
            </View>
            </View>

            <View style={styles.imageWrapper}>
              {
                data?.image ?
                  <Image
                    style={styles.technicalImage}
                    resizeMode={'contain'}
                    src={data?.image}
                  />
                  :
                  <></>
              }
            </View>
          </View>

          <View style={styles.bottomTable}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.col, width: 196.35, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>{ `TOOTE MÕÕDUD (LxBxH)` }</Text>
                <Text style={{...styles.colContent, fontSize: 13, fontFamily: 'Roboto', fontWeight: 700, textAlign: 'center'}}>{ `${data?.production?.MOOT}` }</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>MATRIITSI NR</Text>
                <Text style={styles.colContent}>{data?.blueprint?.MATRIITS || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>MATRIITSI ASUKOHT</Text>
                <Text style={styles.colContent}>{data?.blueprint?.MATRIITS_LOCATION || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>KLISHEE NR</Text>
                <Text style={styles.colContent}>{data?.blueprint?.KLISHEE || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>KLISHEE ASUKOHT</Text>
                <Text style={styles.colContent}>{data?.blueprint?.KLISHEE_LOCATION || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>LP-MARK</Text>
                <Text style={styles.colContent}>{data?.production?.MATERIAL || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>FSC KOOD</Text>
                <Text style={styles.colContent}>{data?.production?.FSC || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>ALUSE TÜÜP</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{data?.blueprint?.PALLET_TYPE || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0, borderBottomWidth: 0 }}>
                <Text style={styles.colHeading}>PAIGUTUS ALUSEL</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{data?.blueprint?.PALLET_LAYOUT || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 110, paddingHorizontal: 0, borderBottomWidth: 0 }}>
                <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: '#000', paddingBottom: 3, paddingHorizontal: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={styles.colHeading}>TOORIKU MÕÕT</Text>
                  <Text style={{...styles.colContent, marginLeft: 10 }}>{`${data?.blueprint?.STOCK_MOOT || ''}`}</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingTop: 3, paddingHorizontal: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={styles.colHeading}>TOORIKU KOGUS</Text>
                  <Text style={{...styles.colContent, marginLeft: 10 }}>{`${data?.blueprint?.STOCK_AMOUNT || ''}`}</Text>
                </View>
              </View>
            </View>
            {/* <View style={{ width: 830, height: 2, backgroundColor: 'red'}}></View> */}
            <View style={styles.tableRow}>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>COLOR 1</Text>
                <Text style={{...styles.colContent, fontSize: 11}}>{data?.blueprint?.PANTONE1 || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>COLOR 2</Text>
                <Text style={{...styles.colContent, fontSize: 11}}>{data?.blueprint?.PANTONE2 || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>COLOR 3</Text>
                <Text style={{...styles.colContent, fontSize: 11}}>{data?.blueprint?.PANTONE3 || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>COLOR 4</Text>
                <Text style={{...styles.colContent, fontSize: 11}}>{data?.blueprint?.PANTONE4 || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>T:T</Text>
                <Text style={styles.colContent}>{data?.blueprint?.TT || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>TK/LÖÖGIST</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{`${data?.blueprint?.PCS_PER_HIT || ''}`}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>TK/LEHEST</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{`${data?.blueprint?.PCS_PER_STOCK || ''}`}</Text>
              </View>
              <View style={{ ...styles.col, width: 130.9, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>NUUDID</Text>
                <Text style={styles.colContent}>{ data?.blueprint?.NUUT || data?.materialOrder?.NUUT || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>KOGUS ALUSEL</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{`${data?.blueprint?.AMOUNT_PALLET || ''}`}</Text>
              </View>
              <View style={{ ...styles.col, width: 65.45, borderRightWidth: 0 }}>
                <Text style={styles.colHeading}>PAKKEJUHIS</Text>
                <Text style={{...styles.colContent, fontSize: 11, textAlign: 'center'}}>{data?.blueprint?.PACKAGING_METHOD || ''}</Text>
              </View>
              <View style={{ ...styles.col, width: 110, paddingHorizontal: 0 }}>
                <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: '#000', paddingBottom: 3, paddingHorizontal: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={styles.colHeading}>OFFSET MÕÕT</Text>
                  <Text style={{...styles.colContent, marginLeft: 4, fontSize: 11, textAlign: 'center' }}>{ `${data?.blueprint?.OFFSET_MOOT || ''}` }</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingTop: 3, paddingHorizontal: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={styles.colHeading}>OFFSET KOGUS</Text>
                  <Text style={{...styles.colContent, marginLeft: 10 }}>{ `${data?.blueprint?.OFFSET_AMOUNT || ''}` }</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default TechnicalCardDocument  