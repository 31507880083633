import React from 'react'

const IconButton = ({onClick, label, disabled, className, icon}) => {

  return (
    <button
      className={`btn btn-icon${className ? ' ' + className : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className='btn-icon--icon'>{ icon }</div>
      {
        label ? <div className='btn-icon--label'>{ label }</div> : <></>
      }
    </button>
  )
}

export default IconButton