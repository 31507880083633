import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { useMutation, useQuery } from '@apollo/client'
import SelectInput from './SelectInput'
import Checkbox from './Checkbox'
import { ConstructorConfirm, UpdateOrderRow } from '../graphql/mutations'
import { Constants } from '../util/const'
import { calculateArea, getSpread, getMaterialPrice, getConsumption, getMaterialTotalPrice, calculatePrice, calculateDelta } from '../util/calc'
import BoxMaterialFields from './BoxMaterialFields'
import BoxWorkflow from './BoxWorkflow'
import { GetMaterials, GetOrderRow, GetOrderRowByName, GetUsers, GetMaterialsByGroupId } from '../graphql/queries'
import BoxColorFields from './BoxColorFields'
import BoxProjectWorkflow from './BoxProjectWorkflow'
import Button from './Button'
import FilesUpload from './FilesUpload'
import { useAuth } from '../providers/Auth'
import { formatDate } from '../util/format'
import { useNotification } from '../providers/Notification'
import debounce from 'lodash.debounce'
import { getWeight } from '../util/helpers'

const initialBoxState = {
    AMOUNT: '',
    CLASSTYPE: undefined,
    BOX: undefined,
    BOXNAME: '',
    PRINT: undefined,
    ROTATS: false,
    FACTORY: undefined,
    MATERIAL: undefined,
    MATERIAL_GROUP_ID: undefined,
    FPRICE: '',
    LPRICE: '',
    PRODUCTION_CODE: '',
    L: '',
    B: '',
    H: '',
    H_EXTRA: '',
    AL: '',
    AB: '',
    MAT_PRICE: '',
    DENSITY: '',
    PROFIT: '',
    TRANSPORT: '',
    PER_SHEET: '',
    KLISHEE: '',
    MATRIITS: '',
    COMMENT: '',
    COLOR1: '',
    COLOR2: '',
    COLOR3: '',
    COLOR4: '',
    PANTONE1: '',
    PANTONE2: '',
    PANTONE3: '',
    PANTONE4: '',
    SLITTER: '',
    FLEXO: '',
    TIGEL: '',
    SRE: '',
    INLINE: '',
    SLOTTER: '',
    KLIIM: '',
    ALIIM: '',
    _3LIIM: '',
    LAMINAATOR: '',
    TOOTLEMINE: '',
    EKSTSEN: '',
    AFD: '',
    FPR: '',
    PREMAID: '',
    TITAN: '',
    VERONA: '',
    MAT_TYPE: '',
    TT_L: '',
    TT_B: '',
    LP_L: '',
    LP_B: '',
}

const matTypes = [
    {
        value: 'B',
        label: 'B',
    },
    {
        value: 'C',
        label: 'C',
    },
    {
        value: 'E',
        label: 'E',
    },
    {
        value: 'BB',
        label: 'BB',
    },
    {
        value: 'BC',
        label: 'BC',
    },
    {
        value: 'BE',
        label: 'BE',
    },
]

const BoxFields = ({ orderRowId, clientId, boxes, classes, factories, materialGroups, initialMaterials, prints, onProductionClick }) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const { dispatch } = useNotification()
    const [loading, setLoading] = useState(false)
    const [box, setBox] = useState(initialBoxState)
    const [factoryMaterials, setFactoryMaterials] = useState([])
    const [currentTab, setCurrentTab] = useState('order')
    const [constructors, setConstructors] = useState([])
    const [materials, setMaterials] = useState(initialMaterials || [])
    const [rowMaterialGroup, setRowMaterialGroup] = useState()
    const [groupPrices, setGroupPrices] = useState([])
    const [selectedGroupPrice, setSelectedGroupPrice] = useState(null)
    const [useFsc, setUseFsc] = useState(false)

    const [updateOrderRow] = useMutation(UpdateOrderRow)
    const [constructorConfirm] = useMutation(ConstructorConfirm)

    useEffect(() => {
        if (!box?.MATERIAL) return

        if (!useFsc) {
            const updatedBox = {
                FSC: null,
                MATERIAL: box.MATERIAL.MARK,
                MAT_PRICE: box.MATERIAL.PRICE,
                DENSITY: box.MATERIAL.DENSITY,
            }

            setBox({
                ...box,
                ...updatedBox,
                MATERIAL: box.MATERIAL,
            })

            updateOrderRow({
                variables: {
                    id: parseInt(orderRowId),
                    data: updatedBox,
                },
            })
            return
        }

        const updatedBox = {
            MATERIAL: box.MATERIAL.MARK,
            MAT_PRICE: box.MATERIAL.PRICE,
            DENSITY: box.MATERIAL.DENSITY,
            FSC: box?.MATERIAL?.FSC || null,
        }

        setBox({
            ...box,
            ...updatedBox,
            MATERIAL: box.MATERIAL,
        })

        updateOrderRow({
            variables: {
                id: parseInt(orderRowId),
                data: updatedBox,
            },
        })
    }, [useFsc])

    useQuery(GetOrderRow, {
        fetchPolicy: 'no-cache',
        variables: {
            id: parseInt(orderRowId),
        },
        onCompleted: async (data) => {
            let boxItem = initialBoxState
            if (data?.getOrderRow) {
                const rowData = data.getOrderRow
                const factory = factories.find(x => x.value === rowData['FACTORY'])
                const material = materials.find(x => x.MARK === rowData['MATERIAL']) 
                const groupId = material?.MATERIAL_GROUP_ID
                const group = groupId ? materialGroups.find(x => x.value === groupId) : null
                const materialGroup = group ? {value: group?.value,label: group?.label} : null
                setUseFsc(rowData.FSC !== null)

                if (materials && factory) {
                    const materialItems = materials.reduce((acc, curr) => {
                        if (curr.FACTORY === factory.value) {
                            acc.push(curr)
                        }
                        return acc
                    }, [])
        
                    setFactoryMaterials(materialItems)
                }

                if (materialGroup) {
                    setRowMaterialGroup(materialGroup)

                    const { data: groupData } = await fetchMaterialsByGroupId({ id: group.value })
                    if (groupData?.getMaterialsByGroupId) {
                        
                        const groupsWithPrices = groupData.getMaterialsByGroupId.reduce((acc, curr) => {
                            if (!acc.some(x => x.value === curr.FACTORY && x.mark === curr.MARK)) {
                                acc.push({
                                    value: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`, 
                                    label: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`,
                                    mark: curr.MARK,
                                    price: curr.PRICE,
                                })
                            }
                            return acc
                        }, [])
                        setGroupPrices(groupsWithPrices)
                        const initialGroupPrice = material ? groupsWithPrices.find(price => price.mark === material.MARK) : null      
                        setSelectedGroupPrice(initialGroupPrice || null)
                }
            }   
                
                const classType = classes.find(x => x.ID === rowData['CLASSTYPE'])
                const print = prints.find(x => x.value === rowData['PRINT'])
                const boxType = boxes.find(x => x.NAME === rowData['BOX'])
                const matType = matTypes.find(x => x.value === rowData['MAT_TYPE'])
    
                boxItem = {
                    ...boxItem,
                    ...rowData,
                    FACTORY: factory,
                    MATERIAL: material,
                    CLASSTYPE: classType ? classType : null,
                    PRINT: print,
                    BOX: boxType,
                    MAT_TYPE: matType,
                }
            }
            
            setBox(boxItem)
        },
    })
    
    useQuery(GetUsers, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getUsers } = data
            if (getUsers?.length < 1) return
            setConstructors(getUsers.filter(x => x.USERCODE > 0))
        }
    })

    const { refetch: fetchMaterials } = useQuery(GetMaterials, {
        skip: true,
    })

    const { refetch: fetchMaterialsByGroupId } = useQuery(GetMaterialsByGroupId, {
        variables: {id: parseInt(rowMaterialGroup?.value)},
        skip:true,
    })
    
    const { refetch: getOrderRowByName } = useQuery(GetOrderRowByName, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        return () => {
            setBox(initialBoxState)
        }
    }, [])

    const setField = (field, value) => {
        const data = {
            ...box,
            [field]: value,
        }
        const newPrice = calculatePrice(data, materials)
        setBox({
            ...data,
            FPRICE: newPrice,
        })

        debouncedUpdate(field, value, newPrice)
    }

    const handleConstructorConfirm = async () => {
        setBox({
            ...box,
            CONSTR_DATE: new Date().toISOString(),
            CONSTR_NAME: user.name,
        })
        
        const res = await constructorConfirm({
            variables: {
                receiver: box.RECEIVER,
                id: parseInt(orderRowId),
            },
        })

        if (res?.data?.constructorConfirm === 'Success') {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'success',
                    content: t('Haldurile email saadetud')
                },
            })
        } else {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const updatePrice = (price) => {
        const realPrice = calculatePrice({
            ...box,
            PROFIT: 0,
        }, materials)

        const profit = (parseFloat(price) / parseFloat(realPrice) * 100) - 100
        const finalPrice = price && !isNaN(price) ? parseFloat(price) : 0

        setBox({
            ...box,
            FPRICE: price,
            PROFIT: profit.toFixed(2),
        })

        debouncedUpdate('PROFIT', profit, finalPrice)
    }

    const updateLabourPrice = (price) => {
        setBox({
            ...box,
            LPRICE: price ? price : 0,
        })

        debouncedUpdate('LPRICE', parseFloat(price))
    }

    const updateTransportPrice = (price) => {
        const updatedBox = {
            ...box,
            TRANSPORT: price ? price : 0,
        }

        const newPrice = calculatePrice(updatedBox, materials)
        updatedBox.FPRICE = parseFloat(newPrice)

        setBox(updatedBox)

        debouncedUpdate('TRANSPORT', parseFloat(price), parseFloat(newPrice))
    }

    const updateProperty = async (field, value, price) => {
        try {
            const updateItem = {
                ...box,
                [field]: value,
            }

            const spread = getSpread(updateItem)

            const updateData = {
                [field]: value,
                AL: spread.length,
                AB: spread.width,
            }

            if (!isNaN(price) && (price !== null || price !== undefined)) {
                updateData.FPRICE = parseFloat(price)
            }

            if (field === 'FACTORY') {
                updateData.MATERIAL = null
            }

            await updateOrderRow({
                variables: {
                    id: parseInt(orderRowId),
                    data: updateData
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const debouncedUpdate = useMemo( () => debounce(updateProperty, 300), [orderRowId, box])

    const changeFactory = async (factory) => {
        const updatedBox = {
            ...box,
            FACTORY: factory,
            MATERIAL: null,
        }

        const newPrice = calculatePrice(updatedBox, materials)
        updatedBox.FPRICE = parseFloat(newPrice)

        setBox(updatedBox)
        
        const factoryName = factory ? factory.value : ''

        if (materials && factory) {
            const materialItems = materials.reduce((acc, curr) => {
                if (curr.FACTORY === factory.value) {
                    acc.push(curr)
                }
                return acc
            }, [])

            setFactoryMaterials(materialItems)
        }

        debouncedUpdate('FACTORY', factoryName, parseFloat(newPrice))
    }

    const changeGroup = async (group) => {
        const updatedBox = {
            ...box,
            MATERIAL_GROUP_ID: group.value,
            MATERIAL: null, 
        }
    
        setBox(updatedBox)
    
        const groupId = group ? group.value : null
        setRowMaterialGroup({
            value: groupId,
            label: group.label,
        })
    
         const { data } = await fetchMaterialsByGroupId({ id: groupId })
         if (data?.getMaterialsByGroupId) {
            const groupsWithPrices = data.getMaterialsByGroupId.reduce((acc, curr) => {
                if (!acc.some(x => x.value === curr.FACTORY && x.mark === curr.MARK)) {
                    acc.push({
                        value: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`,  
                        label: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`,
                        mark: curr.MARK,
                        price: curr.PRICE,
                    })
                }
                return acc
            }, [])
            setGroupPrices(groupsWithPrices)
        }
    }

    const changeGroupPrice = async (groupPrice) => {
        setSelectedGroupPrice(groupPrice)
        const updatedFactory = {
            value: groupPrice.mark,
            label: groupPrice.value.split(' - ')[0]
        }

        const updatedMaterial = {
            MARK: groupPrice.mark,
            PRICE: groupPrice.price,
            GROUP_ID: groupPrice.groupId
        }
        
        const updatedBox = {
            ...box,
            FACTORY: updatedFactory,
            MATERIAL: updatedMaterial,
        }
        
        const newPrice = calculatePrice(updatedBox, materials)
        updatedBox.FPRICE = parseFloat(newPrice)
    
        setBox(updatedBox)
    
        const materialFields = {
            MATERIAL: updatedMaterial.MARK,
            MAT_PRICE: updatedMaterial.PRICE,
            DENSITY: updatedMaterial.DENSITY,
            FSC: useFsc ? updatedMaterial.FSC : null,
            FPRICE: updatedBox.FPRICE
        }
    
        const updatedMaterialBox = {
            ...updatedBox,
            ...materialFields,
            MATERIAL: updatedMaterial,
        }
    
        const newMaterialPrice = calculatePrice(updatedMaterialBox, materials)
        updatedMaterialBox.FPRICE = parseFloat(newMaterialPrice)
        materialFields.FPRICE = parseFloat(newMaterialPrice)
    
        setBox(updatedMaterialBox)

        if (materials) {
            const updatedFactoryMaterials = materials.filter(material =>
                material.FACTORY === updatedFactory.label
            )
            setFactoryMaterials(updatedFactoryMaterials)
        }
        const orderRowData = {
            FACTORY: updatedFactory.label,
            ...materialFields
        }
        try {
            await updateOrderRow({
                variables: {
                    id: parseInt(orderRowId),
                    data: orderRowData,
                },
            })
        } catch (error) {
            console.log("Error updating order row:", error)
        }
    }
    
    const changeMaterial = async (material) => {
        const materialFields = {
            MATERIAL: material.MARK,
            MAT_PRICE: material.PRICE,
            DENSITY: material.DENSITY,
            FSC: useFsc ? material.FSC : null,
        }

        const updatedBox = {
            ...box,
            ...materialFields,
            MATERIAL: material,
        }

        const newPrice = calculatePrice(updatedBox, materials)
        updatedBox.FPRICE = parseFloat(newPrice)
        materialFields.FPRICE = parseFloat(newPrice)

        const materialGroupId = material.MATERIAL_GROUP_ID || null
        if (materialGroupId) {
            const { data } = await fetchMaterialsByGroupId({ id: materialGroupId })
            if (data?.getMaterialsByGroupId) {
                const groupsWithPrices = data.getMaterialsByGroupId.reduce((acc, curr) => {
                    if (!acc.some(x => x.value === curr.FACTORY && x.mark === curr.MARK)) {
                        acc.push({
                            value: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`,
                            label: `${curr.FACTORY} - ${curr.MARK} - ${curr.PRICE} €/m2`,
                            mark: curr.MARK,
                            price: curr.PRICE,
                            name: curr.materialGroup.GROUP_NAME,
                            groupId: curr.materialGroup.ID,
                        })
                    }
                    return acc
                }, [])
                setGroupPrices(groupsWithPrices) 
                const matchingGroup = groupsWithPrices.find((price) => price.mark === material.MARK)
                if (matchingGroup) {
                    setSelectedGroupPrice(matchingGroup)
                    setRowMaterialGroup({
                        value: matchingGroup.groupId,
                        label: matchingGroup.name,
                    })   
                }
            } 
        }
        else {
            setSelectedGroupPrice(null)
            setRowMaterialGroup(null) 
        }
        setBox(updatedBox)
        await updateOrderRow({
            variables: {
                id: parseInt(orderRowId),
                data: materialFields,
            },
        })
    }

    const changeClassType = async (option) => {
        setBox({
            ...box,
            CLASSTYPE: option,
        })

        await debouncedUpdate('CLASSTYPE', option.ID)
    }

    const changeMatType = async (option) => {
        setBox({
            ...box,
            MAT_TYPE: option,
        })

        debouncedUpdate('MAT_TYPE', option.value)
    }

    const changePrint = async (option) => {
        setBox({
            ...box,
            PRINT: option,
        })

        debouncedUpdate('PRINT', option.value)
    }

    const changeBoxType = async (option) => {
        const updatedBox = {
            ...box,
            BOX: option,
        }

        const newPrice = calculatePrice(updatedBox, materials)
        updatedBox.FPRICE = parseFloat(newPrice)

        setBox(updatedBox)
        const spread = getSpread(option)

        await updateOrderRow({
            variables: {
                id: parseInt(orderRowId),
                data: {
                    AB: spread.width,
                    AL: spread.length,
                    BOX: option.NAME,
                    FPRICE: parseFloat(newPrice),
                }
            },
        })
    }

    const calculateLabourPercentage = () => {
        if (!getSpread(box).length || !getSpread(box).width) return 0
        const materialTotal = getMaterialTotalPrice(box, materials)
        const price = materialTotal + Constants.LabourPlaceholder
        const profit = price * parseFloat(box.PROFIT) * 0.01

        const total = ((profit + Constants.LabourPlaceholder) / calculatePrice(box, materials) * 100)
        return isNaN(total) || !isFinite(total) ? 0 : total.toFixed(4)
    }

    const calculateMaterialPercentage = () => {
        const total = parseFloat(getMaterialTotalPrice(box, materials) / calculatePrice(box, materials) * 100)

        return !isFinite(total) ? 0 : total.toFixed(4)
    }

    const handleSearchDescription = async () => {
        try {
            const { data } = await getOrderRowByName({
                input: box.BOXNAME,
                clientId: parseInt(clientId),
                rowId: parseInt(orderRowId),
            })
            const prod = data?.getOrderRowByName
            if (prod) {
                const boxOption = boxes.find(x => x.NAME === prod.BOX)
                const classType = classes.find(x => x.ID === prod.CLASSTYPE)
                const materialOption = materials.find(x => x.MARK === prod.MATERIAL)

                const fields = {
                    L: prod.L,
                    B: prod.B,
                    H: prod.H,
                    CLASSTYPE: prod.CLASSTYPE,
                    FPRICE: prod.FPRICE,
                    H_EXTRA: prod.H_EXTRA,
                    BOX: prod.BOX,
                    FACTORY: prod.FACTORY,
                    PRINT: prod.PRINT,
                    DENSITY: prod.DENSITY,
                    MATERIAL: prod.MATERIAL,
                    AL: prod.AL,
                    AB: prod.AB,
                }

                await updateOrderRow({
                    variables: {
                        id: parseInt(orderRowId),
                        data: fields,
                    },
                })

                const newBox = {
                    ...box,
                    ...fields,
                    CLASSTYPE: classType,
                    BOX: boxOption,
                    FACTORY: {
                        value: prod.FACTORY,
                        label: prod.FACTORY,
                    },
                    MATERIAL: materialOption,
                    PRINT: {
                        value: prod.PRINT,
                        label: prod.PRINT,
                    },
                }

                setBox(newBox)
            }
        } catch (err) {
            console.log('Error getting production by name', err)
        }
    }

    const renderMaterialOptions = ({ innerProps, innerRef, data, isFocused, isSelected }) => {
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={
                    `select-option${isFocused ? ' select-option--focused' : ''}${isSelected ? ' select-option--selected' : ''} select-option--material-option`
                }
            >
                <div className='flex align-items--center'>
                    <span>
                        { t(data.MARK) }
                    </span>
                    <span>
                        { `${data.PRICE.toFixed(3)} €/m2` }
                    </span>
                </div>
            </div>
        )
    }

    const renderGroupPriceOptions = ({ innerProps, innerRef, data, isFocused, isSelected }) => {
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={
                    `select-option${isFocused ? ' select-option--focused' : ''}${isSelected ? ' select-option--selected' : ''} select-option--material-option`
                }
            >
                <div className='select-option--flexbox'>
                    <span className="select-option--flexbox-left-value">
                        { data.value.split(' - ')[0] }
                    </span>
                    <span className="select-option--flexbox-middle-value">
                        { t(data.mark) }
                    </span>
                    <span className="select-option--flexbox-right-value">
                        { `${data.price.toFixed(3)} €/m2` }
                    </span>
                </div>
            </div>
        )
    }

    const handlePriceCalc = (boxData) => {
        const newPrice = calculatePrice(boxData || box, materials)
        setField('FPRICE', parseFloat(newPrice))
    }

    const displayLB = () => {
        if (box.LP_B || box.LP_L) {
            return (
                <span>{ `${box.LP_L ? box.LP_L : '0'} x ${box.LP_B ? box.LP_B : '0'} K` }</span>
            )
        }

        if (box.BOX && (box.BOX.NAME === 'Stants' || !box.BOX.FORMULA_L)) {
            return (
                <div className='box-fields--spread-input'>
                    <Input
                        type={'number'}
                        value={box.AL || '0'}
                        placeholder={t('Pikkus')}
                        onChange={(e) => setField('AL', parseInt(e.target.value))}
                    />
                    <span>{ ' x ' }</span>
                    <Input
                        type={'number'}
                        value={box.AB || '0'}
                        placeholder={t('Laius')}
                        onChange={(e) => setField('AB', parseInt(e.target.value))}
                    />
                </div>
            )
        }

        return (
            <span>{ `${getSpread(box).length} x ${getSpread(box).width}` }</span>
        )
    }

    const handleProductionClick = async () => {
        if (!box.CLASSTYPE) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Vajalikud väljad on täitmata')
                },
            })
            return
        }

        if (onProductionClick) onProductionClick()
    }

    const handleRefreshPrice = async () => {
        if (!box || !box.MATERIAL || !box.MATERIAL.PRICE) return

        const materialRes = await fetchMaterials()
        const newMaterials = materialRes.data?.getMaterials || []
        const updatedMaterial = newMaterials.find(x => x.MARK === box.MATERIAL.MARK && x.FACTORY === box.MATERIAL.FACTORY)

        if (!updatedMaterial) return

        const updatedBox = {
            ...box,
            MAT_PRICE: updatedMaterial.PRICE,
        }

        setBox(updatedBox)

        const newPrice = calculatePrice(updatedBox, newMaterials)

        await updateOrderRow({
            variables: {
                id: parseInt(orderRowId),
                data: {
                    MAT_PRICE: parseFloat(box.MATERIAL.PRICE),
                    FPRICE: parseFloat(newPrice),
                },
            },
        })
    }
    
    if (!orderRowId) return (
        <div className='empty-result'>
            <span>{ t('Karpi pole valitud') }</span>
        </div>
    )

    if (loading) return (
        <div className='empty-result'>
            <span>{ t('Laen karbi andmeid') }</span>
        </div>
    )

    return (
        <div className='box-fields--tabs'>
            <div className='box-fields--tabs-header'>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'order' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('order')}
                >
                    { t('Tellimus') }
                </div> 
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'material' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('material')}
                >
                    { t('Materjal') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'files' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('files')}
                >
                    { t('Failid') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'workflow' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('workflow')}
                >
                    { t('Töökäik') }
                </div>
                <div
                    className={`box-fields--tabs-header-item ${currentTab === 'project' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('project')}
                >
                    { t('Projekt') }
                </div>
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-content--order${currentTab === 'order' ? ' show' : ''}`}>
                <div className='box-fields box-fields--main'>
                    <div className='field-row'>
                        <div className='field-col'>
                            <div className='box-fields--row'>
                                <Input
                                    placeholder={t('Kogus')}
                                    label={`${t('Kogus')}:`}
                                    value={box.AMOUNT}
                                    type={'number'}
                                    onChange={(e) => setField('AMOUNT', parseInt(e.target.value))}
                                />
                                <SelectInput
                                    label={`${t('Klass')}:`}
                                    options={classes}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                    onChange={changeClassType}
                                    value={box.CLASSTYPE}
                                    error={box.CLASSTYPE ? null : true}
                                />
                            </div>
                            <SelectInput
                                label={`${t('Tüüp')}:`}
                                options={boxes}
                                onChange={changeBoxType}
                                getOptionLabel={(option) => option?.NAME}
                                getOptionValue={(option) => option?.NAME}
                                value={box.BOX?.NAME ? box.BOX : null}
                            />
                            <div className='box-fields--description'>
                                <Input
                                    placeholder={t('Kliendi tootekood')}
                                    label={`${t('Kliendi tootekood')}:`}
                                    value={box.BOXNAME}
                                    onChange={(e) => setField('BOXNAME', e.target.value)}
                                />
                                <Button
                                    label={t('Täida andmed')}
                                    onClick={handleSearchDescription}
                                />
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Trükk')}:`}
                                    options={prints}
                                    onChange={changePrint}
                                    value={box.PRINT}
                                />
                                <div className='box-fields--checkbox'>
                                    <Checkbox
                                        label={`${t('Rotatsioon')}`}
                                        value={box.ROTATS === 1}
                                        onChange={(e) => setField('ROTATS', e.target.checked ? 1 : 0)}
                                    />
                                </div>
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Materjali grupp')}:`}
                                    options={materialGroups}
                                    onChange={changeGroup}
                                    value={rowMaterialGroup}
                                />
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Materjali grupi materjalid')}:`}
                                    options={groupPrices}
                                    onChange={changeGroupPrice}
                                    value={selectedGroupPrice}
                                    components={{
                                        Option: renderGroupPriceOptions,
                                    }}
                                />
                            </div>
                            <div className='box-fields--row'>
                                <SelectInput
                                    label={`${t('Materjali tehas')}:`}
                                    options={factories}
                                    onChange={changeFactory}
                                    value={box.FACTORY ? box.FACTORY : null}
                                />
                            </div>
                            <div className='box-fields--material'>
                                <SelectInput
                                    label={`${t('Materjal')}:`}
                                    options={factoryMaterials}
                                    getOptionLabel={(option) => `${option.MARK}${option?.FSC && useFsc ? ` (${option.FSC})` : ''}`}
                                    getOptionValue={(option) => option.MARK}
                                    onChange={changeMaterial}
                                    value={box.MATERIAL ? box.MATERIAL : null}
                                    components={{
                                        Option: renderMaterialOptions,
                                    }}
                                />
                                <div className='box-fields--material-fsc'>
                                    <Checkbox
                                        label={t('FSC')}
                                        value={useFsc}
                                        onChange={(e) => setUseFsc(e.target.checked)}
                                        disabled={!box?.MATERIAL || !box.MATERIAL.FSC}
                                    />
                                </div>
                                <div className='box-fields--material-price'>
                                    <span>{ `${getMaterialPrice(box.MATERIAL?.MARK, box.FACTORY?.value, materials, box)} €/m2` }</span>
                                    <Button
                                        label={t('Uuenda hinda')}
                                        className={'btn-small'}
                                        onClick={handleRefreshPrice}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='field-col'>
                            <div className='box-fields--measurements'>
                                <span className='input-label'>{ `${t('Mõõdud (LxBxH)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        placeholder={t('Pikkus')}
                                        value={box.L}
                                        type={'number'}
                                        onChange={(e) => setField('L', parseInt(e.target.value))}
                                    />
                                    <Input
                                        placeholder={t('Laius')}
                                        value={box.B}
                                        type={'number'}
                                        onChange={(e) => setField('B', parseInt(e.target.value))}
                                    />
                                    <Input
                                        placeholder={t('Kõrgus')}
                                        value={box.H}
                                        type={'number'}
                                        onChange={(e) => setField('H', parseInt(e.target.value))}
                                    />
                                    <span className='divider'>/</span>
                                    <Input
                                        placeholder={t('')}
                                        value={box.H_EXTRA}
                                        onChange={(e) => setField('H_EXTRA', e.target.value)}
                                    />
                                </div>
                                <div className='box-fields--measurement box-fields--spread'>
                                    <span>{ `${t('Laotus (LxB)')}:` }</span>
                                    { box.L && box.B &&
                                        <span className='box-fields--spread-result'>
                                            {
                                                displayLB()
                                            }
                                            <span>{ `${box.PER_SHEET} ${t('tk./lehest')}` }</span>
                                        </span>
                                    }
                                </div>
                                <div className='box-fields--calculations'>
                                    <div className='box-fields--measurement box-fields--area'>
                                        <span>{ `${t('Pindala')}:` }</span>
                                        { box.L && box.B &&
                                            <span className='box-fields--area-result'>
                                                { `${calculateArea(getSpread(box).length, getSpread(box).width).toFixed(4) || '0'} m2` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--consumption'>
                                        <span>{ `${t('Kulunorm')}:` }</span>
                                        { box.L && box.B &&
                                            <span className='box-fields--consumption-result'>
                                                { `${getConsumption(box)} m2` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--material-total'>
                                        <span>{ `${t('Mat. maksumus')}:` }</span>
                                        { box.L && box.B && box.MATERIAL &&
                                            <span className='box-fields--material-total-result'>
                                                { `${getMaterialTotalPrice(box, materials)} EUR` }
                                            </span>
                                        }
                                    </div>
                                    <div className='box-fields--measurement box-fields--weight'>
                                        <span>{ `${t('Kaal')}:` }</span>
                                        { box.L && box.B && box.MATERIAL &&
                                            <span className='box-fields--weight-result'>
                                                { `${getWeight(box, materials)}g` }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='field-row field-row--calculations'>

                    <div className='box-fields--totals'>
                        <div className='box-price-fields'>
                            <div className='box-price-fields--items'>
                                <div className='box-price-fields--item'>
                                    <Button
                                        className={'calculate-price'}
                                        label={t('Arvuta hind')}
                                        onClick={handlePriceCalc}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Töö kulu')}:` }
                                        value={box.LPRICE}
                                        onChange={(e) => updateLabourPrice(e.target.value)}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Kasum')}:` }
                                        value={box.PROFIT}
                                        onChange={(e) => setField('PROFIT', parseFloat(e.target.value))}
                                        suffix={'%'}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Hind')}:` }
                                        value={box.FPRICE}
                                        onChange={(e) => updatePrice(e.target.value)}
                                        suffix={'EUR'}
                                    />
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Materjal')} / ${t('Hind')}:` }</span>
                                    <span>{ calculateMaterialPercentage() > 0 ? calculateMaterialPercentage() : 0 }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Töö + Kasum / Hind')}:` }</span>
                                    <span>{ calculateLabourPercentage() }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <span className='input-label'>{ `${t('Delta')}:` }</span>
                                    <span>{ calculateDelta(box, materials) }</span>
                                </div>
                                <div className='box-price-fields--item'>
                                    <Input
                                        type={'number'}
                                        label={ `${t('Transpordi hind')}:` }
                                        value={box.TRANSPORT}
                                        onChange={(e) => updateTransportPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                            <Button
                                label={t('Tootmisesse')}
                                onClick={handleProductionClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`box-fields--tabs-content${currentTab === 'material' ? ' show' : ''}`}>
                <BoxMaterialFields
                    color1={box.COLOR1}
                    color2={box.COLOR2}
                    color3={box.COLOR3}
                    color4={box.COLOR4}
                    pantone1={box.PANTONE1}
                    pantone2={box.PANTONE2}
                    pantone3={box.PANTONE3}
                    pantone4={box.PANTONE4}
                    matrix={box.MATRIITS}
                    perSheet={box.PER_SHEET}
                    printingPress={box.KLISHEE}
                    comment={box.COMMENT}
                    onColorChange={setField}
                    onMatrixChange={setField}
                    onPerSheetChange={setField}
                    onPrintingPressChange={setField}
                    onCommentChange={setField}
                />
            </div>
            <div className={`box-fields--tabs-content${currentTab === 'files' ? ' show' : ''}`}>
                <FilesUpload orderRowId={orderRowId} />
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-workflow ${currentTab === 'workflow' ? ' show' : ''}`}>
                <BoxWorkflow
                    onChange={setField}
                    items={[
                        {
                            field: 'SLITTER',
                            value: box.SLITTER,
                        },
                        {
                            field: 'FLEXO',
                            value: box.FLEXO,
                        },
                        {
                            field: 'TIGEL',
                            value: box.TIGEL,
                        },
                        {
                            field: 'SRE',
                            value: box.SRE,
                        },
                        {
                            field: 'INLINE',
                            value: box.INLINE,
                        },
                        {
                            field: 'SLOTTER',
                            value: box.SLOTTER,
                        },
                        {
                            field: 'KLIIM',
                            value: box.KLIIM,
                        },
                        {
                            field: 'ALIIM',
                            value: box.ALIIM,
                        },
                        {
                            field: '_3LIIM',
                            value: box._3LIIM,
                        },
                        {
                            field: 'LAMINAATOR',
                            value: box.LAMINAATOR,
                        },
                        {
                            field: 'TOOTLEMINE',
                            value: box.TOOTLEMINE,
                        },
                        {
                            field: 'EKSTSEN',
                            value: box.EKSTSEN,
                        },
                        {
                            field: 'AFD',
                            value: box.AFD,
                        },
                        {
                            field: 'FPR',
                            value: box.FPR,
                        },
                        {
                            field: 'PREMAID',
                            value: box.PREMAID,
                        },
                        {
                            field: 'TITAN',
                            value: box.TITAN,
                        },
                        {
                            field: 'VERONA',
                            value: box.VERONA,
                        },
                    ]}
                /> 
            </div>
            <div className={`box-fields--tabs-content box-fields--tabs-project${currentTab === 'project' ? ' show' : ''}`}>
                <div className='box-fields'>
                    <div className='box-fields--box'>
                        <div className='field-row'>
                            <div className='field-col'>
                                <SelectInput
                                    label={`${t('Klass')}:`}
                                    options={classes}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                    onChange={changeClassType}
                                    value={box.CLASSTYPE}
                                />
                                <Input
                                    placeholder={t('Kogus')}
                                    label={`${t('Kogus')}:`}
                                    value={box.AMOUNT}
                                    onChange={(e) => setField('AMOUNT', parseInt(e.target.value))}
                                    type={'number'}
                                />
                                <SelectInput
                                    label={`${t('Tüüp')}:`}
                                    options={boxes}
                                    onChange={changeBoxType}
                                    value={box.BOX}
                                    getOptionLabel={(option) => option.NAME}
                                    getOptionValue={(option) => option.NAME}
                                />
                                <SelectInput
                                    label={`${t('Trükk')}:`}
                                    options={prints}
                                    onChange={changePrint}
                                    value={box.PRINT}
                                />
                                <SelectInput
                                    label={`${t('Materjal')}:`}
                                    options={matTypes}
                                    onChange={changeMatType}
                                    value={box.MAT_TYPE}
                                />
                                <span className='input-label'>{ `${t('Mõõdud')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        placeholder={t('Pikkus')}
                                        value={box.L}
                                        onChange={(e) => setField('L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        placeholder={t('Laius')}
                                        value={box.B}
                                        onChange={(e) => setField('B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        placeholder={t('Kõrgus')}
                                        value={box.H}
                                        onChange={(e) => setField('H', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                                <span>{ `${t('Terast terani (LxB)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        value={box.TT_L}
                                        onChange={(e) => setField('TT_L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        value={box.TT_B}
                                        onChange={(e) => setField('TT_B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                                <span>{ `${t('LP toorik (LxB)')}:` }</span>
                                <div className='box-fields--measurements-fields'>
                                    <Input
                                        value={box.LP_L}
                                        onChange={(e) => setField('LP_L', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <Input
                                        value={box.LP_B}
                                        onChange={(e) => setField('LP_B', parseInt(e.target.value))}
                                        type={'number'}
                                    />
                                    <span className='divider'>/</span>
                                    <Input
                                        value={box.PER_SHEET}
                                        onChange={(e) => setField('PER_SHEET', parseFloat(e.target.value))}
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className='field-col'>
                                <BoxColorFields
                                    color1={box.COLOR1}
                                    color2={box.COLOR2}
                                    color3={box.COLOR3}
                                    color4={box.COLOR4}
                                    pantone1={box.PANTONE1}
                                    pantone2={box.PANTONE2}
                                    pantone3={box.PANTONE3}
                                    pantone4={box.PANTONE4}
                                    onColorChange={setField}
                                />
                            </div>
                            <div className='field-col'>
                                <BoxProjectWorkflow
                                    onFieldChange={setField}
                                    tigel={box.TIGEL}
                                    verona={box.VERONA}
                                    titan={box.TITAN}
                                    sre={box.SRE}
                                    inline={box.INLINE}
                                    slotter={box.SLOTTER}
                                    flexo={box.FLEXO}
                                />
                            </div>
                        </div>
                        <div className='field-row'>
                            <div className='field-col'>
                                <div className="box-fields--constructor">
                                    <div>
                                        <p>{ `${t('Konstruktor')}:` }</p>
                                    </div>
                                    <div>
                                        <p>{ box.CONSTR_NAME }</p>
                                        <p>{ formatDate(new Date(box.CONSTR_DATE)) }</p>
                                    </div>
                                </div>
                            </div>
                            <div className='field-col'>
                                <SelectInput
                                    label={`${t('Vali saaja')}:`}
                                    options={constructors}
                                    onChange={(option) => setField('RECEIVER', option.EMAIL)}
                                    value={constructors.find(x => x.EMAIL === box.RECEIVER)}
                                    getOptionLabel={(option) => option.RNAME}
                                    getOptionValue={(option) => option.EMAIL}
                                />
                                <Button
                                    label={t('Kinnita')}
                                    onClick={handleConstructorConfirm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxFields