import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'

const EmailLog = ({
    emails
}) => {

    const { t } = useTranslation()

    return (
        <div className='email-logs'>
            <h5>{ t('Emailid') }</h5>
            <table>
                <tbody>
                    {
                        emails.map((x) =>
                            <tr key={x.ID}>
                                <td>{ formatDate(new Date(x.KUUP), true) }</td>
                                <td>{ x.USER }</td>
                                <td>{ x.EMAIL }</td>
                                <td>{ x.TEXT }</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default EmailLog