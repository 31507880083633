import React, { useRef, useState, useEffect } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllMaterialGroups, GetMaterials} from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateMaterialGroup, RemoveMaterialGroup, UpdateMaterialGroup, UpdateMaterial } from '../../graphql/mutations'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import FilterTable from '../../components/FilterTable'
import SelectInput from '../../components/SelectInput'
import ConfirmModal from '../../components/ConfirmModal'

const includeFields = [
    {
        value: 'ID',
        label: 'Grupi ID',
    },
    {
        value: 'GROUP_NAME',
        label: 'Grupi nimi',
    },   
]

const MaterialGroupScreen = () => {
    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [showModalMaterial, setShowModalMaterial] = useState(false)
    const [newItem, setNewItem] = useState({})
    const [materials, setMaterials] = useState()
    const [selectedMaterial, setSelectedMaterial] = useState(null) 
    const [selectedFactory, setSelectedFactory] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modifiedMaterials, setModifiedMaterials] = useState([])
    const [removedMaterials, setRemovedMaterials] = useState([])
    const [groupName, setGroupName] = useState(null)

    const [updateItem] = useMutation(UpdateMaterialGroup)
    const [createItem] = useMutation(CreateMaterialGroup)
    const [removeItem] = useMutation(RemoveMaterialGroup)
    const [updateMaterial] = useMutation(UpdateMaterial)
   
    const addItem = async () => {
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })

            if (data.createMaterialGroup) {
                setShowModal(false)
                setNewItem({})
                tableRef.current?.refresh()
            }
        } catch (err) {
            console.log('Error creating material group', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    id: selectedRow.ID,
                },
            })
            setSelectedRow(null)
            if (data.removeMaterialGroup) 
                tableRef.current?.refresh()
        } catch (err) {
            console.log('Error deleting material group', err)
        }
    }

    const updateProperty = async (item, field, value) => {
        try {
            await updateItem({
                variables: {
                    id: item.ID,
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'GROUP_NAME':
                return <TableInput
                    initialValue={row[field]}
                    label={field}
                    onBlur={(field, value) => {
                        updateProperty(row, field, value)
                    }}
                />
            default:
                return <div className='groups-center'>
                            {row[field]}
                        </div> 
        }
    }

    const { refetch: fetchMaterials } = useQuery(GetMaterials, {
        skip: true,
        onCompleted: (data) => {
            if (data && data.getMaterials && data.getMaterials.length > 0) {
                setMaterials(data.getMaterials)
            }
        },
    })
    
    useEffect(() => {
        fetchMaterials()
    }, [])

    const handleMaterialChange = (selectedOption) => {
        setSelectedMaterial(selectedOption)  
        setSelectedFactory({FACTORY: selectedOption.FACTORY, MARK: selectedOption.MARK})
    }

    const handleFactoryChange = (selectedOption) => {
        setSelectedFactory(selectedOption)
        setSelectedMaterial(null) 
    }

    const filteredMaterials = materials?.filter(material => {
        return selectedFactory ? material.FACTORY === selectedFactory.FACTORY : true
    }).filter(material => {
        return material.MATERIAL_GROUP_ID !== selectedRow?.ID
    }) || []

    const distinctFactories = materials?.reduce((acc, curr) => {
        if (!acc.some(x => x.FACTORY === curr.FACTORY)) {
            acc.push(curr)
        }
        return acc
    }, [])

    const handleRowClick = async (row) => {
        setSelectedRow(row)
    }

    const handleRowDoubleClick = (row) => {
        setShowModalMaterial(true)
        setSelectedRow(row)
    }

    const addMaterialToGroup = (material) => {
        const materialExists = materials.some(m => m.MARK === material.MARK && m.MATERIAL_GROUP_ID === selectedRow.ID)

        if (!materialExists) {
            setMaterials((prev) => [{ ...material, MATERIAL_GROUP_ID: selectedRow.ID }, ...prev])
            setModifiedMaterials((prev) => [...prev, material])
        } else {
            console.log("Material already exists in group")
        }
    }

    const removeMaterialFromGroup = (material) => {
        setMaterials((prev) => prev.filter((m) => m.MARK !== material.MARK))
        setRemovedMaterials((prev) => {
            const updatedRemovedMaterials = [...prev, material]
            return updatedRemovedMaterials
        })
    }

    const saveChanges = async () => {
        try {
            await Promise.all(modifiedMaterials.map(async (material) => {
                const { MARK, FACTORY } = material
                await updateMaterial({
                    variables: {
                        factory: FACTORY,
                        mark: MARK,
                        data: {
                            MATERIAL_GROUP_ID: selectedRow.ID,
                        },
                    },
                })
            }))
            setModifiedMaterials([])

            await Promise.all(removedMaterials.map(async (material) => {
                const { MARK, FACTORY } = material
                await updateMaterial({
                    variables: {
                        factory: FACTORY,
                        mark: MARK,
                        data: {
                            MATERIAL_GROUP_ID: null,
                        },
                    },
                })
            }))
            setRemovedMaterials([])

            if (groupName) {
                updateProperty(selectedRow, 'GROUP_NAME', groupName)
                setGroupName(null)
            }
            fetchMaterials()
            tableRef.current?.refresh()
        } catch (err) {
            console.log('Error saving changes:', err)
        }
    }
    
    const MaterialTable = ({ materials, removeMaterialFromGroup }) => (
        <div className="scrollable-table">
            <table>
                <thead>
                    <tr>
                        <th>Materjal</th>
                        <th>Tehas</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {materials.map((material) => (
                        <tr key={material.MARK}>
                            <td>{material.MARK}</td>
                            <td>{material.FACTORY}</td>
                            <td>
                                <Button
                                    label={t('Eemalda')}
                                    onClick={() => removeMaterialFromGroup(material)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

    return (
        <div className='materials-screen'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Materjali grupid') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa Grupp'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta Grupp'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={() => setShowConfirmModal(true)}
                        />
                        <ConfirmModal
                            show={showConfirmModal}
                            close={() => {setShowConfirmModal(false)}}
                            title={`Kas tahate kustutada gruppi nimega ${selectedRow?.GROUP_NAME}?`}
                            confirmLabel={'Jah'}
                            cancelLabel={'Ei'}
                            onConfirm={async () => {
                                await deleteItem() 
                                setShowConfirmModal(false) 
                            }}
                        ></ConfirmModal>
                        <IconButton
                            label={'Lisa materjale'}
                            icon={<PlusIcon />}
                            disabled={!selectedRow}
                            onClick={() => setShowModalMaterial(true)}
                        />
                    </div>
                </div>
                <FilterTable
                    ref={tableRef}
                    query={GetAllMaterialGroups}
                    queryKey={'getAllMaterialGroups'}
                    fieldConditions={fieldConditions}
                    includeFields={includeFields}
                    initialOrderBy={'ID'}
                    onRowClick={handleRowClick}
                    onRowDoubleClick={handleRowDoubleClick}
                    activeRows={selectedRow ? [selectedRow] : []}
                    rowKey={'ID'}
                />
            </div>
            <Modal
                title={t('Uus materjali grupp')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus materjali grupp') }</h5>
                <Input
                    label={`${t('Grupi nimi')}*:`}
                    value={newItem.GROUP_NAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        GROUP_NAME: e.target.value,
                    })}
                />
                <Button
                    label={t('Lisa materjali grupp')}
                    onClick={addItem}
                />
            </Modal>
            <Modal
                title={t('Lisa materjale')}
                show={showModalMaterial}
                close={() => setShowModalMaterial(false)}
            >
                
                <Input
                    label={`${t('Grupi nimi')}:`}
                    value={selectedRow?.GROUP_NAME || ''}
                    onChange={(e) => {
                        setGroupName(e.target.value)
                        setSelectedRow({
                            ...selectedRow,
                            GROUP_NAME: e.target.value,
                    })
                    }}
                />
                <h5>{`${t('Lisa')} ${selectedRow?.GROUP_NAME  || ''} ${t('gruppi uus materjal')}:`}</h5>
               <SelectInput
                    label={`${t('Materjali tehas:')}`}
                    options={distinctFactories}
                    getOptionLabel={(option) => option.FACTORY}
                    getOptionValue={(option) => option.FACTORY}
                    onChange={handleFactoryChange}  
                    value={selectedFactory}
                />
                <SelectInput
                    label={`${t('Materjal')}:`}
                    options={filteredMaterials} 
                    getOptionLabel={(option) => option.MARK}
                    getOptionValue={(option) => option.MARK}
                    onChange={handleMaterialChange} 
                    value={selectedMaterial}
                />
                <Button className='add-material-button'
                        label={t('Lisa materjal gruppi')}
                        onClick={() => {
                            if (selectedMaterial) {
                                addMaterialToGroup(selectedMaterial)
                            }
                        }}
                />
                <MaterialTable 
                    materials={(materials || []).filter(material => material.MATERIAL_GROUP_ID === selectedRow?.ID)}
                    removeMaterialFromGroup={removeMaterialFromGroup} 
                />
                <Button
                    label={t('Salvesta')}
                    onClick={saveChanges}
                    disabled={loading || (modifiedMaterials.length === 0 && removedMaterials.length === 0 && groupName === null)}
                    loading={loading}
                />
            </Modal>
        </div>
    )
}

export default MaterialGroupScreen