import React from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import PlanningMachines from '../../components/PlanningMachines'

const PlanningScreen = () => {
    const { t } = useTranslation()

    return (
        <div className='planning'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Planeerimine')}</h2>
                </div>
                <PlanningMachines />
            </div>
        </div>
    )
}

export default PlanningScreen