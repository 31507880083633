import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllClients, SearchClientsAll } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateClient } from '../../graphql/mutations'
import Pagination from '../../components/Pagination'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Search from '../../components/Search'
import { formatDate } from '../../util/format'
import { useNavigate } from 'react-router-dom'

const ClientsScreen = () => {

    const { t } = useTranslation()
    const page = useRef(0)
    const limit = useRef(100)
    const navigate = useNavigate()
    const [tableHeaders, setTableHeaders] = useState([])
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState({})
    const [clientList, setClientList] = useState({
        count: 0,
        rows: [],
    })

    const { data, loading, refetch } = useQuery(GetAllClients, {
        variables: {
          page: page.current,
          limit: limit.current,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getAllClients } = data
            if (getAllClients?.rows?.length > 0) {
                let keys = []
                for (const key in getAllClients.rows[0]) {
                    if (['__typename', 'ID', 'MANAGER_ID', 'manager'].includes(key)) continue
                    keys.push(key)
                }
                setTableHeaders(keys)
                setClientList(getAllClients)
            }
        }
    })

    const { refetch: searchClients } = useQuery(SearchClientsAll, {
        skip: true,
    })
    
    const [createItem] = useMutation(CreateClient)

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }

    const addItem = async () => {
        if (!validateFields()) return
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createClient) {
                setShowModal(false)
                setNewItem({})
                refetch()
                openClient(data.createClient)
            }
        } catch (err) {
            console.log('Error creating client', err)
        }
    }

    const handleSearch = async (input) => {
        if (input.length <= 0) {
            const { data } = await refetch({
                page: page.current,
                limit: limit.current,
            })
            if (data && data.getAllClients) {
                setClientList(data.getAllClients)
            }
            return
        }
        if (input.length < 2) return
        const { data } = await searchClients({
            searchInput: input,
        })
        if (data && data.searchClients) {
            const item = {
                count: 0,
                rows: data.searchClients,
            }
            setClientList(item)
        }
    }

    const validateFields = () => {
        if (!newItem.NAME) return false
        return true
    }

    const getColValue = (row, field) => {
        if (field === 'MANAGER' && row.manager && row.manager.RNAME) return row.manager.RNAME
        if (field === 'CHANGE') return formatDate(new Date(row[field]))
        return row[field]
    }

    const openClient = (id) => navigate(`/client/${id}`)

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='factories'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Kliendid') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                    </div>
                </div>
                <Search
                    search={handleSearch}
                    placeholder={t('Otsi kliente')}
                />
                { tableHeaders && tableHeaders.length > 0 ?
                    <div className='table-wrapper'>
                        <table className='input-table'>
                            <thead>
                                <tr>
                                {
                                    tableHeaders.map((x, i) =>
                                        <th key={`table-header-col-${i}`} className='grid-list-item--col'>{ t(x) }</th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                clientList && clientList.rows && clientList.rows.map((item) =>
                                    <tr
                                        key={item.ID}
                                        onClick={() => openClient(item.ID)}
                                        className={`table-row${item.ID === selectedRow?.ID ? ' table-row--selected' : ''}`}
                                    >
                                        {
                                            tableHeaders.map((x) =>
                                                <td key={`${item.ID}-${x}`}>
                                                    { getColValue(item, x) }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Kliente ei leitud') }</p>
                    </div>
                }
                <Pagination total={clientList.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
            </div>
            <Modal
                title={t('Uus klient')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus klient') }</h5>
                <Input
                    label={`${t('Nimi')}:`}
                    value={newItem.NAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        NAME: e.target.value,
                    })}
                />
                <Button
                    label={t('Lisa klient')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default ClientsScreen