import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetInvoice, GetInvoices, SearchClients } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import Input from '../../components/Input'
import { parseDate } from '../../util/format'
import DateInput from '../../components/DateInput'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import Textarea from '../../components/Textarea'
import InvoiceRows from '../../components/InvoiceRows'
import { RemoveInvoice, UpdateInvoice } from '../../graphql/mutations'
import InvoiceExtraRows from '../../components/InvoiceExtraRows'

const initialInvoiceState = {
    CREATED_DATE: null,
    CLIENT_ID: null,
    PALLETS: null,
    PLACES: null,
    NETTO: null,
    GROSS: null,
    PAYMENT_DATE: null,
    CREATOR: null,
    ORDERNO: null,
    GOODS: null,
    COMMENT: null,
    COUNTRY: null,
    PREPAID_AMOUNT: null,
}

const InvoiceScreen = () => {

    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [invoice, setInvoice] = useState(initialInvoiceState)
    const [updateInvoice] = useMutation(UpdateInvoice)
    const [removeInvoice] = useMutation(RemoveInvoice)

    const { refetch: searchClients } = useQuery(SearchClients, {
        skip: true,
    })

    const { loading } = useQuery(GetInvoice, {
        variables: {
          id: parseInt(id)
        },
        skip: !id,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getInvoice } = data
            if (getInvoice) setInvoice(getInvoice)
        },
    })

    const setField = (field, value) => {
        setInvoice({
            ...invoice,
            [field]: value,
        })
        updateProperty(field, value)
    }

    const loadClients = async (input) => {
        if (input.length < 3) return
        const res = await searchClients({
            searchInput: input,
        })
        return res.data.searchClients
    }

    const handleClientChange = (client) => {
        if (!client || !client.ID) return

        setInvoice({
            ...invoice,
            client,
        })
        updateProperty('CLIENT_ID', client.ID)
    }

    const handleDeleteInvoice = async () => {
        try {
            await removeInvoice({
                variables: {
                    id: parseInt(id),
                },
            })
            navigate(-1)
        } catch (err) {
            console.log('handleDeleteInvoice:', err)
        }
    }

    const updateProperty = async (field, value) => {
        try {
            await updateInvoice({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='invoice'>
            <div className='inner'>
                <div className='breadcrumbs'>
                    <BackButton
                        label={t('Arved')}
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className='inner-title'>
                    <h2>{ t('Arve') }</h2>
                    <Button
                        label={t('Kustuta arve')}
                        onClick={handleDeleteInvoice}
                    />
                </div>

                <div className='invoice-fields'>
                    <div className='field-row'>
                        <div className='field-col'>
                            <Input
                                label={t('Number')}
                                type={'number'}
                                value={invoice.ORDERNO}
                                onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                            />
                            <DateInput
                                label={t('Kuupäev')}
                                value={invoice.CREATED_DATE ? new Date(invoice.CREATED_DATE) : null}
                                onChange={(val) => setField('CREATED_DATE', parseDate(val))}
                            />
                            <DateInput
                                label={t('Maksetähtaeg')}
                                value={invoice.PAYMENT_DATE ? new Date(invoice.PAYMENT_DATE) : null}
                                onChange={(val) => setField('PAYMENT_DATE', parseDate(val))}
                            />
                            <SelectInput
                                label={`${t('Kient')}`}
                                value={invoice.client}
                                load={loadClients}
                                getOptionLabel={(option) => option.NAME}
                                getOptionValue={(option) => option.ID}
                                loadingMessage={() => t('Loading clients')}
                                noOptionsMessage={() => t('Type to search')}
                                onChange={handleClientChange}
                                defaultValue={invoice.client}
                            />
                        </div>
                        <div className='field-col'>
                            <Input
                                label={t('Neto kaal')}
                                type={'number'}
                                value={invoice.NETTO}
                                onChange={(e) => setField('NETTO', parseInt(e.target.value))}
                            />
                            <Input
                                label={t('Bruto kaal')}
                                type={'number'}
                                value={invoice.GROSS}
                                onChange={(e) => setField('GROSS', parseInt(e.target.value))}
                            />
                            <Input
                                label={t('Riik')}
                                value={invoice.COUNTRY}
                                onChange={(e) => setField('COUNTRY', e.target.value)}
                            />
                            <Input
                                label={t('Ettemaks')}
                                type={'number'}
                                value={invoice.PREPAID_AMOUNT}
                                onChange={(e) => setField('PREPAID_AMOUNT', parseFloat(e.target.value))}
                            />
                            <Checkbox
                                label={t('Goods label in Invoice')}
                                value={invoice.GOODS === 1}
                                onChange={(e) => setField('GOODS', e.target.checked ? 1 : 0)}
                            />
                        </div>
                    </div>
                    <Textarea
                        label={t('Kommentaar')}
                        value={invoice.COMMENT}
                        onChange={e => setField('COMMENT', e.target.value)}
                    />
                    <div className='field-item creator'>
                        <span className='field-item--label'>{ t('Koostaja') }</span>
                        <span className='field-item--value'>{ invoice.author && invoice.author.RNAME }</span>
                    </div>
                </div>
                <InvoiceRows
                    invoiceId={id}
                />
                <InvoiceExtraRows
                    invoiceId={id}
                />
            </div>
        </div>
    )
}

export default InvoiceScreen