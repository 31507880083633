import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation } from '@apollo/client'
import { OrdersQuery } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { CreateOrder, DuplicateOrder, RemoveOrder } from '../../graphql/mutations'
import { formatDate } from '../../util/format'
import { useNotification } from '../../providers/Notification'
import ConfirmModal from '../../components/ConfirmModal'
import FilterTable from '../../components/FilterTable'
import { OrderCreated, OrdersUpdated } from '../../graphql/subscriptions'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Number',
    },
    {
        value: 'client.NAME',
        label: 'Klient',
    },
    {
        value: 'contact.CONTACT',
        label: 'Kontakt',
    },
    {
        value: 'ODATE',
        label: 'Kuupäev',
    },
    {
        value: 'creator.RNAME',
        label: 'Koostaja',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.ordersUpdated
    if (!newData) return prev

    const itemRows = prev.ordersQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })
    
    return Object.assign({}, prev, {
        ordersQuery: {
            ...prev.ordersQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.orderCreated
    if (!newData || prev.ordersQuery?.rows?.some(x => x.ID === newData.ID)) return prev
    
    return Object.assign({}, prev, {
        ordersQuery: {
            ...prev.ordersQuery,
            rows: [
                newData,
                ...prev.ordersQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: OrdersUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: OrderCreated,
        updateQuery: handleCreateSubscription,
    },
]

const OrdersScreen = () => {
    const [deleteModal, setDeleteModal] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const table = useRef()
    const [createOrder] = useMutation(CreateOrder)
    const [selectedRows, setSelectedRows] = useState([])
    const { dispatch } = useNotification()

    const [removeOrder] = useMutation(RemoveOrder)
    const [duplicateItem] = useMutation(DuplicateOrder)

    const openOrder = (id) => {
        navigate(`/order/${id}`)
    }

    const newOrder = async () => {
        try {
            const { data } = await createOrder()
            if (data && data.createOrder) {
                openOrder(data.createOrder)
            }
        } catch (err) {
            console.log('newOrder', err)
        }
    }

    const handleDelete = async () => {
        if (!selectedRows || selectedRows.length <= 0) return
        try {
            await Promise.all(selectedRows.map(async (item) => {
                await removeOrder({
                    variables: {
                        id: parseInt(item.ID),
                    },
                })
            }))
            setSelectedRows([])
            setDeleteModal(false)
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Valitud tellimused kustutatud'),
                    type: 'success',
                },
            })
            table.current?.refresh()
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const handleDuplicate = async () => {
        if (!selectedRows || selectedRows.length <= 0 || selectedRows.length > 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Kopeerida saab üks tellimus korraga'),
                    type: 'error',
                },
            })
            return
        }
        try {
            const { data } = await duplicateItem({
                variables: {
                    id: parseInt(selectedRows[0].ID)
                },
            })
            if (data && data.duplicateOrder !== 'Fail') {
                table.current?.refresh()
            } 
        } catch (err) {
            console.log('handleDuplicate', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ODATE':
                return formatDate(new Date(row.ODATE || null))
            case 'client.NAME':
                return row.client?.NAME
            case 'contact.CONTACT':
                return row.contact?.CONTACT
            case 'creator.RNAME':
                return row.creator?.RNAME
        }
    }

    const handleRowDoubleClick = (item) => {
        window.open(`/order/${item.ID}`, 'Hinnapakkumise andmed')
    }

    const handleRowClick = (item) => {
        if (selectedRows.some(x => x.ID === item.ID)) {
            setSelectedRows(selectedRows.filter(x => x.ID !== item.ID))
            return
        }
        setSelectedRows([
            ...selectedRows,
            item,
        ])
    }

    return (
        <div className='orders'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Hinnapakkumised') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Uus tellimus')}
                            onClick={newOrder}
                        />
                        <Button
                            disabled={!selectedRows || selectedRows.length <= 0}
                            label={t('Kopeeri tellimus')}
                            onClick={handleDuplicate}
                        />
                        <Button
                            disabled={!selectedRows || selectedRows.length <= 0}
                            label={t('Tühista valik')}
                            onClick={() => setSelectedRows([])}
                        />
                        <Button
                            disabled={!selectedRows || selectedRows.length <= 0}
                            label={t('Kustuta valitud')}
                            onClick={() => setDeleteModal(true)}
                        />
                        <Button
                            label={t('Otsing')}
                            onClick={() => navigate('/quotation-search')}
                        />
                    </div>
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        query={OrdersQuery}
                        queryKey={'ordersQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ORDERNO'}
                        onRowDoubleClick={handleRowDoubleClick}
                        onRowClick={handleRowClick}
                        activeRows={selectedRows}
                        ref={table}
                        subscriptions={subscriptions}
                    />
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('Kustuta')}
                close={() => setDeleteModal(false)}
                onConfirm={handleDelete}
                title={t('Kas soovid kustutada järgmised tellimused?')}
            >
                {
                    selectedRows.map((x, i) =>
                        <div key={`delete-${i}`}>{ x.ORDERNO }</div>    
                    )
                }
            </ConfirmModal>
        </div>
    )
}

export default OrdersScreen