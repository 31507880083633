import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetDeliveryNote, GetDeliveryNoteRows, ProductionsQuery } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateDeliveryNote, CreateDeliveryNoteRow, SendToBookkeeping } from '../../graphql/mutations'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import { useNotification } from '../../providers/Notification'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import { formatDate, removeTags } from '../../util/format'
import Filters from '../../components/Filters'
import FilterTable from '../../components/FilterTable'
import writeXlsxFile from 'write-excel-file'
import { getProductStatusClass } from '../../util/colors'
import { pdf } from '@react-pdf/renderer'
import { useAuth } from '../../providers/Auth'
import { ProductionCreated, ProductionsUpdated } from '../../graphql/subscriptions'
import PdfViewer from '../../components/PdfViewer'
import DeliveryNoteInvoiceDocument from '../../documents/deliverynote-invoice-document'
import DateInput from '../../components/DateInput'
import { useConstants } from '../../providers/Constants'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Rida number',
    },
    {
        value: 'ORDER_DATE',
        label: 'Tellimuse kuup.',
    },
    {
        value: 'PRODUCTION_CODE',
        label: 'Toote kood',
    },
    {
        value: 'NAME',
        label: 'Klient',
    },
    {
        value: 'KLIENT_ORDER',
        label: 'Kliendi tellimus',
    },
    {
        value: 'TELLIMUSNO',
        label: 'Tellimus',
    },
    {
        value: 'KLIENT_BOXCODE',
        label: 'Kliendi tootekood',
    },
    {
        value: 'MAT_ORDER_ID',
        label: 'Mat. tellimus',
    },
    {
        value: 'MOOT',
        label: 'Mõõt',
    },
    {
        value: 'MATERIAL',
        label: 'Materjal',
    },
    {
        value: 'MAT_LOADED',
        label: 'Mat. laaditud',
    },
    {
        value: 'OFSET',
        label: 'Offset',
    },
    {
        value: 'KOGUS',
        label: 'Kogus',
    },
    {
        value: 'FACTORY_NAME',
        label: 'Tehas',
    },
    {
        value: 'SIZE',
        label: 'LxB',
    },
    {
        value: 'MAT_ORDERS_CNT',
        label: 'Ridu',
    },
    {
        value: 'MAT_AMOUNT',
        label: 'LxB kogus',
    },
    {
        value: 'MAT_NUUT',
        label: 'Nuudid',
    },
    {
        value: 'MAT_DELIVERY',
        label: 'Mat. tarneaeg',
    },
    {
        value: 'ARRIVED_AMOUNT',
        label: 'Saabunud',
    },
    {
        value: 'DIFF_AMOUNT',
        label: 'Vahe',
    },
    {
        value: 'BOX',
        label: 'FEFCO',
    },
    {
        value: 'TRYKK',
        label: 'Trükk',
    },
    {
        value: 'DEADLINE',
        label: 'Tähtaeg',
    },
    {
        value: 'NEW_DEADLINE',
        label: 'Reaalne tähtaeg',
    },
    {
        value: 'START_DATE',
        label: 'Tootmise algus',
    },
    {
        value: 'FIN_DATE',
        label: 'Tootmise lõpp',
    },
    {
        value: 'ALUSEID',
        label: 'Aluseid',
    },
    {
        value: 'KOMMENTAAR',
        label: 'Kommentaar',
    },
    {
        value: 'ALUS_MOOT',
        label: 'Aluse mõõt',
    },
    {
        value: 'SLITTER',
        label: 'Slitter',
    },
    {
        value: 'FLEXO',
        label: 'Flexo',
    },
    {
        value: 'TIGEL',
        label: 'Tigel',
    },
    {
        value: 'VERONA',
        label: 'Verona',
    },
    {
        value: 'TITAN',
        label: 'Titan',
    },
    {
        value: 'SRE',
        label: 'SRE',
    },
    {
        value: 'INLINE',
        label: 'In-Line',
    },
    {
        value: 'SLOTTER',
        label: 'Slotter',
    },
    {
        value: 'KLIIM',
        label: 'K. liim',
    },
    {
        value: 'ALIIM',
        label: 'A. liim',
    },
    {
        value: '_3LIIM',
        label: '3p. liim',
    },
    {
        value: 'LAMINAATOR',
        label: 'Laminaator',
    },
    {
        value: 'PREMAID',
        label: 'Valmistoodang',
    },
    {
        value: 'STOCK_MAT',
        label: 'Laomaterjal',
    },
    {
        value: 'STOCK_MOOT',
        label: 'Mat. mõõt',
    },
    {
        value: 'ADDRESS',
        label: 'Tarneaadress',
    },
    {
        value: 'EXPORTED',
        label: 'Eksporditud',
    },
    {
        value: 'AFD',
        label: 'AFD',
    },
    {
        value: 'FPR',
        label: 'FPR',
    },
    {
        value: 'PALLET',
        label: 'PALLET',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.productionsUpdated
    if (!newData || !prev.productionsQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    const itemRows = prev.productionsQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })
    
    return Object.assign({}, prev, {
        productionsQuery: {
            ...prev.productionsQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.productionCreated
    if (!newData || prev.productionsQuery?.rows?.some(x => x.ID === newData.ID)) return prev
    
    return Object.assign({}, prev, {
        productionsQuery: {
            ...prev.productionsQuery,
            rows: [
                newData,
                ...prev.productionsQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: ProductionsUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: ProductionCreated,
        updateQuery: handleCreateSubscription,
    },
]

const ProductionsScreen = () => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const tableRef = useRef()
    const { user } = useAuth()
    const { constants } = useConstants()
    const [noteCreated, setNoteCreated] = useState(false)
    const [notePallets, setNotePallets] = useState(1)
    const [notePaidPallets, setNotePaidPallets] = useState(false)
    const [noteDate, setNoteDate] = useState(new Date())
    const [selectedRows, setSelectedRows] = useState([])
    const [extraFilters, setExtraFilters] = useState()
    const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false)
    const [showPdfModal, setShowPdfModal] = useState(false)

    const [createNote] = useMutation(CreateDeliveryNote)
    const [createNoteRow] = useMutation(CreateDeliveryNoteRow)
    const [sendToBookkeeping] = useMutation(SendToBookkeeping)

    const { refetch: getDeliveryNote } = useQuery(GetDeliveryNote, {
        fetchPolicy: 'no-cache',
    })

    const { refetch: getRows } = useQuery(GetDeliveryNoteRows, { skip: true })

    useEffect(() => {
        const currentDate = new Date()
        const filters = {
            year: `${currentDate.getFullYear()}`,
            month: `${currentDate.getMonth() + 1}`,
        }
        handleFilterChange(filters)
    }, [])

    const handleRowDoubleClick = (item) => {
        const rows = tableRef.current?.getTableRowsData()
        localStorage.setItem('productions', JSON.stringify(rows))
        window.open(`/production/${item.ID}`, 'Tootmise andmed')
    }

    const handleRowClick = (item) => {
        if (selectedRows.some(x => x.ID === item.ID)) {
            setSelectedRows(selectedRows.filter(x => x.ID !== item.ID))
            return
        }
        setSelectedRows([
            ...selectedRows,
            item,
        ])
    }

    const handleCreateDelivery = async () => {
        if (selectedRows.length < 1) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Ühtegi rida pole valitud'),
                    type: 'error',
                },
            })
            return
        }
        const client = selectedRows[0].KLIENT_ID
        let errors = false

        selectedRows.forEach(x => {
            if (!x.START_DATE) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: `${x.ORDERNO} ${t(`ei ole veel tootmises`)}`,
                    },
                })
                errors = true
            }
    
            if (!x.PARTIAL_AMOUNT) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: `${x.ORDERNO} ${t(`puudub väljastamiseks kogus`)}`,
                    },
                })
                errors = true
            }
        })

        if (errors) return

        try {
            const { data } = await createNote({
                variables: {
                    data: {
                        CLIENT_ID: client,
                        PALLETS: parseInt(notePallets),
                        PALLET_COSTS: notePaidPallets ? 1 : 0,
                    },
                },
            })

            if (data && data.createDeliveryNote) {
                await Promise.all(selectedRows.map(async (item) => {
                    await createNoteRow({
                        variables: {
                            data: {
                                INVOICE_ID: parseInt(data.createDeliveryNote),
                                PRODUCT_ID: parseInt(item.ID),
                                AMOUNT: parseInt(item.PARTIAL_AMOUNT),
                            },
                        },
                    })
                }))
                const { data: deliveryNote } = await getDeliveryNote({
                    id: parseInt(data.createDeliveryNote),
                })
                const { data: deliveryNoteRows } = await getRows({
                    noteId: parseInt(data.createDeliveryNote),
                })

                if (deliveryNote?.getDeliveryNote && deliveryNoteRows?.getDeliveryNoteRows) {
                    setNoteCreated({
                        ...deliveryNote.getDeliveryNote,
                        rows: deliveryNoteRows.getDeliveryNoteRows,
                        PALLETPRICE: constants?.PALLETPRICE_DOM,
                    })

                    dispatch({
                        type: 'ADD',
                        payload: {
                            content: t('Saateleht loodud'),
                            type: 'success',
                        },
                    })

                    return
                }
            }
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Viga saatelehe loomisel'),
                    type: 'error',
                },
            })
        } catch (err) {
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Viga saatelehe loomisel'),
                    type: 'error',
                },
            })
            console.log('createInvoice', err)
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters({
            ...filters,
            ...(filters.exported === 'true' && {
                exported: true,
            })
        })
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ORDER_DATE':
            case 'FIN_DATE':
            case 'START_DATE':
            case 'DEADLINE':
            case 'NEW_DEADLINE':
            case 'MAT_DELIVERY':
                return formatDate(new Date(row[field]))
            case 'materialOrderData.order':
                return row?.materialOrderData?.order
            case 'materialOrderData.factory':
                return row?.materialOrderData?.factory
            case 'materialOrderData.size':
                return row?.materialOrderData?.size
            case 'materialOrderData.count':
                return row?.materialOrderData?.count
            case 'materialOrderData.amount':
                return row?.materialOrderData?.amount
            case 'materialOrderData.nuut':
                return row?.materialOrderData?.nuut
            case 'materialOrderData.delivery':
                return formatDate(new Date(row?.materialOrderData?.delivery))
            case 'materialOrderData.arrived':
                return row?.materialOrderData?.arrived
            case 'materialOrderData.diff':
                return row?.materialOrderData?.diff
            case 'client.NAME':
                return row?.client?.NAME
        }
    }

    const handleBookkeeping = async () => {
        try {
            const pdfFile = pdf(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            pdfFile.updateContainer(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const { data } = await sendToBookkeeping({
                variables: {
                    id: parseInt(noteCreated.ID),
                    file,
                },
            })

            if (data?.sendToBookkeeping === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Edastatud raamatupidamisse'),
                        type: 'success',
                    },
                })
                return
            } 

            dispatch({
                type: 'ADD',
                payload: {
                    content: t(`Viga raamatupidamisse saatmisel: ${data?.sendToBookkeeping}`),
                    type: 'error',
                },
            })
        } catch (err) {
            console.log('handleBookkeeping:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    content: err,
                    type: 'error',
                },
            })
        }
    }

    const renderModalActions = () => {
        if (noteCreated) return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Ei')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Ainult prindi')}
                    onClick={async () => {
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
                <Button
                    label={t('Jah')}
                    onClick={async () => {
                        await handleBookkeeping()
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
            </div>
        )
        return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Katkesta')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Loo saateleht')}
                    onClick={handleCreateDelivery}
                />
            </div>
        )
    }

    const closeDeliveryNoteModal = () => {
        setShowDeliveryNoteModal(false)
    }

    const resetNoteState = () => {
        setNoteCreated(null)
        setNotePaidPallets(false)
        setNotePallets(1)
        setNoteDate(new Date())
    }

    const handleExcel = async () => {
        if (!tableRef.current) return
        const dataset = {
            columns: [],
            data: [],
        }
        Array.from(tableRef.current.getTableRows()).map((row, index) => {
            const items = []
            Array.from(row.cells).map((cell, cellIndex) => {
                if (index === 0) {
                    items.push({
                        value: removeTags(cell.innerHTML),
                        fontWeight: 'bold',
                    })
                } else {
                    switch (cellIndex) {
                        case 1:
                        case 18:
                        case 23:
                        case 24:
                        case 25:
                        case 26: {
                            const value = removeTags(cell.innerHTML).split('.')
                            const date = value?.length > 2 ? new Date(`${value[2]}-${value[1]}-${value[0]}T00:00:00.000Z`) : null
                            items.push({
                                value: date,
                                type: Date,
                                format: 'DD.MM.YYYY',
                            })
                            break
                        }
                        case 0:
                        case 5:
                        case 7:
                        case 10:
                        case 11:
                        case 12:
                        case 15:
                        case 16:
                        case 19:
                        case 20:
                        case 22:
                        case 27:
                        case 30:
                        case 31:
                        case 32:
                        case 33:
                        case 34:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 42:
                        case 43:
                        case 46:
                        case 47:
                        case 48: {
                            const value = removeTags(cell.innerHTML)
                            items.push({
                                value: value ? parseInt(value, 10) : null,
                                type: Number,
                            })
                            break
                        }
                        case 49: {
                            const value = removeTags(cell.innerHTML)
                            items.push({
                                value: value ? parseFloat(value) : null,
                                type: Number,
                            })
                            break
                        }
                        default: {
                            items.push({
                                value: removeTags(cell.innerHTML),
                                type: String,
                            })
                            break
                        }
                    }
                }
            })

            if (index === 0) {
                dataset.columns = items
            }
            else dataset.data.push(items)
        })
        
        await writeXlsxFile([
            dataset.columns,
            ...dataset.data,
        ], {
            fileName: `productions-${new Date().getTime()}.xlsx`,
            stickyRowsCount: 1
        })
        
    }

    return (
        <div className='productions'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Tootmine') }</h2>
                    <div className='inner-title--actions'>
                    <Button
                            label={t('Saateleht')}
                            onClick={() => {
                                resetNoteState()
                                setShowDeliveryNoteModal(true)
                                setNotePallets(selectedRows?.reduce((a, c) => a += parseInt(c.ALUSEID), 0))
                            }}
                        />
                        {
                            user?.permissions?.excel === 1 ?
                            <Button
                                label={t('Excelisse')}
                                onClick={handleExcel}
                            />
                            :
                            <></>
                        }
                        {
                            selectedRows && selectedRows.length > 0 &&
                            <Button
                                label={t('Tühista valik')}
                                onClick={() => setSelectedRows([])}
                            />
                        }
                    </div>
                </div>
                <Filters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        query={ProductionsQuery}
                        queryKey={'productionsQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ORDERNO'}
                        extraFilters={extraFilters}
                        statusColorEnabled={true}
                        onRowDoubleClick={handleRowDoubleClick}
                        onRowClick={handleRowClick}
                        activeRows={selectedRows}
                        ref={tableRef}
                        rowClass={getProductStatusClass}
                        useExtraFilters={true}
                        subscriptions={subscriptions}
                        onFiltersChanged={() => setSelectedRows([])}
                    />
                </div>
            </div>
            <Modal
                show={showDeliveryNoteModal}
                close={() => {
                    resetNoteState()
                    closeDeliveryNoteModal()
                }}
                renderActions={renderModalActions}
                className={'modal--create-deliverynote'}
            >
                {
                    !noteCreated ?
                    <div className='modal--create-deliverynote--content'>
                        <DateInput
                            label={t('Kuupäev')}
                            onChange={(val) => setNoteDate(new Date(val))}
                            value={noteDate}
                        />
                        <Input
                            label={t('Aluseid')}
                            value={notePallets}
                            onChange={(e) => setNotePallets(e.target.value)}
                            type={'number'}
                        />
                        <Checkbox
                            label={t('Tasulised alused')}
                            value={notePaidPallets}
                            onChange={(e) => setNotePaidPallets(e.target.checked)}
                        />
                    </div>
                    :
                    <p>
                        { t('Saateleht on tekitatud. Kas soovite seda välja trükkida ja raamatupidamisse saata?') }
                    </p>
                }
            </Modal>
            <Modal
                show={showPdfModal}
                className={'delivery-note-pdf-modal'}
                close={() => setShowPdfModal(false)}
            >
                {
                    noteCreated && noteCreated.ID &&
                    <div className='flex justify-content--space-between'>
                        <PdfViewer document={'delivery-note'} data={noteCreated} />
                        <PdfViewer document={'delivery-note-invoice'} data={noteCreated} />
                    </div>

                }
            </Modal>
        </div>
    )
}

export default ProductionsScreen