import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetUserRealisation } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'
import DateInput from '../../components/DateInput'
import { parseDate } from '../../util/format'

const periods = [
    {
        label: 'Päev',
        value: 'day',
    },
    {
        label: 'Nädal',
        value: 'week',
    },
    {
        label: 'Kuu',
        value: 'month',
    },
    {
        label: 'Kvartal',
        value: 'quarter',
    },
    {
        label: 'Aasta',
        value: 'year',
    },
]

const initialResult = {
    labels: [],
    datasets: [],
}

const ReportUserScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)
    const [period, setPeriod] = useState('week')

    const { refetch } = useQuery(GetUserRealisation, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start,
            end,
            shipped,
            period,
        },
        onCompleted: (data) => {
            if (data?.getUserRealisation) {
                const names = data.getUserRealisation.reduce((acc, curr) => {
                    if (acc.some(x => x === curr.name)) return acc
                    acc.push(curr.name)
                    return acc
                }, [])

                const periods = data.getUserRealisation.reduce((acc, curr) => {
                    if (acc.some(x => x === curr.period)) return acc
                    acc.push(curr.period)
                    return acc
                }, [])

                const dataset = periods.map(x => {
                    const names = data.getUserRealisation.filter(i => i.period === x)
                    const item = {}
                    names.map(i => {
                        item[i.name] = i.value
                    })
                    return {
                        period: x,
                        names: item,
                    }
                })

                setResult({
                    labels: periods,
                    datasets: names.map((x, index) => ({
                        label: x,
                        data: dataset,
                        parsing: {
                            xAxisKey: 'period',
                            yAxisKey: `names.${x}`,
                        },
                        backgroundColor: chartColors[index],
                    })),
                })
            }
        },
    })

    useEffect(() => {
        (async () => {
            await refetch()
        })()
    }, [])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        start,
        end,
        shipped,
        period,
    ])

    return (
        <div className='report-basic'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Realisatsioon eraldi') }</h2>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <SelectInput
                        label={t('Periood')}
                        options={periods}
                        value={periods.find(x => x.value === period)}
                        onChange={(option) => setPeriod(option.value)}
                    />
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        plugins={[ChartDataLabels]}
                        data={result}
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value, context) {
                                        const label = value.names[context.dataset.label]
                                        return label ? Number(parseFloat(label).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default ReportUserScreen