import React from 'react'
import './style.scss'
import { GetAllOrderRows } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import FilterTable from '../../components/FilterTable'
import { formatDate } from '../../util/format'
import { useNavigate } from 'react-router-dom'

const includeFields = [
    {
        value: 'order.client.NAME',
        label: 'Klient',
    },
    {
        value: 'order.ORDERNO',
        label: 'Nr',
    },
    {
        value: 'order.ODATE',
        label: 'Kuupäev',
    },
    {
        value: 'order.CREATOR',
        label: 'Koostaja',
    },
    {
        value: 'BOX',
        label: 'Karp',
    },
    {
        value: 'MATERIAL',
        label: 'Materjal',
    },
    {
        value: 'L',
        label: 'Pikkus',
    },
    {
        value: 'B',
        label: 'Laius',
    },
    {
        value: 'H',
        label: 'Kõrgus',
    },
    {
        value: 'H_EXTRA',
        label: 'Kõrgused',
    },
    {
        value: 'AMOUNT',
        label: 'Kogus',
    },
    {
        value: 'FPRICE',
        label: 'Hind',
    },
    {
        value: 'PRINT',
        label: 'Trükk',
    },
]

const QuotationSearchScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const fieldConditions = (row, field) => {
        if (field === 'order.client.NAME') {
            return row?.order?.client?.NAME
        }
        if (field === 'order.ODATE') {
            return row?.order?.ODATE ? formatDate(new Date(row.order.ODATE)) : ''
        }
        if (field === 'order.ORDERNO') {
            return row?.order?.ORDERNO
        }
        if (field === 'order.CREATOR') {
            return row?.order?.CREATOR
        }
    }

    const handleRowClick = (row) => {
        navigate(`/order/${row.ORDER_ID}`)
    }

    return (
        <div className='quotation-search'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Hinnapakkumiste otsing') }</h2>
                    <div className='inner-title--actions'>

                    </div>
                </div>
                <FilterTable
                    query={GetAllOrderRows}
                    queryKey={'getAllOrderRows'}
                    fieldConditions={fieldConditions}
                    includeFields={includeFields}
                    initialOrderBy={'order.ORDERNO'}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    )
}

export default QuotationSearchScreen