import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import Input from './Input'
import DateInput from './DateInput'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { useMutation } from '@apollo/client'
import { UpdateFactoryMaterialOrder } from '../graphql/mutations'
import MaterialDeliveryRows from './MaterialDeliveryRows'

const MaterialDeliveryModal = ({
    show,
    close,
    data,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [delivery, setDelivery] = useState({})

    const [updateRow] = useMutation(UpdateFactoryMaterialOrder)

    useEffect(() => {
        setDelivery(data)
    }, [data])

    const setFactoryOrderField = (field, value) => {
        setDelivery({
            ...delivery,
            factoryOrder: {
                ...delivery.factoryOrder,
                [field]: value,
            },
        })
    }

    const handleUpate = async () => {
        try {
            const res = await updateRow({
                variables: {
                    id: delivery.factoryOrder?.ID,
                    arrival: delivery.factoryOrder?.ARRIVAL,
                    completed: delivery.factoryOrder?.COMPLETED,
                },
            })

            if (res?.data?.updateFactoryMaterialOrder !== 'Success') return

            if (onSuccess) onSuccess()
        } catch (err) {
            console.log('Error updating factory delivery', err)
        }
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                <Button
                    label={t('Katkesta')}
                    onClick={close}
                    className={'btn-cancel'}
                />
                <Button
                    label={t('Uuenda')}
                    onClick={handleUpate}
                />
            </div>
        )
    }

    return (
        <Modal
            show={show}
            close={close}
            renderActions={renderModalActions}
            className={'modal-material-delivery'}
        >
            <div className='modal-material-delivery--content'>
                <Input
                    label={t('Tehas')}
                    value={delivery?.factory?.FACTORY_NAME}
                    disabled
                />
                <Input
                    label={t('Kogus')}
                    value={delivery?.AMOUNT}
                    type={'number'}
                    disabled
                />
                <DateInput
                    label={t('Tellimuse kuupäev')}
                    onChange={(val) => setFactoryOrderField('ARRIVAL', new Date(val))}
                    value={delivery?.factoryOrder?.ARRIVAL ? new Date(delivery.factoryOrder.ARRIVAL) : new Date()}
                />
                <DateInput
                    label={t('Materjali tarneaeg')}
                    value={delivery?.DELIVERY_DATE ? new Date(delivery.DELIVERY_DATE) : undefined}
                    disabled
                />
                <DateInput
                    label={t('Kinnitus')}
                    onChange={(val) => setFactoryOrderField('COMPLETED', new Date(val))}
                    value={delivery?.factoryOrder?.COMPLETED ? new Date(delivery.factoryOrder.COMPLETED) : new Date()}
                />
                <MaterialDeliveryRows
                    prodId={delivery?.production?.ID}
                    bufferId={delivery?.buffer?.ID}
                />
            </div>
        </Modal>
    )
}

export default MaterialDeliveryModal