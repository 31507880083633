import { useMutation } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RemoveMaterialOrderItem } from '../graphql/mutations'
import { formatDate } from '../util/format'
import Button from './Button'

const MaterialOrderItem = ({
    data,
    onClick,
    onUpdate,
}) => {

    const { t } = useTranslation()
    const [removeMaterialOrderItem] = useMutation(RemoveMaterialOrderItem)

    const getArea = () => {
        const measurements = data.SIZE?.split('x')
        if (!measurements || measurements.length < 2) return ''
        return (measurements[0] * measurements[1] / 1000000 * data.AMOUNT).toFixed(2)
    }

    const deleteRow = async (id) => {
        try {
            await removeMaterialOrderItem({
                variables: {
                    id,
                },
            })
            if (onUpdate) onUpdate()
        } catch (err) {
            console.log('Error deleting material order row', err)
        }
    }

    const handleRowClick = (e) => {
        if (e.target?.classList?.contains('btn')) return
        if (onClick) onClick()
    }

    return (
        <tr onClick={handleRowClick} className='material-order-item'>
            <td>{ data.ID }</td>
            <td>{ data.production?.ORDERNO || data.buffer?.ORDERNO }</td>
            <td>{ data.SIZE }</td>
            <td>{ data.SIZE_BOX }</td>
            <td>{ data.MARK }</td>
            <td>{ data.FSC }</td>
            <td>{ data.MARK_COMMENT }</td>
            <td>{ formatDate(new Date(data.production?.SHIPPED || data.buffer?.SHIPPED)) }</td>
            <td>{ formatDate(new Date(data.DELIVERY_DATE)) }</td>
            <td>{ data.PRICE }</td>
            <td>{ data.AMOUNT }</td>
            <td>{ getArea() }</td>
            <td>{ data.NUUT }</td>
            <td>{ data.REMARK }</td>
            <td>{ data.SPEED }</td>
            <td>{ data.production?.client?.NAME || data.buffer?.client?.NAME }</td>
            <td>{ formatDate(new Date(data.production?.DEADLINE || data.buffer?.DEADLINE)) }</td>
            <td>{ data.production?.BOX || data.buffer?.BOX }</td>
            <td>{ data.production?.MOOT || data.buffer?.MOOT }</td>
            <td>{ data.PRIORITY }</td>
            <td>
                <Button
                    label={t('Kustuta')}
                    onClick={() => deleteRow(data.ID)}
                />
            </td>
        </tr>
    )
}

export default MaterialOrderItem