import React, { useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import PlanningMachines from '../../components/PlanningMachines'
import { useQuery } from '@apollo/client'
import { GetLocations, GetMachines } from '../../graphql/queries'
import Button from '../../components/Button'
import OperatorMachine from '../../components/OperatorMachine'

const OperatorScreen = () => {

    const { t } = useTranslation()
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedMachine, setSelectedMachine] = useState(null)

    const { data: locationData } = useQuery(GetLocations, {
        variables: {
            page: 0,
            limit: 100,
        },
    })
    const { data: machineData } = useQuery(GetMachines, {
        variables: {
            page: 0,
            limit: 100,
        },
    })

    const handleBack = () => {
        if (selectedMachine) {
            setSelectedMachine(null)
            return
        }
        setSelectedLocation(null)
    }

    const renderLocations = () => {
        if (!locationData?.getLocations?.rows?.length) return (
            <div className='locations'>
                <h3>{t('Ühtegi asukohta ei leitud')}</h3>
            </div>
        )
        return (
            <div className='locations'>
                <h3>{t('Vali asukoht')}</h3>
                <div className='locations-list'>
                    {
                        locationData.getLocations.rows.map((location) =>
                            <Button
                                className='location'
                                key={location.ID}
                                label={location.LABEL}
                                onClick={() => setSelectedLocation(location)}
                            />
                        )
                    }
                </div>
            </div>
        )
    }

    const renderMachines = () => {
        if (!machineData?.getMachines?.rows?.length) return (
            <div className='machines'>
                <h3>{t('Ühtegi masinat ei leitud')}</h3>
            </div>
        )

        return (
            <div className='machines'>
                <h3>{t('Vali masin')}</h3>
                <div className='machines-list'>
                    {
                        machineData.getMachines.rows.filter(i => i.LOCATION_ID === selectedLocation.ID).map((machine) =>
                            <Button
                                className='machine'
                                key={machine.ID}
                                label={`${machine.LABEL} (${t(machine.CODE)})`}
                                onClick={() => setSelectedMachine(machine)}
                            />
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='operator'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Operaator')}</h2>
                    <Button
                        label={t('Tagasi')}
                        onClick={handleBack}
                        disabled={!selectedLocation}
                    />
                </div>
                {!selectedLocation ? renderLocations() : <></>}
                {selectedLocation && !selectedMachine ? renderMachines() : <></>}
                {
                    selectedLocation && selectedMachine ?
                        <OperatorMachine
                            machine={selectedMachine}
                        />
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default OperatorScreen