import React from 'react'
import SelectInput from './SelectInput'
import { useQuery } from '@apollo/client'
import { SearchClients } from '../graphql/queries'
import { useTranslation } from 'react-i18next'

const ClientSearchInput = ({
    value,
    onChange,
    defaultValue,
}) => {

    const { t } = useTranslation()

    const { refetch: searchClients } = useQuery(SearchClients, {
        variables: {
            searchInput: '',
        },
    })

    const loadClients = async (input) => {
        if (input.length < 3) return
        const res = await searchClients({
            searchInput: input,
        })
        return res.data.searchClients
    }

    return (
        <SelectInput
            label={`${t('Klient')}`}
            value={value}
            load={loadClients}
            getOptionLabel={(option) => option.NAME}
            getOptionValue={(option) => option.ID}
            loadingMessage={() => t('Laen kliente')}
            noOptionsMessage={() => t('Kirjuta, et otsida kliente')}
            onChange={onChange}
            defaultValue={defaultValue}
        />
    )
}

export default ClientSearchInput