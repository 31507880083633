import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 5,
    paddingTop: '70mm',
    flexDirection: 'column-reverse',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'stretch',
    borderColor: '#000',
  },
  col: {
    flex: 1,
    borderColor: '#000',
  },
  colLast: {
    flex: 1,
    borderRightWidth: 0,
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
  },
  value: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    marginTop: 6,
    marginBottom: 6,
    color: '#992825',
  },
  info: {
    fontSize: 10,
  },
  image: {
    width: 70,
    height: 50,
  },
  clientNameContainer: {
    width: 500,
    paddingHorizontal: 6,
  },
  clientNameText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    marginTop: 6,
    color: '#992825',
  },
})

const calculateFontSize = (width, text) => {
  const minFontSize = 32
  const maxFontSize = 64
  const percentageOfContainer = 0.1
  const A4_WIDTH = 595 // A4 width in points
  const paddingHorizontal = 20
  if (!width || !text) {
    return 32
  }
  const containerWidth = width  //- (paddingHorizontal)
  let fontSize = containerWidth * percentageOfContainer
  fontSize = Math.max(minFontSize, Math.min(fontSize, maxFontSize))
  while (text.length * fontSize > containerWidth) {
    fontSize -= 1
    if (fontSize < minFontSize)
      break
  }
  return fontSize
}

const PrintoutDocumentFi = ({
  data,
  printout,
  type,
  multiplier,
  palletamount,
  printempty
}) => {

  const { t } = useTranslation()

  const emptyPage = () => {
    const clientFontSize = calculateFontSize(595, data?.client?.NAME)
    const orderFontSize = calculateFontSize(200, data?.KLIENT_ORDER)
    const codeFontSize = calculateFontSize(200, data?.KLIENT_BOXCODE)

    return (
      <View style={[styles.wrapper,{flex:1,flexGrow: 1}]}>
        <View style={[
          styles.row,
          {
            height: 120,
            borderBottomWidth: 1,
            paddingHorizontal: 6,
            paddingVertical: 3,
            flexGrow: 1,
          },
        ]}>
          <View>
            <View>
              <Text style={styles.label}>{`Asiakas:`}</Text>
            </View>
            <View style={styles.clientNameContainer}>
              <Text style={{
                ...styles.clientNameText,
                fontSize: clientFontSize
              }}>
                {`${data?.client?.NAME}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[
            styles.col,
            {
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              paddingVertical: 3,
              paddingHorizontal: 6,
              flexGrow: 1,
            },
          ]}>
            <Text style={styles.label}>{`Toimitusosoite:`}</Text>
            <Text style={{ ...styles.value, fontSize: 16 }}>
              {`${data?.ADDRESS ? data.ADDRESS : '-'}`}
            </Text>
          </View>
        </View>
        <View style={[
          styles.row,
          {
            borderBottomWidth: 1,
            borderTopWidth: 1,
            flexDirection: 'row',
          },
        ]}>
          <View
            style={{
              borderRightWidth: 1,
              borderRightColor: '#000',
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              fontSize: orderFontSize,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`OstotIlaus: `}</Text>
            <Text style={styles.value}>{`${data?.KLIENT_ORDER}`}</Text>
          </View>
          <View
            style={{
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Tilauspäivämäärä: `}</Text>
            <Text style={styles.value}>{`${formatDate(new Date(data?.ORDER_DATE))}`}</Text>
          </View>
        </View>
        <View style={[
          styles.row,
          {
            borderBottomWidth: 1,
            flexDirection: 'row',
          },
        ]}>
          <View
            style={{
              borderRightWidth: 1,
              borderRightColor: '#000',
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Mittat:`}</Text>
            <Text style={[styles.value, { fontWeight: 'bold' }]}>{`${data?.MOOT || '-'}`}</Text>
          </View>
          <View
            style={{
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              fontSize: codeFontSize,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Tuote: `}</Text>
            <Text style={[styles.value, { fontWeight: 'bold' }]}>{`${data?.KLIENT_BOXCODE ? data.KLIENT_BOXCODE : '-'}`}</Text>
          </View>
        </View>
        <View style={[
          styles.row,
          {
            borderBottomWidth: 1,
            flexDirection: 'row',
            flexGrow: 1,
          },
        ]}>
          <View
            style={{
              borderRightWidth: 1,
              borderRightColor: '#000',
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Aaltopahvi:`}</Text>
            <Text style={styles.value}>{`${data.TYPE || '-'}`}</Text>
          </View>
          <View
            style={{
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Toimitusmäära:`}</Text>
            <Text style={styles.value}>{``}</Text>
          </View>
        </View>
        <View style={[
          styles.row,
          {
            borderBottomWidth: 1,
            flexDirection: 'row',
            flexGrow: 1,
          },
        ]}>
          <View
            style={{
              borderRightWidth: 1,
              borderRightColor: '#000',
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Lavan mumero:`}</Text>
            <Text style={styles.value}>{``}</Text>
          </View>
          <View
            style={{
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Toimitusaika: `}</Text>
            <Text style={styles.value}>{`${data?.DEADLINE ? formatDate(new Date(data?.DEADLINE)) : '-'}`}</Text>
          </View>
        </View>

        <View style={[
          styles.row,
          {
            flexDirection: 'row',
            flexGrow: 1,
          },
        ]}>
          <View
            style={{
              borderRightWidth: 1,
              borderRightColor: '#000',
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              minHeight: 60,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Määra lavalla:`}</Text>
            <Text style={styles.value}>{''}
            </Text>
          </View>
          <View
            style={{
              paddingHorizontal: 6,
              paddingVertical: 3,
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text style={styles.label}>{`Pakkaaja: `}</Text>
            <Text style={styles.value}>{``}</Text>
          </View>
        </View>
        {renderFooter()}
      </View>
    )
  }


  const renderFooter = () => {
    if (type === 'Pitsa') {
      return (
        <View
          style={[
            styles.row,
            {
              height: '35mm',
              borderTopWidth: 1,
            },
          ]}
        >
          <View
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%',
              paddingVertical: 3,
              paddingHorizontal: 6,
            }}
          >
            <View
              style={{
                width: '60%',
                flex: 1,
                marginRight: 20,
              }}
            >
              <View>
                <Text style={styles.title}>{t('OÜ Multipakend Tootmine')}</Text>
              </View>
              <View>
                <Text style={styles.info}>{`Tuuliku tee 4`}</Text>
                <Text style={styles.info}>{`10621 Tallinn`}</Text>
                <Text style={styles.info}>{`tel: +372 6803 730`}</Text>
                <Text style={styles.info}>{`e-mail: info@multipakend.ee`}</Text>
              </View>
            </View>
            {
              type === 'Pitsa' &&
              <View
                style={{
                  width: '20%',
                  marginRight: 20,
                }}
              >
                <Image
                  style={{
                    width: 70,
                    height: 70,
                  }}
                  source={require('../assets/food-safe.png')}
                />
              </View>
            }
            <View
              style={{
                width: '20%',
                marginRight: 20,
              }}
            >
              <Image
                style={styles.image}
                source={require('../assets/logo.png')}
              />
            </View>
          </View>
        </View>
      )
    }

    if (type === 'Multipakend') return (
      <View
        style={[
          {
            height: '35mm',
            borderTopWidth: 1,
            justifyContent: 'center',
          },
        ]}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              width: '80%',
            }}
            source={require('../assets/logo-long.png')}
          />
        </View>
        <View
          style={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '80%',
            marginHorizontal: 'auto',
            marginTop: 30,
            gap: 10,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text style={styles.info}>{`Tuuliku tee 4`}</Text>
            <Text style={styles.info}>{`10621 Tallinn`}</Text>
          </View>
          <View
            style={{
              flex: 1,
            }}
          >
            <Text style={styles.info}>{`tel: +372 6803 730`}</Text>
            <Text style={styles.info}>{`e-mail: info@multipakend.ee`}</Text>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Text style={styles.info}>www.multipakend.ee</Text>
          </View>
        </View>
      </View>
    )

    return (<></>)
  }

  const renderPages = () => {
    const pages = []

    const clientFontSize = calculateFontSize(595, data?.client?.NAME)
    const orderFontSize = calculateFontSize(200, data?.KLIENT_ORDER)
    const codeFontSize = calculateFontSize(200, data?.KLIENT_BOXCODE)

    let count = printout?.count ?? 1
    if (!printempty) {
      if (palletamount) {
        count = data.KOGUS / palletamount
      }
      for (let i = 0; i < Math.ceil(count) * multiplier; i++) {
        const palletNumber = Math.floor(i / multiplier) + 1
        const isFullPage = i < Math.floor(count) * multiplier
        const amountToShow = isFullPage && palletamount ? palletamount : (printout?.items ? `${printout.items} kpl` : '')
        pages.push(
          <Page key={`page-${i}`} size="A4" style={styles.page} orientation={'portrait'}>
            <View
              style={[styles.wrapper,{flex:1,flexGrow: 1}]}
            >
              <View style={[
                styles.row,
                {
                  height: 120,
                  borderBottomWidth: 1,
                  paddingHorizontal: 6,
                  paddingVertical: 3,
                  flexGrow: 1,
                },
              ]}>
                <View>
                  <View>
                    <Text style={[
                      styles.label,
                    ]}>
                      {`Asiakas:`}
                    </Text>
                  </View>
                  <View style={styles.clientNameContainer}>
                    <Text style={{
                      ...styles.clientNameText,
                      fontSize: clientFontSize,
                    }}
                    >
                      {`${data?.client?.NAME}`}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.row}>
                <View style={[
                  styles.col,
                  {
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingVertical: 3,
                    paddingHorizontal: 6,
                    flexGrow: 1,
                  },
                ]}>
                  <View>
                    <Text style={styles.label}>{`Toimitusosoite:`}</Text>
                  </View>
                  <View>
                    <Text style={{
                      ...styles.value,
                      fontSize: 16,
                    }}>{`${data?.ADDRESS ? data.ADDRESS : '-'}`}</Text>
                  </View>
                </View>
              </View>

              <View style={[
                styles.row,
                {
                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  flexDirection: 'row',
                },
              ]}>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: '#000',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    fontSize: orderFontSize,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`OstotIlaus: `}</Text>
                  <Text style={styles.value}>{`${data?.KLIENT_ORDER}`}</Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Tilauspäivämäärä: `}</Text>
                  <Text style={styles.value}>{`${formatDate(new Date(data?.ORDER_DATE))}`}</Text>
                </View>
              </View>

              <View style={[
                styles.row,
                {
                  borderBottomWidth: 1,
                  flexDirection: 'row',
                },
              ]}>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: '#000',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Mittat:`}</Text>
                  <Text style={[styles.value, { fontWeight: 'bold' }]}>{`${data?.MOOT || '-'}`}</Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    fontSize: codeFontSize,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Tuote: `}</Text>
                  <Text style={[styles.value, { fontWeight: 'bold' }]}>{`${data?.KLIENT_BOXCODE ? data.KLIENT_BOXCODE : '-'}`}</Text>
                </View>
              </View>

              <View style={[
                styles.row,
                {
                  borderBottomWidth: 1,
                  flexDirection: 'row',
                  flexGrow: 1,
                },
              ]}>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: '#000',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Aaltopahvi:`}</Text>
                  <Text style={styles.value}>{`${data.TYPE || '-'}`}</Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Toimitusmäära:`}</Text>
                  <Text style={styles.value}>{`${data?.KOGUS ? `${data.KOGUS} kpl` : '-'}`}</Text>
                </View>
              </View>

              <View style={[
                styles.row,
                {
                  borderBottomWidth: 1,
                  flexDirection: 'row',
                  flexGrow: 1,
                },
              ]}>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: '#000',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Lavan mumero:`}</Text>
                  <Text style={styles.value}>{palletamount && isFullPage ? `${palletNumber}` : (!printout ? `` : `${palletNumber}`)}</Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Toimitusaika: `}</Text>
                  <Text style={styles.value}>{`${data?.DEADLINE ? formatDate(new Date(data?.DEADLINE)) : '-'}`}</Text>
                </View>
              </View>

              <View style={[
                styles.row,
                {
                  flexDirection: 'row',
                  flexGrow: 1,
                },
              ]}>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: '#000',
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    minHeight: 60,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Määra lavalla:`}</Text>
                  <Text style={styles.value}>{amountToShow}
                  </Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <Text style={styles.label}>{`Pakkaaja: `}</Text>
                  <Text style={styles.value}>{``}</Text>
                </View>
              </View>
              {renderFooter()}
            </View>
          </Page>
        )

      }
      if (palletamount) { //when technological card is present then add empty page in the end.
        for (let i = 0; i < multiplier; i++) {
          pages.push(
            <Page key={`page-end-${i}`} size="A4" style={styles.page} orientation={'portrait'}>
              {emptyPage()}
            </Page>
          )
        }
      }
    } else { //when user has chosen to print empty then only add empty page
      for (let i = 0; i < Math.ceil(count) * multiplier; i++) {
        pages.push(
          <Page key={`page-${i}`} size="A4" style={styles.page} orientation={'portrait'}>
            {emptyPage()}
          </Page>

        )
      }
    }

    return pages
  }

  return (
    <Document>
      {
          renderPages()
      }
    </Document>
  )
}

export default PrintoutDocumentFi