import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GetBlueprintLog } from '../graphql/queries'
import { formatDate } from '../util/format'

const BlueprintLog = ({
    productionCode,
}) => {
    const { t } = useTranslation()
    const { data } = useQuery(GetBlueprintLog, {
        skip: !productionCode,
        variables: {
            productionCode,
        },
    })

    const getFields = (input) => {
        if (!input) return
        
        const list = input.split(', ')

        return list.map(x => t(`blueprint.${x}`)).join(', ')
    }

    return (
        <table className='blueprint-log'>
            <tbody>
                {
                    data?.getBlueprintLog?.length ?
                    data.getBlueprintLog.map(x =>
                        <tr className='blueprint-log-item' key={x.ID}>
                            <td>{ formatDate(new Date(x.CREATED), true) }</td>
                            <td>{ x.author.RNAME }</td>
                            <td>{ getFields(x.UPDATED_FIELDS) }</td>
                        </tr>    
                    )
                    :
                    <></>
                }
            </tbody>
        </table>
    )
}

export default BlueprintLog