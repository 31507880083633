import writeXlsxFile from 'write-excel-file'
import { removeTags } from './format'
import { calculateWeight, getSpread } from './calc'
import { MachineCodes } from './const'

const ExportToExcel = async (tableRef, fileNamePrefix) => {
    if (!tableRef.current) return
    const dataset = {
        columns: [],
        data: [],
    }
    Array.from(tableRef.current?.getTableRows()).map((row, index) => {
        const items = []
        Array.from(row.cells).map((cell, cellIndex) => {
            if (index === 0) {
                items.push({
                    value: removeTags(cell.innerHTML),
                    fontWeight: 'bold',
                })
            } else items.push({
                value: removeTags(cell.innerHTML),
            })
        })

        if (index === 0) {
            dataset.columns = items
        }
        else dataset.data.push(items)
    })
    
    await writeXlsxFile([
        dataset.columns,
        ...dataset.data,
    ], {
        fileName: `${fileNamePrefix}-${new Date().getTime()}.xlsx`,
        stickyRowsCount: 1
    })
}

const getDeliveryNoteRowAddress = (rows) => {
    if (!rows || rows.length < 0) return ''
    const addressRow = rows.find(x => x.production && x.production.ADDRESS)
    return addressRow?.production?.ADDRESS
}

const getMaterialNuut = (prodData) => {
    if (prodData && prodData.materialOrders && prodData.materialOrders[0]) {
        return prodData.materialOrders[0].NUUT
    }
    return ''
}

const getMaterialSize = (prodData) => {
    if (prodData && prodData.materialOrders && prodData.materialOrders[0]) {
        return prodData.materialOrders[0].SIZE
    }
    return ''
}

const getWeight = (box, materials) => {
    const spread = getSpread(box)
    if (!spread.length || !spread.width) return 0

    const material = materials.find(x => x.MARK === box.MATERIAL.MARK || x.MARK === box.MATERIAL)
    return material ? calculateWeight(spread.length, spread.width, material.DENSITY) : 0
}

const getBlueprintMachineList = (blueprint) => {
    if (!blueprint) return []

    const machines = {}

    for (const [key, value] of Object.entries(blueprint)) {
        if (!MachineCodes.some(x => x.value === key) || !value) continue

        machines[key] = value
    }

    return machines
}

function isValidDate(date) {
    return date instanceof Date && !isNaN(date)
}

const forcePasswordChange = (updated) => {
    if (!updated) return true
    const updatedTime = new Date(updated).getTime()
    const interval = 1000*60*60*24*90
  
    return updatedTime + interval <= Date.now()
}

const formatNuut = (nuut) => {
    if (!nuut) return ''
    let nodes = nuut.split('-')
    if (nodes.length > 2) nodes[1] = `${nodes[1]}*`

    return nodes.join(',')
}

export {
    ExportToExcel,
    getDeliveryNoteRowAddress,
    getMaterialNuut,
    getMaterialSize,
    getWeight,
    getBlueprintMachineList,
    isValidDate,
    forcePasswordChange,
    formatNuut,
}