import jwtDecode from 'jwt-decode'
import React, { useState, useEffect, useContext, createContext } from 'react'

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider
            value={ auth }
        >
            { children }
        </authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  useEffect(() => {
    updateUserData()
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark')
      document.body.classList.remove('theme--light')
      document.body.classList.add('theme--dark')
      return
    }
    localStorage.setItem('theme', 'light')
    document.body.classList.remove('theme--dark')
    document.body.classList.add('theme--light')
  }, [theme])

  const signIn = async (token) => {
    localStorage.setItem('token', token)
    updateUserData()
  }

  const updateUserData = () => {
    try {
      const token = localStorage.getItem('token')
      const userData = jwtDecode(token)
  
      setUser(userData)
      setLoggedIn(userData ? true : false)
    } catch (error) {
      signOut()
    }
  }

  const signOut = () => {
    localStorage.removeItem('token')
    setUser(null)
    setLoggedIn(false)
  }

  return {
    user,
    loggedIn,
    signIn,
    signOut,
    setTheme,
    theme,
    loaded,
  }
}