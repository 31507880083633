import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { ExportMaterials, GetMaterialCodes } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateMaterial, RemoveMaterial, UpdateMaterial } from '../../graphql/mutations'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import ImportMaterialCodeModal from '../../components/ImportMaterialCodeModal'
import { formatDate } from '../../util/format'

const includeFields = [
    {
        value: 'CODE',
        label: 'Kood',
    },
    {
        value: 'CLASS',
        label: 'Klass',
    },
    {
        value: 'NAME',
        label: 'Nimi',
    },
    {
        value: 'NUUT',
        label: 'Nuudid',
    },
    {
        value: 'MARK',
        label: 'Mark',
    },
    {
        value: 'FSC',
        label: 'FSC',
    },
    {
        value: 'COLOR',
        label: 'Värv',
    },
    {
        value: 'UNIT',
        label: 'Ühik',
    },
]

const MaterialCodesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [showImport, setShowImport] = useState(false)
    const [exportLoading, setExportLoading] = useState(null)
    const [link, setLink] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [newItem, setNewItem] = useState({})

    const { refetch: getExport } = useQuery(ExportMaterials, {
        fetchPolicy: 'no-cache',
    })
    
    const [updateItem] = useMutation(UpdateMaterial)
    const [createItem] = useMutation(CreateMaterial)
    const [removeItem] = useMutation(RemoveMaterial)

    const addItem = async () => {
        if (!validateFields()) return
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createMaterial) {
                setShowModal(false)
                setNewItem({})
                tableRef.current?.refresh();
            }
        } catch (err) {
            console.log('Error creating material', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    factory: selectedRow.FACTORY,
                    mark: selectedRow.MARK,
                },
            })
            setSelectedRow(null)
            if (data.removeMaterial) tableRef.current?.refresh()
        } catch (err) {
            console.log('Error deleting material', err)
        }
    }

    const updateProperty = async (item, field, value) => {
        try {
            let parsedValue = value
            if (field === 'PRICE') parsedValue = parseFloat(value)
            if (['LAYER', 'ACTIVE', 'DENSITY'].includes(field)) parsedValue = parseInt(value)

            await updateItem({
                variables: {
                    factory: item.FACTORY,
                    mark: item.MARK,
                    data: {
                        [field]: parsedValue,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            default:
                return row[field]
        }
    }


    const handleExport = async () => {
        setExportLoading(true)
        try {
            const res = await getExport()

            setLink(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.exportMaterials}`)
            setFileName(
                `Materjali eksport ${formatDate(new Date(), true)}.xlsx`
            )

            if (!res?.data?.exportMaterials || res.data.exportMaterials === 'FAIL') {
                console.log('Export error')
                return
            }
        } catch (err) {
            console.log(err)
        } finally {
            setExportLoading(false)
        }
    }

    const handleRowClick = async (row) => {
        setSelectedRow(row)
    }

    const validateFields = () => {
        if (!newItem.FACTORY || !newItem.PRICE || !newItem.MARK || !newItem.LAYER) return false
        return true
    }

    return (
        <div className='materials-screen'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Materjalide koodid') }</h2>
                    <div className='inner-title--actions'>
                        {/* <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        /> */}
                        <Button
                            label={'Impordi'}
                            onClick={() => setShowImport(true)}
                        />
                        {/* <Button
                            label={'Ekspordi'}
                            onClick={handleExport}
                            loading={exportLoading}
                        /> */}
                        {/* {
                            fileName && link ?
                            <Button
                                label={ t('Lae excel alla') }
                                link={link}
                                download={fileName}
                                disabled={!link || !fileName}
                                onClick={() => {
                                    setFileName(null)
                                    setLink(null)
                                }}
                            />
                            :
                            <></>
                        } */}
                    </div>
                </div>
                <FilterTable
                    ref={tableRef}
                    query={GetMaterialCodes}
                    queryKey={'getMaterialCodes'}
                    fieldConditions={fieldConditions}
                    includeFields={includeFields}
                    initialOrderBy={'ID'}
                    onRowClick={handleRowClick}
                    activeRows={selectedRow ? [selectedRow] : []}
                    hideHeaderSearch
                    // rowKey={'MARK'}
                />
            </div>
            {/* <Modal
                title={t('Uus materjali kood')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus materjali kood') }</h5>
                <Input
                    label={`${t('Tehas')}*:`}
                    value={newItem.FACTORY}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        FACTORY: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Mark')}*:`}
                    value={newItem.MARK}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        MARK: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Mark 2')}:`}
                    value={newItem.MARK2}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        MARK2: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Tüüp')}:`}
                    value={newItem.TYPE}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        TYPE: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Kood')}:`}
                    value={newItem.CODE}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        CODE: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Hind')}*:`}
                    value={newItem.PRICE}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        PRICE: parseFloat(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Kihid')}*:`}
                    value={newItem.LAYER}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        LAYER: parseInt(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Aktiivne')}:`}
                    value={newItem.ACTIVE}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        ACTIVE: parseInt(e.target.value),
                    })}
                />
                <Input
                    label={`${t('Tihedus')}:`}
                    value={newItem.DENSITY}
                    type={'number'}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        DENSITY: parseInt(e.target.value),
                    })}
                />
                <Button
                    label={t('Lisa materjal')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal> */}
            <ImportMaterialCodeModal
                show={showImport}
                close={() => setShowImport(false)}
            />
        </div>
    )
}

export default MaterialCodesScreen