import { useQuery } from '@apollo/client'
import React, { useState, useContext, createContext } from 'react'
import { GetConstants } from '../graphql/queries'

const ConstantsContext = createContext()

export const ProvideConstants = ({ children }) => {
  const constants = useProvideConstants()

  return <ConstantsContext.Provider
            value={constants}
        >
            { children }
        </ConstantsContext.Provider>
}

export const useConstants = () => {
  return useContext(ConstantsContext)
}

const useProvideConstants = () => {
  const [constants, setConstants] = useState({})

  const { refetch: fetchConstants } = useQuery(GetConstants, {
    onCompleted: (data) => {
      const { getConstants } = data
      if (getConstants) setConstants(getConstants)
    }
  })

  const refreshConstants = async () => {
    try {
      const { data } = await fetchConstants()

      if (data && data.getConstants) {
        setConstants(data.getConstants)
        return data.getConstants
      }
    } catch (err) {

    }
  }

  return {
    constants,
    refreshConstants,
  }
}