import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
    justifyContent: 'flex-start',
  },
  titleSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginRight: 60,
  },
  row: {
    flexDirection: 'row',
  },
})

const LostClientsReport = ({
  data,
  date,
}) => {

  const { t } = useTranslation()

  if (!data) return (
    <Document></Document>
  )

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'portrait'}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>{ t('Kadunud kliendid') }</Text>
          <Text>{ formatDate(new Date()) }</Text>
        </View>
        <Text
          style={{
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          { `${t('Viimane kuupäev')}: ${date ? formatDate(new Date(date)) : ''}` }
        </Text>
        <View>
          {
            data.map((x, i) =>
              <View key={`client-${i}`} style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text>{ x.label }</Text>
                </View>
                <View style={{ width: 200 }}>
                  <Text>
                    { formatDate(new Date(x.value)) }
                  </Text>
                </View>
              </View>  
            )
          }
        </View>
      </Page>
    </Document>
  )
}

export default LostClientsReport