import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from './ActivityIndicator'
import Checkbox from './Checkbox'
import SelectInput from './SelectInput'

const initialState = {
    display: 'all',
    arrived: false,
}

const options = [
    {
        value: 'all',
        label: 'Kõik',
    },
    {
        value: 'production',
        label: 'Tootmine',
    },
    {
        value: 'buffer',
        label: 'Buffer',
    },
]

const MaterialDeliveryFilters = ({
    onFilterChange,
    loading,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const loadFilters = {
            ...filters,
        }

        for (const [param, value] of searchParams.entries()) {
            if (param === 'page') continue
            loadFilters[param] = value
        }

        setFilters({
            ...loadFilters,
        })

        if (onFilterChange) onFilterChange({
            ...loadFilters,
        })
    }, [searchParams])

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [filters])

    const assignSearchParams = (params) => {
        const newParams = {}
        for (const [key, value] of Object.entries(params)) {
            if (!value) continue
            newParams[key] = value
        }

        setSearchParams({
            ...newParams,
            page: 1,
        })
    }

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)
        assignSearchParams(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters'>
            <div className='filters-status'>
                <SelectInput
                    options={options}
                    placeholder={t('Kuvamine')}
                    value={filters.display ? options.find(x => x.value === filters.display) : undefined}
                    onChange={(option) => setField('display', option.value)}
                />
            </div>
            <Checkbox
                label={t('Saabunud')}
                value={filters.arrived}
                onChange={(e) => setField('arrived', e.target.checked)}
            />
            {
                loading && <ActivityIndicator />
            }
        </div>
    )
}

export default MaterialDeliveryFilters