import { useQuery } from '@apollo/client'
import { PDFViewer } from '@react-pdf/renderer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateInput from '../../components/DateInput'
import { GetLostClients } from '../../graphql/queries'
import LostClientsReport from '../../reports/lost-clients'
import { parseDate } from '../../util/format'
import ActivityIndicator from '../../components/ActivityIndicator'

const LostClientsScreen = () => {

    const { t } = useTranslation()
    const [data, setData] = useState()
    const [date, setDate] = useState()

    const { loading } = useQuery(GetLostClients, {
        variables: {
            date,
        },
        onCompleted: (res) => {
            if (res?.getLostClients) {
                setData(res.getLostClients)
            }
        },
    })

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='pdf-viewer'>
            <div className='inner'>
                <div className='inner-title'>
                    <DateInput
                        label={t('Viimane kuupäev')}
                        value={date ? new Date(date) : null}
                        onChange={(val) => setDate(parseDate(val))}
                    />
                </div>
                {
                    data &&
                    <PDFViewer>
                        { <LostClientsReport data={data} date={date} /> }
                    </PDFViewer>
                }
            </div>
        </div>
    )
}

export default LostClientsScreen