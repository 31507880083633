import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import Input from './Input'
import DateInput from './DateInput'
import { useTranslation } from 'react-i18next'
import ClientSearchInput from './ClientSearchInput'
import Textarea from './Textarea'
import ClientTransport from './ClientTransport'
import Button from './Button'
import { useMutation, useQuery } from '@apollo/client'
import { IssueBufferToSales, SendBufferIssuedEmail } from '../graphql/mutations'
import BufferIssueDocument from '../documents/buffer-issue-document'
import { useNotification } from '../providers/Notification'
import { pdf } from '@react-pdf/renderer'
import { GetBuffer } from '../graphql/queries'
import { useConstants } from '../providers/Constants'
import { getUTCDate } from '../util/format'

const BufferModal = ({
    show,
    close,
    data,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const { constants, refreshConstants } = useConstants()
    const [item, setItem] = useState(data)
    const [amount, setAmount] = useState(0)
    const [issueBufferToSales] = useMutation(IssueBufferToSales)
    const [sendEmail] = useMutation(SendBufferIssuedEmail)

    const { refetch } = useQuery(GetBuffer, {
        skip: true,
        variables: {
            id: parseInt(data)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getBuffer } = data
            if (getBuffer) {
                setItem(getBuffer)
            }
        },
    })

    useEffect(() => {
        if (typeof data === 'object') {
            setItem(data)
            return
        }
        refetch()
    }, [data])

    const setField = (field, value) => {
        setItem({ ...item, [field]: value })
    }

    const handleClientChange = (option) => {
        setItem({ ...item, client: option })
    }

    const handleSendEmail = async () => {
        try {
            const refreshedConstants = await refreshConstants()
            
            const pdfData = [{
                ...item,
                KOGUS: amount,
            }]
            const pdfFile = pdf(<BufferIssueDocument data={pdfData} constants={refreshedConstants} />)
            pdfFile.updateContainer(<BufferIssueDocument data={pdfData} constants={refreshedConstants} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf' })

            const res = await sendEmail({
                variables: {
                    prodId: item.ID,
                    file,
                },
            })

            if (res?.data?.sendBufferIssuedEmail === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Email saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Emaili saatmine ebaõnnestus')
                    },
                })
            }
        } catch (err) {
            console.log('handleSendEmail:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Emaili saatmine ebaõnnestus')
                },
            })
        }
    }

    const issueBuffer = async () => {
        try {
            const res = await issueBufferToSales({
                variables: {
                    bufferId: item.ID,
                    data: {
                        ...item,
                        KLIENT_ID: item?.client?.ID,
                        PRICE: parseFloat(item.PRICE),
                        DEADLINE: item.DEADLINE ? getUTCDate(new Date(item.DEADLINE)) : new Date(),
                        ID: undefined,
                        client: undefined,
                        contact: undefined,
                        creator: undefined,
                        emails: undefined,
                        materialDeliveries: undefined,
                        materialOrders: undefined,
                        order: undefined,
                        __typename: undefined,
                    },
                    amount: parseInt(amount),
                },
            })

            if (res?.data?.issueBufferToSales === 'Success') {
                handleSendEmail()
                if (onSuccess) onSuccess({ amount })
                return
            }
        } catch (e) {

        }
    }

    const displayAmountError = () => {
        if (!item?.KOGUS || !amount) return

        const amountLeft = item?.RKOGUS || item.KOGUS

        return amountLeft < amount ? 'Kontrolli kogust' : null
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                <Button
                    label={t('Katkesta')}
                    onClick={close}
                    className={'btn-cancel'}
                />
                <Button
                    label={t('Väljasta')}
                    onClick={issueBuffer}
                />
            </div>
        )
    }

    const handleClose = () => {
        if (close) close()

        setAmount(0)
    }

    return (
        <Modal
            show={show}
            close={handleClose}
            renderActions={renderModalActions}
            className={'modal-buffer'}
        >
            <div className='modal-buffer--content field-row'>
                <div className='field-col'>
                    <ClientSearchInput
                        value={item?.client}
                        onChange={handleClientChange}
                        defaultValue={item?.client}
                    />
                    <Input
                        label={t('Tellimuse number')}
                        value={item?.KLIENT_ORDER}
                        onChange={(e) => setField('KLIENT_ORDER', e.target.value)}
                    />
                    <DateInput
                        label={t('Tarnekuupäev')}
                        onChange={(val) => {
                            const localeString = val.toLocaleString('en')
                            setField('DEADLINE', localeString)
                        }}
                        value={item?.DEADLINE ? new Date(item.DEADLINE) : new Date()}
                    />
                    <Input
                        label={t('Kogus')}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        type={'number'}
                        placeholder={`${item?.RKOGUS ? item.RKOGUS : (item?.KOGUS || '0')}`}
                        error={displayAmountError()}
                    />
                    <Input
                        label={t('Hind')}
                        value={item?.PRICE}
                        onChange={(e) => setField('PRICE', e.target.value)}
                        type={'number'}
                        placeholder={item?.PRICE}
                    />
                </div>
                <div className='field-col'>
                    <ClientTransport
                        data={item}
                        onChange={setField}
                    />
                    <Textarea
                        label={t('Kommentaar')}
                        value={item?.KOMMENTAAR}
                        onChange={(e) => setField('KOMMENTAAR', e.target.value)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default BufferModal