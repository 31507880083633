import React, { useState } from 'react'
import Input from './Input'

const TableInput = ({
    label,
    initialValue,
    fieldUpdated,
    onBlur,
}) => {

    const [value, setValue] = useState(initialValue)

    return (
        <>
            <Input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                    if (fieldUpdated) fieldUpdated(label, e.target.value)
                }}
                onBlur={() => {
                    if (onBlur) onBlur(label, value)
                }}
            />
            <div className='table-input--hidden'>
                { value }
            </div>
        </>
    )
}

export default TableInput