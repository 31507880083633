import React from 'react'

const ExclamationIcon = ({ fill }) => (
    <svg version="1.1" baseProfile="tiny" fill={fill || '#010C3A'} xmlns="http://www.w3.org/2000/svg" viewBox="-1.7 0 20.4 20.4" xmlSpace="preserve">
        <path d="M16.406 10.283a7.917 7.917 0 1 1-7.917-7.917 7.916 7.916 0 0 1 7.917 7.917zM9.48 14.367a1.003 1.003 0 1 0-1.004 1.003 1.003 1.003 0 0 0 1.004-1.003zM7.697 11.53a.792.792 0 0 0 1.583 0V5.262a.792.792 0 0 0-1.583 0z"/>
    </svg>

)

export default ExclamationIcon
