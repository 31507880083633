import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllUsers } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from '../../components/ActivityIndicator'
import { CreateUser, RemoveUser, UpdateUser } from '../../graphql/mutations'
import Pagination from '../../components/Pagination'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'

const UsersScreen = () => {

    const { t } = useTranslation()
    const page = useRef(0)
    const limit = useRef(100)
    const [tableHeaders, setTableHeaders] = useState([])
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState({})

    const { data, loading, refetch } = useQuery(GetAllUsers, {
        variables: {
          page: page.current,
          limit: limit.current,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getAllUsers } = data
            if (getAllUsers?.rows?.length > 0) {
                let keys = []
                for (const key in getAllUsers.rows[0]) {
                    if (['__typename', 'ID'].includes(key)) continue
                    keys.push(key)
                }
                setTableHeaders([...keys])
            }
        }
    })
    
    const [updateItem] = useMutation(UpdateUser)
    const [createItem] = useMutation(CreateUser)
    const [removeItem] = useMutation(RemoveUser)

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
    }

    const addItem = async () => {
        if (!validateFields()) return
        try {
            const { data } = await createItem({
                variables: {
                    data: newItem,
                },
            })
            if (data.createUser) {
                setShowModal(false)
                setNewItem({})
                refetch()
            }
        } catch (err) {
            console.log('Error creating user', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return
        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(selectedRow.ID)
                },
            })
            setSelectedRow(null)
            if (data.removeUser) refetch()
        } catch (err) {
            console.log('Error deleting user', err)
        }
    }

    const updateProperty = async (id, field, value) => {
        try {
            let finalValue = value

            if (['SUPERUSER', 'EXCEL', 'MATERIAL', 'CONSTRUCTOR', 'MAT_ORDERS'].includes(field)) {
                finalValue = parseInt(value, 10)
            }

            await updateItem({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: finalValue,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const validateFields = () => {
        if (!newItem.RNAME || !newItem.UNAME || !newItem.PASSW || !newItem.PROFESSION) return false
        return true
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='users'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Kasutajad') }</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>

                </div>
                { tableHeaders && tableHeaders.length > 0 ?
                    <div className='table-wrapper'>
                        <table className='input-table'>
                            <thead>
                                <tr>
                                {
                                    tableHeaders.map((x, i) =>
                                        <th key={`table-header-col-${i}`} className='grid-list-item--col'>{ t(x) }</th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.getAllUsers.rows.map((item) =>
                                    <tr
                                        key={item.ID}
                                        onClick={() => setSelectedRow(item)}
                                        className={`table-row${item.ID === selectedRow?.ID ? ' table-row--selected' : ''}`}
                                    >
                                        {
                                            tableHeaders.map((x) =>
                                                <td key={`${item.ID}-${x}`}>
                                                    <TableInput
                                                        initialValue={item[x]}
                                                        label={x}
                                                        fieldUpdated={(field, value) => {
                                                            updateProperty(item.ID, field, value)
                                                        }}
                                                    />
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Kasutajaid ei leitud') }</p>
                    </div>
                }
                <Pagination total={data?.getAllUsers?.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
            </div>
            <Modal
                title={t('Kasutaja loomine')}
                show={showModal}
                close={() => setShowModal(false)}
              >
                <h5>{ t('Lisa uus kasutaja') }</h5>
                <Input
                    label={`${t('Kasutajanimi')}:`}
                    value={newItem.UNAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        UNAME: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Salasõna')}:`}
                    value={newItem.PASSW}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        PASSW: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Nimi')}:`}
                    value={newItem.RNAME}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        RNAME: e.target.value,
                    })}
                />
                <Input
                    label={`${t('Amet')}:`}
                    value={newItem.PROFESSION}
                    onChange={(e) => setNewItem({
                        ...newItem,
                        PROFESSION: e.target.value,
                    })}
                />
                <Button
                    label={t('Loo kasutaja')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
        </div>
    )
}

export default UsersScreen