import { Constants } from './const'

const calculateArea = (length, width, perSheet) => {
    if (perSheet > 0) {
        return parseFloat(length) * parseFloat(width) / perSheet * 1e-6
    }
    return parseFloat(length) * parseFloat(width) * 1e-6
}

const getMaterialPrice = (material, factory, materials, boxData) => {
    if (boxData && boxData.MAT_PRICE) return boxData.MAT_PRICE
    if (!material || !factory || !materials) return 0
    const materialItem = materials.find(x => x.MARK === material && x.FACTORY === factory)
    return materialItem ? materialItem.PRICE : 0
}

const getConsumption = (boxData) => {
    const spread = getSpread(boxData)
    if (!spread.length || !spread.width) return 0

    let length = spread.length
    let width = spread.width

    if (boxData.BOX === 'Stants' || boxData.BOX?.NAME === 'Stants') {
        length += 35
        width += 35
    }

    if (boxData.LP_L > 0 && boxData.PER_SHEET > 0) {
        length = parseFloat(boxData.LP_L)
        width = parseFloat(boxData.LP_B)
    }

    const area = calculateArea(length, width, boxData.PER_SHEET)

    return parseFloat((area * (100 + Constants.Failure ) / 100).toFixed(4))
}

const getMaterialTotalPrice = (boxData, materials) => {
    return parseFloat((getConsumption(boxData) * getMaterialPrice(boxData.MATERIAL?.MARK, boxData.FACTORY?.value, materials, boxData)).toFixed(4))
}

const calculateWeight = (length, width, density) => {
    const area = calculateArea(length, width)
    return Math.round(area * density)
}

const calculateDelta = (boxData, materials) => {
    const boxPrice = boxData.FPRICE || calculatePrice(boxData, materials)
    const total = parseFloat(boxPrice - getMaterialTotalPrice(boxData, materials))
    return isNaN(total) ? 0 : total.toFixed(4)
}

const getSpread = (boxData) => {
    if (!boxData?.BOX) return {
        length: 0,
        width: 0,
    }

    if (boxData.LP_L && boxData.LP_B) {
        return {
            length: parseInt(boxData.LP_L),
            width: parseInt(boxData.LP_B),
        }
    }

    if (!boxData.BOX.FORMULA_L || boxData.BOX.NAME === 'Stants') {
        return {
            width: boxData.AB ? boxData.AB : 0,
            length: boxData.AL ? boxData.AL : 0,
        }
    }


    const formulaL = replaceFormulaValues(boxData.BOX.FORMULA_L, boxData.L, boxData.B, boxData.H, boxData.MATERIAL ? boxData.MATERIAL.LAYER : 0)
    const formulaW = replaceFormulaValues(boxData.BOX.FORMULA_B, boxData.L, boxData.B, boxData.H, boxData.MATERIAL ? boxData.MATERIAL.LAYER : 0)
    const values = calculateSpread(formulaL, formulaW)

    return values
}

const replaceFormulaValues = (formula, length, width, height, layers) => {
    if (!formula) return ''
    return formula.replaceAll('L', length ? parseInt(length) : 0).replaceAll('B', width ? parseInt(width) : 0).replaceAll('H', height ? parseInt(height) : 0).replaceAll('K', layers ? parseInt(layers) : 0)
}

const calculateSpread = (lengthFormula, widthFormula) => {
    if (!lengthFormula || !widthFormula) return {
        length: 0,
        width: 0,
    }
    return {
        length: parseInt(eval(lengthFormula)),
        width: parseInt(eval(widthFormula)),
    }
}

const calculatePrice = (boxData, materials) => {
    if (!boxData?.MATERIAL) return 0
    const { length, width } = getSpread(boxData)

    if (!length || !width) return 0

    const matPrice = getMaterialTotalPrice(boxData, materials)
    const price = boxData.LPRICE && parseFloat(boxData.LPRICE) > 0 ? matPrice + parseFloat(boxData.LPRICE) : matPrice + Constants.LabourPlaceholder
    const profit = price * parseFloat(boxData.PROFIT) * 0.01

    let finalPrice

    if (boxData.PRINT.value !== '0' && boxData.PRINT.value !== 'Ofset') {
        finalPrice = price + profit + Constants.PrintPrice
    } else if (boxData.PRINT.value === 'Ofset') {
        const area = calculateArea(length, width, parseInt(boxData.PER_SHEET))
        finalPrice = price + profit + Constants.OfsetPrice * parseFloat(area)
    } else {
        finalPrice = price + profit
    }

    finalPrice = isNaN(finalPrice) ? 0 : parseFloat(finalPrice.toFixed(3))
    return finalPrice
}

export {
    calculateArea,
    calculateWeight,
    replaceFormulaValues,
    calculateSpread,
    getSpread,
    getMaterialPrice,
    getConsumption,
    calculateDelta,
    getMaterialTotalPrice,
    calculatePrice,
}