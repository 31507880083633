import { useQuery, useSubscription } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GetDeliveryNotesByProd } from '../graphql/queries'
import { DeliveryNoteRowCreated } from '../graphql/subscriptions'
import { formatDate } from '../util/format'

const DeliveryNotesList = ({ prodId }) => {

    const { t } = useTranslation()
    const [notes, setNotes] = useState([])
    const rowHeaders = [
        {
            value: 'CREATED_DATE',
            label: t('Kuupäev'),
        },
        {
            value: 'AMOUNT',
            label: t('Kogus'),
        },
    ]

    const { refetch } = useQuery(GetDeliveryNotesByProd, {
        fetchPolicy: 'no-cache',
        variables: {
            prodId: parseInt(prodId),
        },
        onCompleted: (data) => {
            const { getDeliveryNotesByProd } = data
            if (getDeliveryNotesByProd) setNotes(getDeliveryNotesByProd)
        }
    })

    const { data } = useSubscription(DeliveryNoteRowCreated, {
        variables: {
            prodId: parseInt(prodId),
        },
        onSubscriptionData: (data) => {
            refetch()
        }
    })

    const getColumnValue = (row, field) => {
        if (field === 'AMOUNT') {
            const amount = row.rows.reduce((acc, curr) => {
                if (curr.AMOUNT) {
                    acc += curr.AMOUNT
                }
                return acc
            }, 0)
            return amount
        }
        if (field === 'CREATED_DATE') {
            return formatDate(new Date(row[field]))
        }
        return row[field]
    }

    const getTotal = () => {
        const total = notes.reduce((acc, curr) => {
            if (curr.rows) {
                const rowsTotal = curr.rows.reduce((i, x) => {
                    if (x.AMOUNT) i += x.AMOUNT
                    return i
                }, 0)
                acc += rowsTotal
            }
            return acc
        }, 0)

        return total
    }

    return (
        <div className='delivery-note-rows'>
            <div className='delivery-note-rows-list'>
                { notes.length > 0 ?
                    <>
                        <table className='order-rows'>
                            <thead
                                className='order-rows--header'
                                style={{
                                    gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                }}
                            >
                                <tr>
                                {
                                    rowHeaders.map((headerItem) =>
                                        <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                            { headerItem.label }
                                        </th>
                                    )
                                }
                                </tr>
                            </thead>
                            <tbody className='order-rows--content'>
                                {
                                    notes.map((row, index) =>
                                        <tr
                                            key={`order-row-${index}`}
                                            className={`order-row`}
                                            onClick={() => window.open(`/deliverynote/${row.ID}`)}
                                            style={{
                                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                            }}
                                        >
                                            {
                                                rowHeaders.map((headerItem, itemIndex) =>
                                                    <td
                                                        key={`order-row-${index}-${itemIndex}`}
                                                        className='order-row--item'
                                                    >
                                                        { getColumnValue(row, headerItem.value) }
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <div className='delivery-note-total'>
                            <span>{ `${t('Kokku')}:` }</span>
                            <span>{ getTotal() }</span>
                        </div>
                    </>
                    :
                    <div className='empty-result'>
                        { t('Saatelehed puuduvad') }
                    </div>
                }
            </div>
        </div>
    )
}

export default DeliveryNotesList