import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNotification } from '../providers/Notification';

const Notification = () => {
    const { notifications, dispatch } = useNotification()
    const { t } = useTranslation()

    useEffect(() => {
        document.body.addEventListener('keyup', handleEsc)

        return () => {
            document.body.removeEventListener('keyup', handleEsc)
        }
    }, [])

    const handleEsc = (e) => {
        if (e.key === 'Escape') {
            dispatch({
                type: 'REMOVE_ALL',
            })
        }
    }

    if (!notifications || notifications.length < 1) return <></>

    return (
        <div className='notifications'>
            {
                notifications.map((item) => {
                    
                    setTimeout(() => {
                        dispatch({
                            type: 'REMOVE',
                            payload: {
                                id: item.id,
                            },
                        })
                    }, 3000)
                    return (
                        <div
                            key={item.id}
                            className={`notification notification-${item.type ? item.type : 'default'}`}
                            onClick={() => {
                                dispatch({
                                    type: 'REMOVE',
                                    payload: {
                                        id: item.id,
                                    },
                                })
                            }}
                        >
                            <div className='notification-inner'>
                                <div className='notification-title'>
                                    { t(item.type) }
                                </div>
                                <div className='notification-content'>
                                    { item.content }
                                </div>
                            </div>
                            <div className='notification-close-wrapper'>
                                <div className='notification-close'></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Notification