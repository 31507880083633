import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { formatDate } from '../util/format'

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf'
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf'
import { useTranslation } from 'react-i18next'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: UbuntuRegular,
        fontWeight: 400,
      },
      {
        src: UbuntuBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
    paddingBottom: 120,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
    width: 400,
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  infoRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 10,
  },
  tableColText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 9,
  },
  col1: {
    width: '25%',
    paddingRight: 3,
  },
  col2: {
    width: '20%',
    paddingRight: 3,
  },
  col3: {
    width: '25%',
    paddingRight: 3,
  },
  col4: {
    width: '15%',
    paddingRight: 3,
  },
  col5: {
    width: '10%',
    paddingRight: 3,
  },
  col6: {
    width: '10%',
    paddingRight: 3,
  },
  col7: {
    width: '10%',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    width: 80,
  },
  value: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  info: {
    fontSize: 10,
  },
  image: {
    width: 70,
    height: 50,
  },
  footerWrap: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    right: 40,
    alignItems: 'center',
    flex: 1,
  },
  footer: {
    borderTopWidth: 1,
    borderColor: '#000',
    paddingHorizontal: 6,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 11,
    width: '100%',
  },
})

const QuotationDocumentFin = ({
  data,
  rows,
}) => {

  const { t } = useTranslation()

  if (!data || !rows) return <></>

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'portrait'}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: '20%',
              marginRight: 20,
            }}
          >
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          </View>
          <Text style={styles.title}>Multipakend</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Yritys')}:` }</Text>
              </View>
              <Text>{ data.client && data.client.NAME }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Yhteyshenkilö')}:` }</Text>
              </View>
              <Text>{ data.contact && data.contact.CONTACT }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Tel')}:` }</Text>
              </View>
              <Text>{ data.contact && data.contact.PHONE }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Mobiil')}:` }</Text>
              </View>
              <Text>{ data.contact && data.contact.MOBILE }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Email')}:` }</Text>
              </View>
              <Text>{ data.contact && data.contact.EMAIL }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Skype')}:` }</Text>
              </View>
              <Text>{ data.contact && data.contact.SKYPE }</Text>
            </View>

          </View>

          <View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Päivämäärä')}:` }</Text>
              </View>
              <Text>{ formatDate(new Date(data.ODATE)) }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Lähettäjä')}:` }</Text>
              </View>
              <View>
                <Text>{ data.creator && data.creator.RNAME }</Text>
                <Text>{ data.creator && data.creator.PROFESSION }</Text>
              </View>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Puh')}:` }</Text>
              </View>
              <Text>{ data.creator && data.creator.PHONE }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Fax')}:` }</Text>
              </View>
              <Text>{ data.creator && data.creator.FAX }</Text>
            </View>

            <View
              style={styles.infoRow}
            >
              <View style={styles.label}>
                <Text>{ `${t('Email')}:` }</Text>
              </View>
              <Text>{ data.creator && data.creator.EMAIL }</Text>
            </View>

          </View>

        </View>
        <View>
          <Text
            style={{
              fontSize: 13,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            { `${t('Tarjous nr:')} ${data.ORDERNO}` }
          </Text>
        </View>
        <View>
          <View style={[styles.tableRow, { borderBottomWidth: 1, borderBottomColor: '#000', marginTop: 20 }]}>
            <View style={styles.col1}>
              <Text style={styles.tableHeadLabel}>{ t('Koko, mm') }</Text>
            </View>
            <View style={styles.col2}>
              <Text style={styles.tableHeadLabel}>{ t('FEFCO') }</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.tableHeadLabel}>{ t('Laatu') }</Text>
            </View>
            <View style={styles.col5}>
              <Text style={styles.tableHeadLabel}>{ t('Painoväri') }</Text>
            </View>
            <View style={styles.col6}>
              <Text style={styles.tableHeadLabel}>{ t('Määra') }</Text>
            </View>
            <View style={{...styles.col7, textAlign: 'right'}}>
              <Text style={styles.tableHeadLabel}>{ t('Hinta') }</Text>
            </View>
          </View>
          {
            rows.map((row) =>
            <View key={row.ID} style={[styles.tableRow, { borderBottomWidth: 0, borderBottomColor: '#000' }]}>
              <View style={styles.col1}>
                <Text style={styles.tableColText}>{ `${row.L}x${row.B}x${row.H}${row.H_EXTRA ? '/' + row.H_EXTRA : ''}` }</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.tableColText}>{ `${row.BOX}${row.BOXNAME ? ', ' + row.BOXNAME : ''}` }</Text>
              </View>
              <View style={styles.col3}>
                <Text style={styles.tableColText}>{ row.MATERIAL }</Text>
              </View>
              <View style={styles.col5}>
                <Text style={styles.tableColText}>{ row.PRINT }</Text>
              </View>
              <View style={styles.col6}>
                <Text style={styles.tableColText}>{ row.AMOUNT }</Text>
              </View>
              <View style={{...styles.col7, textAlign: 'right'}}>
                <Text style={styles.tableColText}>{ (parseFloat(row.FPRICE) + (!isNaN(parseFloat(row.TRANSPORT)) ? parseFloat(row.TRANSPORT) : 0)).toFixed(3) }</Text>
              </View>
            </View>
            )
          }
          <View style={{
            marginTop: 20,
          }}>
            <Text>{ data.TEXT }</Text>
          </View>
        </View>
        <View style={styles.footerWrap} fixed>
          <View style={styles.footer}>
            <View style={{ marginRight: 20 }}>
              <Text style={{fontSize: 10}}>{ `Multipakend Tootmine OÜ` }</Text>
              <Text style={{fontSize: 10}}>{ `Tuuliku tee 4c` }</Text>
              <Text style={{fontSize: 10}}>{ `10621 Tallinn` }</Text>
            </View>

            <View style={{ marginRight: 20, alignItems: 'center' }}>
              <Text style={{fontSize: 10}}>{ `Reg nr: 12843560` }</Text>
              <Text style={{fontSize: 10}}>{ `KMKR nr: EE101789738` }</Text>
              <Text style={{fontSize: 10}}>{ `info@multipakend.ee` }</Text>
            </View>

            <View style={{ alignItems: 'flex-end' }}>
              <Text style={{fontSize: 10}}>{ `Telefon: +372 6803 730` }</Text>
              <Text style={{fontSize: 10}}>{ `Telefax: +3726 562 666` }</Text>
              <Text style={{fontSize: 10}}>{ `Pank: Swedbank, HABAEE2X` }</Text>
              <Text style={{fontSize: 10}}>{ `IBAN: EE622200221061907439` }</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default QuotationDocumentFin