import React from 'react'
import CheckmarkIcon from './icons/checkmark'

const Checkbox = ({label, value, onChange, error, disabled, onBlur, className}) => {
  return (
    <div className={`input-wrapper input-wrapper--checkbox${error ? ' input-wrapper--error' : ''}${value ? ' input-wrapper--checkbox-checked' : ''}${disabled ? ' input-wrapper--checkbox-disabled' : ''}${className ? ' ' + className : ''}`}>
      <label>
        <input type={'checkbox'} checked={value} onChange={onChange} disabled={disabled} onBlur={onBlur} />
        <div className='input-wrapper--checkbox-box'>
          <CheckmarkIcon />
        </div>
        { label && <span>{ label }</span> }
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default Checkbox