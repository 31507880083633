import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetBasicReport, GetClients, GetClientsByName, GetSingleCompanyReport } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import DateInput from '../../components/DateInput'
import { parseDate } from '../../util/format'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'

const periods = [
    {
        label: 'Päev',
        value: 'day',
    },
    {
        label: 'Nädal',
        value: 'week',
    },
    {
        label: 'Kuu',
        value: 'month',
    },
    {
        label: 'Kvartal',
        value: 'quarter',
    },
    {
        label: 'Aasta',
        value: 'year',
    },
]

const initialResult = {
    labels: [],
    data: [],
}

const ReportOneCompanyScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [period, setPeriod] = useState('week')
    const [company, setCompany] = useState()
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)

    const { refetch: searchClients } = useQuery(GetClientsByName, {
        skip: true,
    })

    const { refetch } = useQuery(GetSingleCompanyReport, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start,
            end,
            period,
            shipped,
            client: company?.ID,
        },
        onCompleted: (data) => {
            if (data?.getSingleCompanyReport) {
                setResult({
                    labels: data.getSingleCompanyReport.map(x => x.label),
                    data: data.getSingleCompanyReport.map(x => x.value),
                })
            }
        },
    })

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        period,
        start,
        end,
        shipped,
        company,
    ])

    const loadClients = async (input) => {
        if (input.length < 2) return
        const res = await searchClients({
            name: input,
        })
        return res.data.getClientsByName
    }

    return (
        <div className='report-one-company'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Ühe firma realisatsioon') }</h2>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <SelectInput
                        label={t('Periood')}
                        options={periods}
                        value={periods.find(x => x.value === period)}
                        onChange={(option) => setPeriod(option.value)}
                    />
                    <div className='client-search'>
                        <SelectInput
                            load={loadClients}
                            label={t('Firma')}
                            value={company}
                            getOptionLabel={(option) => option.NAME}
                            getOptionValue={(option) => option.ID}
                            loadingMessage={() => t('Laen kliente')}
                            noOptionsMessage={() => t('Kirjuta, et klienti otsida')}
                            onChange={(option) => setCompany(option)}
                        />
                    </div>
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value) {
                                        return value ? Number(parseFloat(value).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                        plugins={[ChartDataLabels]}
                        data={{
                            labels: result.labels,
                            datasets: [
                                {
                                    id: 1,
                                    label: periods.find(x => x.value === period).label,
                                    data: result.data,
                                    backgroundColor: chartColors[0],
                                },
                            ],
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default ReportOneCompanyScreen