import React from 'react'

const FileInput = ({
    onChange,
    label,
    allowedTypes,
}) => {

    return (
        <div className='file-input'>
            <label>
                <span>{ label }</span>
                <input type='file' onChange={onChange} accept={allowedTypes} />
            </label>
        </div>
    )
}

export default FileInput