import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from './Checkbox'
import Input from './Input'

const BoxProjectWorkflow = ({
    onFieldChange,
    onFieldBlur,
    tigel,
    verona,
    titan,
    sre,
    inline,
    slotter,
    flexo,
}) => {

    const { t } = useTranslation()

    return (
        <div className='box-material-fields--workflow'>
            <div className='field-row'>
                <div className='field-col'>
                    <h5>{ t('Matriits') }</h5>
                    <div className='box-material-fields--color'>
                        <Checkbox
                            label={`${t('TIGEL')}`}
                            value={tigel === 1}
                            onChange={(e) => onFieldChange('TIGEL', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                        <Checkbox
                            label={`${t('VERONA')}`}
                            value={verona === 1}
                            onChange={(e) => onFieldChange('VERONA', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                        <Checkbox
                            label={`${t('TITAN')}`}
                            value={titan === 1}
                            onChange={(e) => onFieldChange('TITAN', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                        <Checkbox
                            label={`${t('SRE')}`}
                            value={sre === 1}
                            onChange={(e) => onFieldChange('SRE', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                    </div>
                </div>
                <div className='field-col'>
                    <h5>{ t('Print') }</h5>
                    <div className='box-material-fields--pantone'>
                        <Checkbox
                            label={`${t('INLINE')}`}
                            value={inline === 1}
                            onChange={(e) => onFieldChange('INLINE', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                        <Checkbox
                            label={`${t('SLOTTER')}`}
                            value={slotter === 1}
                            onChange={(e) => onFieldChange('SLOTTER', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                        <Checkbox
                            label={`${t('FLEXO')}`}
                            value={flexo === 1}
                            onChange={(e) => onFieldChange('FLEXO', e.target.checked ? 1 : 0)}
                            onBlur={onFieldBlur}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxProjectWorkflow