import React from 'react'
import BackIcon from './icons/back'

const BackButton = ({onClick, label, disabled, className}) => {

  return (
    <button
      className={`btn btn-back${className ? ' ' + className : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className='btn-icon'>
        <BackIcon />
      </div>
      <span>
        { label }
      </span>
    </button>
  )
}

export default BackButton