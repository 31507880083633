import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RemoveFile, UploadFile } from '../graphql/mutations'
import { GetFiles, GetFileTypes } from '../graphql/queries'
import Button from './Button'
import FileInput from './FileInput'
import SelectInput from './SelectInput'
import { IsDev } from '../util/const'

const fileUrl = IsDev ? 'http://localhost:3050/files/' : process.env.REACT_APP_FILES

const rowHeaders = [
    {
        value: 'fileType',
        label: 'Tüüp',
    },
    {
        value: 'FILENAME',
        label: 'Faili nimi',
    },
    {
        value: 'user',
        label: 'Salvestaja',
    },
]

const FilesUpload = ({
    orderRowId,
}) => {

    const { t } = useTranslation()
    const [file, setFile] = useState(null)
    const [upload] = useMutation(UploadFile)
    const [fileType, setFileType] = useState(null)

    const [removeItem] = useMutation(RemoveFile)
    const { data: fileTypeQuery } = useQuery(GetFileTypes)
    const { data: fileQuery, refetch } = useQuery(GetFiles, {
        variables: {
            orderRowId: parseInt(orderRowId),
        },
    })

    const handleUpload = async () => {
        try {
            const { data } = await upload({
                variables: {
                    file,
                    fileType: fileType ? fileType.ID : null,
                    orderRowId: parseInt(orderRowId),
                },
            })

            if (data && data.singleUpload) {
                setFile(null)
                refetch()
            }
        } catch (err) {
            console.log('handleUpload', err)
        }
    }

    const handleRemove = async (id) => {
        try {
            await removeItem({
                variables: {
                    id: parseInt(id),
                },
            })
            refetch()
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const getColValue = (row, field) => {
        if (field === 'fileType') return row[field]?.NAME
        if (field === 'user') return row[field]?.RNAME
        return row[field]
    }

    const handleOpenFile = (filename, e) => {
        if (e.target.classList.contains('btn')) {
            e.preventDefault()
            return
        }
        window.open(`${fileUrl}${filename}`, '_blank')
    }

    return (
        <div className='files-upload'>
            <div className='files-list'>
                { fileQuery?.getFiles?.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { headerItem.label }
                                    </th>
                                )
                            }
                            <th></th>
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                fileQuery.getFiles.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                    onClick={(e) => handleOpenFile(row.FILENAME, e)}
                                                >
                                                    { getColValue(row, headerItem.value) }
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button
                                                label={t('Kustuta')}
                                                onClick={() => handleRemove(row.ID)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Faile pole lisatud') }
                    </div>
                }
            </div>
            <div className='upload-file'>
                <div className='upload-file--type'>
                    <SelectInput
                        label={`${t('Faili tüüp')}:`}
                        options={fileTypeQuery?.getFileTypes}
                        getOptionLabel={(option) => option.NAME}
                        getOptionValue={(option) => option.NAME}
                        onChange={(option) => setFileType(option)}
                        value={fileType}
                    />
                    <FileInput key={file?.name} label={t('Vali fail arvutist')} onChange={(e) => setFile(e.target.files[0])} />
                </div>
                <div className='upload-file--filename'>{ file?.name }</div>
                <Button
                    label={'Lae üles'}
                    disabled={!file}
                    onClick={handleUpload}
                />
            </div>
        </div>
    )
}

export default FilesUpload