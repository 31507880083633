import React, { useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetMaterialOrder, GetPaperFactories } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateInput from '../../components/DateInput'
import Checkbox from '../../components/Checkbox'
import Textarea from '../../components/Textarea'
import { RemoveMaterialOrder, UpdateMaterialOrder } from '../../graphql/mutations'
import ActivityIndicator from '../../components/ActivityIndicator'
import SelectInput from '../../components/SelectInput'
import Button from '../../components/Button'
import MaterialOrderItem from '../../components/MaterialOrderItem'
import AddMaterialModal from '../../components/AddMaterialModal'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import PdfViewer from '../../components/PdfViewer'

const rowHeaders = [
    'ID',
    'Rida number',
    'SIZE',
    'SIZE_BOX',
    'MARK',
    'FSC',
    'MARK_COMMENT',
    'FINISHED_DATE',
    'DELIVERY_DATE',
    'PRICE',
    'AMOUNT',
    'AREA',
    'NUUT',
    'REMARK',
    'SPEED',
    'CLIENT_ID',
    'BOX_DATE',
    'BOX_TYPE',
    'BOX_SIZE',
    'PRIORITY',
]

const MaterialOrderScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [pdfDocument, setPdfDocument] = useState(null)
    const [orderData, setOrderData] = useState(null)
    const [orderItems, setOrderItems] = useState([])
    const [factories, setFactories] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const { loading, refetch: refetchOrder } = useQuery(GetMaterialOrder, {
        variables: {
          id: parseInt(id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getMaterialOrder } = data
            if (getMaterialOrder) {
                if (getMaterialOrder.orderRows) {
                    setOrderItems(getMaterialOrder.orderRows)
                }
                setOrderData(getMaterialOrder)
            }
        },
    })

    useQuery(GetPaperFactories, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getPaperFactories } = data
            if (getPaperFactories?.length > 0) {
                setFactories(getPaperFactories)
            }
        },
    })

    const [updateMaterialOrder] = useMutation(UpdateMaterialOrder)
    const [removeMaterialOrder] = useMutation(RemoveMaterialOrder)

    const setOrderField = (field, value) => {
        setOrderData({
            ...orderData,
            [field]: value,
        })
        updateOrder(field, value)
    }

    const changeFactory = (factoryId) => {
        const paperFactory = factories.find(x => x.ID === factoryId)
        setOrderData({
            ...orderData,
            paperFactory,
        })
        updateOrder('FACTORY_ID', paperFactory.ID)
    }

    const updateOrder = async (field, value) => {
        try {
            await updateMaterialOrder({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('Error updating material order', err)
        }
    }

    const removeOrder = async () => {
        try {
            await removeMaterialOrder({
                variables: {
                    id: parseInt(id),
                },
            })
            navigate(-1)
        } catch (err) {
            console.log('Error removing material order', err)
        }
    }

    const handleRowUpdate = () => {
        refetchOrder()
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfDocument(null)
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='material-order'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Materjali tellimus') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Kustuta tellimus')}
                            onClick={removeOrder}
                        />
                        <Button
                            label={t('ENG tellimus')}
                            onClick={() => {
                                setPdfDocument('material-eng')
                                setShowPdfModal(true)
                            }}
                        />
                        <Button
                            label={t('RUS tellimus')}
                            onClick={() => {
                                setPdfDocument('material-rus')
                                setShowPdfModal(true)
                            }}
                        />
                        <Button
                            label={t('Sisemine dokument')}
                            onClick={() => {
                                setPdfDocument('material-internal')
                                setShowPdfModal(true)
                            }}
                        />
                    </div>
                </div>
                { orderData ?
                    <div className='order-fields'>
                        <div className='invoice-fields'>
                            <h5>{ t('Arve') }</h5>
                            <div className='field-row align-items--center'>
                                <div className='field-col'>
                                    <Input
                                        label={ t('Number') }
                                        type={'text'}
                                        value={orderData.ORDER_NUMBER}
                                        onChange={(e) => setOrderField('ORDER_NUMBER', e.target.value)}
                                    />
                                </div>
                                <div className='field-col'>
                                    <DateInput
                                        label={t('Kuupäev')}
                                        value={orderData.CREATED_DATE ? new Date(orderData.CREATED_DATE) : null}
                                        onChange={(val) => {
                                            const localeString = val.toLocaleString('en')
                                            setOrderField('CREATED_DATE', localeString)
                                        }}
                                    />
                                </div>
                                <div className='field-col field-col--factory'>
                                    <SelectInput
                                        label={`${t('Tehas')}:`}
                                        options={factories}
                                        getOptionLabel={item => item.FACTORY_NAME}
                                        getOptionValue={item => item.ID}
                                        onChange={val => changeFactory(val.ID)}
                                        value={factories.find(x => x.ID === orderData?.paperFactory?.ID)}
                                    />
                                </div>
                                <div className='field-col'>
                                    <div className='field-item field-item--checkbox'>
                                        <Checkbox
                                            label={t('Tellitud')}
                                            value={orderData.ORDERED === 1}
                                            onChange={e => setOrderField('ORDERED', e.target.checked ? 1 : 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Textarea
                                label={t('Märkus')}
                                value={orderData.REMARK}
                                onChange={e => setOrderField('REMARK', e.target.value)}
                            />
                            <div className='field-item invoice-fields-item'>
                                <span className='field-item--label invoice-fields-item--label'>{ t('Koostaja') }</span>
                                <span className='field-item--value invoice-fields-item--value'>{ orderData.creator ? orderData.creator.RNAME : '' }</span>
                            </div>
                        </div>
                        <div className='table-actions'>
                            <Button
                                label={ t('Lisa uus rida') }
                                onClick={() => setShowModal(true)}
                            />
                        </div>
                        <div className='table-wrapper'>
                            { orderItems.length > 0 ?
                                <table className='order-rows'>
                                    <thead
                                        className='order-rows--header'
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        <tr>
                                            {
                                                rowHeaders.map((headerItem) =>
                                                    <th key={`header-${headerItem}`} className='order-rows--header-item'>
                                                        { t(headerItem) }
                                                    </th>
                                                )
                                            }
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='order-rows--content'>
                                        {
                                            orderItems.map((row) =>
                                                <MaterialOrderItem
                                                    onClick={() => {
                                                        setSelectedItem(row)
                                                        setShowModal(true)
                                                    }} 
                                                    key={row.ID}
                                                    data={row}
                                                    onUpdate={handleRowUpdate}
                                                />
                                            )
                                        }
                                    </tbody>
                                </table>
                                :
                                <div className='empty-result'>
                                    { t('Ühtegi rida pole veel tellimusse lisatud.') } <span onClick={() => setShowModal(true)}>{ t('Lisa rida') }</span>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Materjali tellimust ei leitud') }</p>
                    </div>
                }
            </div>
            <AddMaterialModal
                close={() => {
                    setShowModal(false)
                    setSelectedItem(null)
                }}
                showModal={showModal}
                orderId={id}
                isBuffer={!!selectedItem?.BUFFER_ID}
                orderRowId={selectedItem ? selectedItem.ID : null}
                orderUpdated={handleRowUpdate}
            />
            <Modal
                show={showPdfModal}
                className={'make-order-modal'}
                close={handleClosePdfModal}
            >
                <PdfViewer document={pdfDocument} data={orderData} />
            </Modal>
        </div>
    )
}

export default MaterialOrderScreen