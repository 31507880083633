import React, { useEffect, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetBoxes, GetClasses, GetClientContacts, GetMaterials, GetMaterialGroups, GetOrder, GetUsers, SearchClients } from '../../graphql/queries'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import BoxFields from '../../components/BoxFields'
import SelectInput from '../../components/SelectInput'
import Textarea from '../../components/Textarea'
import DateInput from '../../components/DateInput'
import { CreateOrderRow, CreateProductionItem, DuplicateOrder, GetProductionCode, RemoveOrder, RemoveOrderRow, UpdateOrder, UpdateOrderRow } from '../../graphql/mutations'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import PrintIcon from '../../components/icons/print'
import { calculateArea, calculateWeight, getSpread } from '../../util/calc'
import Modal from '../../components/Modal'
import { useNotification } from '../../providers/Notification'
import { parseDate } from '../../util/format'
import ActivityIndicator from '../../components/ActivityIndicator'
import ConfirmModal from '../../components/ConfirmModal'
import PdfViewer from '../../components/PdfViewer'
import { OrderRowUpdated } from '../../graphql/subscriptions'
import OrderItemsList from '../../components/OrderItemsList'
import Checkbox from '../../components/Checkbox'
import ClientSearchInput from '../../components/ClientSearchInput'
import BoxNotification from '../../components/BoxNotification'

const initialOrder = {
    ORDERNO: null,
    CLIENT_ID: null,
    ODATE: null,
    TEXT: null,
    CREATOR: null,
    STATUS: null,
    PROD_DATE: null,
    CONTACT_ID: null,
    creator: null,
    client: null,
}

const prints = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: 'Ofset',
        label: 'Offset',
    },
]

const OrderScreen = () => {

    const { orderId } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [currentRow, setCurrentRow] = useState(null)
    const [showProdModal, setShowProdModal] = useState(null)
    const [prodSelection, setProdSelection] = useState(null)
    const { dispatch } = useNotification()

    const [contacts, setContacts] = useState([])
    const [creators, setCreators] = useState([])
    const [orderInfo, setOrderInfo] = useState(initialOrder)
    const [prodOrderNumber, setProdOrderNumber] = useState('')
    const [prodDate, setProdDate] = useState('')
    const [buffer, setBuffer] = useState(false)
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [pdfDocument, setPdfDocument] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [classes, setClasses] = useState()
    const [materials, setMaterials] = useState()
    const [materialGroups, setMaterialGroups] = useState()
    const [factories, setFactories] = useState()
    const [boxes, setBoxes] = useState()
    
    const [duplicateItem] = useMutation(DuplicateOrder)

    const { data, refetch: fetchOrder, loading, subscribeToMore } = useQuery(GetOrder, {
        variables: {
          id: parseInt(orderId),
        },
        onCompleted: (data) => {
            const { getOrder: order } = data
            if (order) {
                setOrderInfo(order)
            }
        },
    })

    const { refetch: getClientContacts } = useQuery(GetClientContacts, {
        variables: {
            clientId: 0,
        },
    })

    const { refetch: fetchClasses } = useQuery(GetClasses, {
        skip: true,
        onCompleted: (data) => {
            if (data && data.getClasses && data.getClasses.length > 0) {
                setClasses(data.getClasses)
            }
        },
    })

    const { refetch: fetchBoxes } = useQuery(GetBoxes, {
        skip: true,
        onCompleted: (data) => {
            if (data && data.getBoxes && data.getBoxes.length > 0) {
                setBoxes(data.getBoxes)
            }
        },
    })

    const { refetch: fetchMaterialGroups } = useQuery(GetMaterialGroups, {
        skip: true,
        onCompleted: (data) => {
            if (data && data.getMaterialGroups && data.getMaterialGroups.length > 0) {
                const materialGroups = data.getMaterialGroups.map(group => ({
                    value: group.ID,            
                    label: group.GROUP_NAME,    
                }))
                setMaterialGroups(materialGroups)  
            }
        }
    })

    const { refetch: fetchMaterials } = useQuery(GetMaterials, {
        skip: true,
        onCompleted: (data) => {
            if (data && data.getMaterials && data.getMaterials.length > 0) {
                const factoryItems = data.getMaterials.reduce((acc, curr) => {
                    if (!acc.some(x => x.value === curr.FACTORY)) {
                        acc.push({
                            value: curr.FACTORY,
                            label: curr.FACTORY,
                        })
                    }
                    return acc
                }, [])
                setFactories(factoryItems)
                setMaterials(data.getMaterials)
            }
        },
    })

    useQuery(GetUsers, {
        onCompleted: (data) => {
            const { getUsers } = data
            if (getUsers && getUsers.length > 0) {
                setCreators(getUsers)
            }
        },
    })

    const [updateOrder] = useMutation(UpdateOrder)
    const [removeOrder] = useMutation(RemoveOrder)
    const [createOrderRow] = useMutation(CreateOrderRow)
    const [removeOrderRow] = useMutation(RemoveOrderRow)
    const [getProductionCode] = useMutation(GetProductionCode)    
    const [createProductionItem] = useMutation(CreateProductionItem)  
    const [updateOrderRow] = useMutation(UpdateOrderRow)

    const addOrderRow = async (rowData, orderRowId) => {
        try {
            const res = await createOrderRow({
                variables: {
                    orderId: parseInt(orderId),
                    data: rowData,
                    orderRowId,
                },
            })
            const { data } = res
            
            if (data?.createOrderRow) {
                await fetchOrder()
                return data.createOrderRow
            }
        } catch (err) {
            console.log('Error adding row:', err)
        }
    }

    const subscribe = () => subscribeToMore({
        document: OrderRowUpdated,
        variables: {
            orderId: parseInt(orderId),
        },
        updateQuery: (prev, { subscriptionData }) => {
            const newData = subscriptionData?.data?.orderRowUpdated

            if (!newData) return prev
    
            const itemRows = prev?.getOrder?.orderRows?.map(x => {
                if (x.ID !== newData.ID) return x
                return newData
            })

            return Object.assign({}, prev, {
                getOrder: {
                    ...prev.getOrder,
                    orderRows: itemRows,
                },
            })
        },
    })

    const duplicateRow = async (rowData) => {
        if (!rowData) return

        const orderRowId = rowData.ID

        const newRow = {
            ...rowData,
            __typename: undefined,
            materialItem: undefined,
        }

        delete newRow.ID
        delete newRow.ORDER_ID
        delete newRow.PRODUCTION_CODE

        return await addOrderRow(newRow, orderRowId)
    }

    const deleteOrderRow = async (row) => {
        try {
            const res = await removeOrderRow({
                variables: {
                    id: parseInt(row.ID),
                },
            })
            const { data } = res
            if (data && data.removeOrderRow === 'Success') {
                await fetchOrder()
            }
        } catch (err) {
            console.log('Error deleting row:', err)
        }
    }

    const submitProduction = async () => {
        if (!orderInfo?.CLIENT_ID && !orderInfo?.client) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Klient on määramata')
                },
            })
            return
        }

        if (!prodSelection || !prodDate || !prodOrderNumber || !data?.getOrder?.orderRows.length > 0) return

        if (prodSelection === 'all') {
            for (const item of data?.getOrder?.orderRows) {
                await setRowToProduction(item)
            }

            setProdSelection(null)
            setShowProdModal(false)
    
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'success',
                    content: t('Tootmisesse edukalt saadetud')
                },
            })
            return
        }

        const prodItem = data?.getOrder?.orderRows?.find(x => x.ID === prodSelection)
        await setRowToProduction(prodItem)

        setProdSelection(null)
        setShowProdModal(false)

        dispatch({
            type: 'ADD',
            payload: {
                type: 'success',
                content: t('Tootmisesse edukalt saadetud')
            },
        })
    }

    const setRowToProduction = async (row) => {
        try {
            const factory = factories.find(x => x.value === row.FACTORY)
            const material = materials.find(x => x.MARK === row.MATERIAL)
            const box = boxes.find(x => x.NAME === row.BOX)
            const classType = classes.find(x => x.ID === row.CLASSTYPE)
            const print = prints.find(x => x.value === row.PRINT)

            
            const boxData = {
                ...row,
                BOX: box,
                MATERIAL: material,
                FACTORY: factory,
                CLASSTYPE: classType,
                PRINT: print,
            }

            const spread = getSpread(boxData)
            const area = calculateArea(spread.length, spread.width, parseInt(row?.PER_SHEET))

            const res = await getProductionCode({
                variables: {
                    orderRowId: parseInt(row.ID),
                    className: classType.NAME,
                    weight: calculateWeight(row.AL, row.AB, row.DENSITY) / 1000,
                    area,
                },
            })
            const { data } = res
            
            if (data && data.getProductionCode) {
                await updateOrderRow({
                    variables: {
                        id: parseInt(row.ID),
                        data: {
                            PRODUCTION_CODE: data.getProductionCode,
                        },
                    },
                })

                const delivery = Date.UTC(
                    prodDate.getFullYear(),
                    prodDate.getMonth(),
                    prodDate.getDate(),
                )

                const result = await createProductionItem({
                    variables: {
                        orderId: parseInt(orderId),
                        orderRowId: parseInt(row.ID),
                        clientOrderNr: prodOrderNumber,
                        delivery,
                        buffer,
                    },
                })
                
                const { data: resData } = result

                if (resData.createProductionItem === 'Success') {
                    handleCloseModal()
                } 

                fetchOrder()
            }
        } catch (err) {
            console.log('Error getting production code:', err)
        }
    }

    const setField = (field, value) => {
        setOrderInfo({
            ...orderInfo,
            [field]: value,
        })
        handleOrderUpdate(field, value)
    }

    const handleOrderUpdate = async (field, value) => {
        try {
            await updateOrder({
                variables: {
                    id: parseInt(orderId),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('handleOrderUpdate', err)
        }
    }

    const handleClientChange = (option) => {
        setOrderInfo({
            ...orderInfo,
            client: option,
            contact: null,
        })
        handleOrderUpdate('CLIENT_ID', parseInt(option.ID))
        handleOrderUpdate('CONTACT_ID', null)
    }

    const handleContactChange = (option) => {
        setOrderInfo({
            ...orderInfo,
            contact: option,
        })
        handleOrderUpdate('CONTACT_ID', parseInt(option.ID))
    }

    const handleCreatorChange = (option) => {
        setOrderInfo({
            ...orderInfo,
            creator: option,
        })
        handleOrderUpdate('CREATOR', option.UNAME)
    }

    const handleDeleteOrder = async () => {
        try {
            await removeOrder({
                variables: {
                    id: parseInt(orderId),
                },
            })
            navigate(-1)
            dispatch({
                type: 'ADD',
                payload: {
                    content: t('Tellimus') + ` ${orderId} ` + t('kustutatud'),
                    type: 'success',
                },
            })
        } catch (err) {
            console.log('handleDeleteOrder', err)
        }
    }

    const handleCloseModal = () => {
        setProdOrderNumber('')
        setProdDate('')
        setBuffer(false)
        setProdSelection(null)
        setShowProdModal(false)
    }

    useEffect(() => {
        const unsub = subscribe()
        fetchBoxes()
        fetchClasses()
        fetchMaterials()
        fetchMaterialGroups()
        return () => {
            unsub()
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (orderInfo.client) {
                try {
                    const res = await getClientContacts({
                        clientId: orderInfo.client.ID,
                    })

                    const { data } = res
                    if (data && data.getClientContacts && data.getClientContacts.length > 0) {
                        setContacts(data.getClientContacts)
                    }
                } catch (err) {
                    console.log('Error getting client contacts:', err)
                }
            }
        })()
    }, [orderInfo.client])

    const handleDuplicate = async () => {
        try {
            const { data } = await duplicateItem({
                variables: {
                    id: parseInt(orderId)
                },
            })
            if (data?.duplicateOrder !== 'Fail') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Tellimus kopeeritud. Teid suunatakse uue tellimuseni'),
                        type: 'success',
                    },
                })

                navigate(`/orders`)
                navigate(`/order/${data.duplicateOrder}`)
            } 
        } catch (err) {
            console.log('handleDuplicate', err)
        }
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='order'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ `${t('Hinnapakkumine')} ${orderInfo.ORDERNO}` }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Kopeeri hinnapakkumine')}
                            onClick={handleDuplicate}
                        />
                        <Button
                            label={t('Kustuta hinnapakkumine')}
                            onClick={() => setDeleteModal(true)}
                            className={'btn-cancel'}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('Väljatrükk')}
                            onClick={() => {
                                setPdfDocument('quotation')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('FIN')}
                            onClick={() => {
                                setPdfDocument('quotation-fin')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('ENG')}
                            onClick={() => {
                                setPdfDocument('quotation-eng')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('Margita')}
                            onClick={() => {
                                setPdfDocument('quotation-mark')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('Margita FIN')}
                            onClick={() => {
                                setPdfDocument('quotation-mark-fin')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('SWE')}
                            onClick={() => {
                                setPdfDocument('quotation-sv')
                                setShowPdfModal(true)
                            }}
                        />
                        <IconButton
                            icon={<PrintIcon />}
                            label={t('Margita SWE')}
                            onClick={() => {
                                setPdfDocument('quotation-mark-sv')
                                setShowPdfModal(true)
                            }}
                        />
                    </div>
                </div>
                <div className='order-info'>
                    <div className='order-info--left'>
                        <Input
                            label={`${t('Number')}`}
                            placeholder={t('Number')}
                            type={'number'}
                            value={orderInfo.ORDERNO}
                            onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                        />
                        <DateInput
                            label={t('Kuupäev')}
                            value={orderInfo.ODATE  && orderInfo.ODATE !== '0000-00-00' ? new Date(orderInfo.ODATE) : null}
                            onChange={(val) => setField('ODATE', parseDate(val))}
                        />
                        <ClientSearchInput
                            value={orderInfo.client}
                            onChange={handleClientChange}
                            defaultValue={orderInfo.client}
                        />
                        <SelectInput
                            label={`${t('Kontakt')}`}
                            value={orderInfo.contact}
                            options={contacts}
                            getOptionLabel={(option) => option.CONTACT}
                            getOptionValue={(option) => option.CONTACT}
                            onChange={handleContactChange}
                            defaultValue={orderInfo.contact}
                        />
                    </div>
                    <div className='order-info--right'>
                        <SelectInput
                            label={`${t('Koostaja')}`}
                            value={orderInfo.creator}
                            options={creators}
                            getOptionLabel={(option) => option.RNAME}
                            getOptionValue={(option) => option.RNAME}
                            onChange={handleCreatorChange}
                            defaultValue={orderInfo.creator}
                        />
                        <Textarea
                            label={`${t('Pakkumise tekst')}`}
                            value={orderInfo.TEXT}
                            onChange={(e) => setField('TEXT', e.target.value)}
                        />
                    </div>
                </div>
                <OrderItemsList
                    orderId={orderId}
                    onRowSelect={(row) => setCurrentRow(row)}
                    onAddRow={addOrderRow}
                    onDuplicateRow={duplicateRow}
                    onDeleteRow={deleteOrderRow}
                    initialRow={state && state.rowId ? state.rowId : undefined}
                    onSendProduction={() => {
                        setProdSelection('all')
                        setShowProdModal(true)
                    }}
                />
                {
                    classes && factories && boxes && materials && currentRow &&
                    
                    <BoxFields
                        initialMaterials={materials}
                        boxes={boxes}
                        classes={classes}
                        factories={factories}
                        materialGroups = {materialGroups}
                        prints={prints}
                        orderRowId={currentRow ? currentRow.ID : null}
                        clientId={orderInfo.client?.ID}
                        onProductionClick={() => {
                            setProdSelection(currentRow.ID)
                            setShowProdModal(true)
                        }}
                    />
                }
            </div>
            <Modal
                title={t('Tootmisesse')}
                show={showProdModal}
                close={handleCloseModal}
                className={'modal-production'}
              >
                <div className='modal-production--inner'>
                    {
                        prodSelection === 'all' ?
                        <h5>{ t('Kas soovite KOGU tellimust tootmisesse võtta?') }</h5>
                        :
                        <h5>{ t('Kas soovite karpi sellisel kujul tootmisesse võtta?') }</h5>
                    }
                    <Input
                        label={`${t('Sisestage kliendi tellimuse number')}:`}
                        value={prodOrderNumber}
                        onChange={(e) => setProdOrderNumber(e.target.value)}
                    />
                    <DateInput
                        label={`${t('Tarneaeg')}`}
                        value={prodDate}
                        onChange={(val) => setProdDate(val)}
                    />
                    <Checkbox
                        label={t('Buffer ladu')}
                        value={buffer}
                        onChange={(e) => setBuffer(e.target.checked)}
                    />
                    {
                        prodSelection === 'all' && data?.getOrder?.orderRows?.some(x => !x.FPRICE)
                        || data?.getOrder?.orderRows?.some(x => x.ID === prodSelection && !x.FPRICE) ?
                        <BoxNotification
                            type={'error'}
                            message={'Tootel puudub hind'}
                        />
                        :
                        <></>
                    }
                </div>
                <Button
                    label={t('Saada')}
                    onClick={submitProduction}
                />
            </Modal>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('Kustuta')}
                close={() => setDeleteModal(false)}
                onConfirm={handleDeleteOrder}
                title={t('Kas soovid tellimuse kustutada?')}
            >
            </ConfirmModal>
            <Modal
                show={showPdfModal}
                className={'make-order-modal'}
                close={handleClosePdfModal}
            >
                <PdfViewer document={pdfDocument} data={{
                        order: orderInfo,
                        // Order by amount and print
                        rows: (data.getOrder.orderRows.map(x => ({
                            ...x,
                            materialItem: {
                                MARK2: materials?.find(i => x.MATERIAL === i.MARK)?.MARK2,
                            },
                        })).sort((a, b) => (a.KOGUS - b.KOGUS))).sort((a, b) => a.TRYKK - b.TRYKK),
                    }} />
            </Modal>
        </div>
    )
}

export default OrderScreen