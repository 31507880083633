import { useMutation, useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateFactoryMaterialOrder } from '../graphql/mutations'
import { GetFactoryMaterialOrders } from '../graphql/queries'
import { formatDate, getUTCDate, parseDate } from '../util/format'
import DateInput from './DateInput'

const rowHeaders = [
    {
        value: 'factoryOrder.MAT_ORDER_NUMBER',
        label: 'Nr.',
    },
    {
        value: 'materialOrder.paperFactory.FACTORY_NAME',
        label: 'Tehas',
    },
    {
        value: 'factoryOrder.ARRIVAL',
        label: 'Saabumine',
    },
    {
        value: 'factoryOrder.COMPLETED',
        label: 'Kinnitus',
    },
    {
        value: 'factoryOrder.STATUS_CHANGE',
        label: 'Stat. muutus',
    },
    {
        value: 'factoryOrder.SHIPPED',
        label: 'Väljastatud',
    },
]

const FactoryMaterialOrderRows = ({ prodId, bufferId }) => {

    const { t } = useTranslation()

    const { refetch, data } = useQuery(GetFactoryMaterialOrders, {
        variables: {
            prodId: prodId ? parseInt(prodId) : undefined,
            bufferId: bufferId ? parseInt(bufferId) : undefined
        },
        fetchPolicy: 'no-cache',
    })

    const [updateRow] = useMutation(UpdateFactoryMaterialOrder)

    const getColumnValue = (row, field) => {
        switch(field) {
            case 'factoryOrder.MAT_ORDER_NUMBER':
                return row.factoryOrder?.MAT_ORDER_NUMBER
            case 'materialOrder.paperFactory.FACTORY_NAME':
                return row.materialOrder?.paperFactory?.FACTORY_NAME
            case 'factoryOrder.ARRIVAL':
                return (
                    <DateInput
                        placeholder={t('Saabumine')}
                        value={row.factoryOrder?.ARRIVAL ? new Date(row.factoryOrder?.ARRIVAL) : null}
                        onChange={(val) => handleUpdate({
                            id: row.ID,
                            arrival: getUTCDate(val),
                        })}
                    />
                )
            case 'factoryOrder.COMPLETED':
                return (
                    <DateInput
                        placeholder={t('Kinnitus')}
                        value={row.factoryOrder?.COMPLETED ? new Date(row.factoryOrder?.COMPLETED) : null}
                        onChange={(val) => handleUpdate({
                            id: row.ID,
                            completed: getUTCDate(val),
                        })}
                    />
                )
            case 'factoryOrder.STATUS_CHANGE':
                return formatDate(new Date(row.factoryOrder?.STATUS_CHANGE || null))
            case 'factoryOrder.SHIPPED':
                return formatDate(new Date(row.factoryOrder?.SHIPPED || null))
            default:
                return row[field]
        }
    }

    const handleUpdate = async ({ id, arrival, completed }) => {
        try {
            await updateRow({
                variables: {
                    id: parseInt(id),
                    arrival,
                    completed,
                },
            })
            refetch()
        } catch (err) {
            console.log('Error updating factory order')
        }
    }

    const handleRowClick = (id) => {
        window.open(`/material-order/${id}`)
    }

    return (
        <div className='factory-order-rows'>
            <h5>{ `${t('Materjalid')}` }</h5>
            <div className='factory-order-rows-list'>
                { data?.getFactoryMaterialOrders?.length > 0 ?
                    <table className='order-rows'>
                        <thead
                            className='order-rows--header'
                            style={{
                                gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                            }}
                        >
                            <tr>
                            {
                                rowHeaders.map((headerItem) =>
                                    <th key={`header-${headerItem.value}`} className='order-rows--header-item'>
                                        { t(headerItem.label) }
                                    </th>
                                )
                            }
                            </tr>
                        </thead>
                        <tbody className='order-rows--content'>
                            {
                                data.getFactoryMaterialOrders.map((row, index) =>
                                    <tr
                                        key={`order-row-${index}`}
                                        className={`order-row`}
                                        onDoubleClick={(e) => handleRowClick(row.ORDER_ID)}
                                        style={{
                                            gridTemplateColumns: `repeat(${rowHeaders.length}, 1fr)`
                                        }}
                                    >
                                        {
                                            rowHeaders.map((headerItem, itemIndex) =>
                                                <td
                                                    key={`order-row-${index}-${itemIndex}`}
                                                    className='order-row--item'
                                                >
                                                    { getColumnValue(row, headerItem.value) }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className='empty-result'>
                        { t('Materjalid hetkel puuduvad') }
                    </div>
                }
            </div>
        </div>
    )
}

export default FactoryMaterialOrderRows