import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { GetMachines } from '../graphql/queries'
import PlanningMachine from './PlanningMachine'
import Button from './Button'

const PlanningMachines = () => {

    const [machines, setMachines] = useState([])
    const [selectedMachine, setSelectedMachine] = useState(null)

    useQuery(GetMachines, {
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'ORDER_NO',
            sort: 'ASC',
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getMachines } = data

            if (!getMachines?.rows?.length) {
                setMachines([])
                return
            }

            setMachines(getMachines.rows)
            setSelectedMachine(getMachines.rows[0])
        }
    })

    const handleMachineClick = (machine) => {
        setSelectedMachine(machine)
    }

    return (
        <div className='planning-machines'>
            <div className='planning-machines--list'>
                {
                    machines.map((machine) =>
                        <Button
                            className={selectedMachine?.ID === machine.ID ? 'btn-active' : ''}
                            label={machine.LABEL}
                            key={`machine-${machine.ID}`}
                            onClick={() => handleMachineClick(machine)}
                        />
                    )
                }
            </div>
            <div className='planning-machines--selected'>
                {
                    selectedMachine ?
                        <PlanningMachine
                            data={selectedMachine}
                        />
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default PlanningMachines