import React from 'react'
import { Bounce } from 'react-activity'

const ActivityIndicator = () => {

    return (
        <Bounce color='#992825' />
    )
}

export default ActivityIndicator