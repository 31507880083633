import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetYearlyReport } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { chartColors } from '../../util/colors'

const years = []

for (let i = 2018; i <= new Date().getFullYear(); i++) {
    years.push({
        value: i,
        label: i,
    })
}

const types = [
    {
        label: 'Kõik',
        value: null,
    },
    {
        label: 'Eksport',
        value: 'exported',
    },
    {
        label: 'Siseturg',
        value: 'domestic',
    },
]

const initialResult = {
    labels: [],
    datasets: [],
}

const ReportYearScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(years[0])
    const [end, setEnd] = useState(years[years.length - 1])
    const [result, setResult] = useState(initialResult)
    const [shipped, setShipped] = useState(false)
    const [type, setType] = useState(types[0])

    const { refetch } = useQuery(GetYearlyReport, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            start: new Date(start.value, 0, 1),
            end: new Date(end.value, 11, 31),
            shipped,
            type: type.value,
        },
        onCompleted: (data) => {
            if (data?.getYearlyReport) {
                const years = data.getYearlyReport.reduce((acc, curr) => {
                    if (acc.some(x => x === curr.year)) return acc
                    acc.push(curr.year)
                    return acc
                }, [])
                const months = data.getYearlyReport.reduce((acc, curr) => {
                    if (acc.some(x => x === curr.month)) return acc
                    acc.push(curr.month)
                    return acc
                }, [])

                const dataset = months.map(x => {
                    const years = data.getYearlyReport.filter(i => i.month === x)
                    const item = {}
                    years.map(i => {
                        item[i.year] = i.value
                    })
                    return {
                        month: x,
                        years: item,
                    }
                })

                setResult({
                    labels: months,
                    datasets: years.map((x, index) => ({
                        label: x,
                        data: dataset,
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: `years.${x}`,
                        },
                        backgroundColor: chartColors[index],
                    })),
                })
            }
        },
    })

    useEffect(() => {
        (async () => {
            await refetch()
        })()
    }, [])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    useEffect(() => {
        refetch()
    }, [
        start,
        end,
        shipped,
        type,
    ])

    return (
        <div className='report-basic'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Realisatsioon') }</h2>
                    <SelectInput
                        label={t('Algus')}
                        options={years}
                        value={start}
                        onChange={setStart}
                    />
                    <SelectInput
                        label={t('Lõpp')}
                        options={years}
                        value={end}
                        onChange={setEnd}
                    />
                    <SelectInput
                        label={t('Turg')}
                        options={types}
                        value={type}
                        onChange={setType}
                    />
                    <Checkbox
                        label={'Väljastatud'}
                        value={shipped}
                        onChange={(e) => setShipped(e.target.checked ? true : false)}
                    />
                </div>

                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        plugins={[ChartDataLabels]}
                        data={result}
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value, context) {
                                        const label = value.years[context.dataset.label]
                                        return label ? Number(parseFloat(label).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default ReportYearScreen