import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useMutation } from '@apollo/client'
import { MaterialOrdersQuery } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CreateMaterialOrder } from '../../graphql/mutations'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import { formatDate } from '../../util/format'
import Modal from '../../components/Modal'
import PdfViewer from '../../components/PdfViewer'
import { MaterialCreated, MaterialsUpdated } from '../../graphql/subscriptions'

const includeFields = [
    {
        value: 'ORDER_NUMBER',
        label: 'Tellimus',
    },
    {
        value: 'CREATED_DATE',
        label: 'Koostatud',
    },
    {
        value: 'REMARK',
        label: 'Märkus',
    },
    {
        value: 'creator.RNAME',
        label: 'Koostaja',
    },
    {
        value: 'paperFactory.FACTORY_NAME',
        label: 'Tehas',
    },
    {
        value: 'ORDERED',
        label: 'Tellitud',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.materialsUpdated
    if (!newData || !prev.materialOrdersQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    const itemRows = prev.materialOrdersQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })

    return Object.assign({}, prev, {
        materialOrdersQuery: {
            ...prev.materialOrdersQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.materialCreated
    if (!newData || prev.materialOrdersQuery?.rows?.some(x => x.ID === newData.ID)) return prev
    
    return Object.assign({}, prev, {
        materialOrdersQuery: {
            ...prev.materialOrdersQuery,
            rows: [
                newData,
                ...prev.materialOrdersQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: MaterialsUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: MaterialCreated,
        updateQuery: handleCreateSubscription,
    },
]

const MaterialOrdersScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const navigate = useNavigate()
    const [createMaterialOrder] = useMutation(CreateMaterialOrder)
    const [selectedRow, setSelectedRow] = useState()
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [pdfDocument, setPdfDocument] = useState(null)

    const openOrder = (id) => {
        navigate(`/material-order/${id}`)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'CREATED_DATE':
                return formatDate(new Date(row[field]))
            case 'creator.RNAME':
                return row?.creator?.RNAME
            case 'paperFactory.FACTORY_NAME':
                return row?.paperFactory?.FACTORY_NAME
        }
    }

    const createOrder = async () => {
        try {
            const { data } = await createMaterialOrder()

            if (data && data.createMaterialOrder) {
                openOrder(data.createMaterialOrder)
            }
        } catch (err) {
            console.log('Error creating material order', err)
        }
    }

    const handleRowClick = (item) => {
        setSelectedRow(item)
    }

    const handleRowDoubleClick = (item) => {
        window.open(`/material-order/${item.ID}`)
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfDocument(null)
    }

    const handleChange = () => {
        if (!selectedRow) return
        setSelectedRow(null)
    }

    return (
        <div className='material-orders'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Materjali tellimused') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('Uus tellimus')}
                            onClick={createOrder}
                        />
                        {
                            selectedRow ?
                            <>
                                <Button
                                    label={t('ENG tellimus')}
                                    onClick={() => {
                                        setPdfDocument('material-eng')
                                        setShowPdfModal(true)
                                    }}
                                />
                                <Button
                                    label={t('RUS tellimus')}
                                    onClick={() => {
                                        setPdfDocument('material-rus')
                                        setShowPdfModal(true)
                                    }}
                                />
                                <Button
                                    label={t('Sisemine dokument')}
                                    onClick={() => {
                                        setPdfDocument('material-internal')
                                        setShowPdfModal(true)
                                    }}
                                />
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={MaterialOrdersQuery}
                        queryKey={'materialOrdersQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'CREATED_DATE'}
                        statusColorEnabled={true}
                        activeRows={selectedRow ? [selectedRow] : []}
                        onRowClick={handleRowClick}
                        onFiltersChanged={handleChange}
                        onRowDoubleClick={handleRowDoubleClick}
                        rowClass={(row) => row.ORDERED === 1 ? 'material-order--ordered' : null}
                        subscriptions={subscriptions}
                    />
                </div>
            </div>

            <Modal
                show={showPdfModal}
                className={'make-order-modal'}
                close={handleClosePdfModal}
            >
                <PdfViewer document={pdfDocument} data={selectedRow} />
            </Modal>
        </div>
    )
}

export default MaterialOrdersScreen