import React from 'react'
import ExclamationIcon from './icons/exclamation'

const BoxNotification = ({
    message,
    type,
}) => (
    <>
        {
            message ?
            <div className={`box-notification${type ? ` box-notification--${type}` : 'box-notification--info'}`}>
                <ExclamationIcon />
                <span>{ message }</span>
            </div>
            :
            <></>
        }
    </>
)

export default BoxNotification