import React, { useEffect, useState } from 'react'
import SelectInput from './SelectInput'
import CreatableInputEdit from './CreatableInputEdit'
import Textarea from './Textarea'
import { TransportOptions } from '../util/const'
import { useTranslation } from 'react-i18next'

const ClientTransport = ({
    data,
    onChange,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(data)

    useEffect(() => {
        if (item?.client?.ID === data?.client?.ID) return

        setItem({
            ...data,
            TRANSPORDIFIRMA: '',
            ADDRESS: '',
        })
    }, [data])

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        if (onChange) onChange(field, value)
    }

    return (
        <div className='client-transport'>
            <CreatableInputEdit
                label={`${t('Transport')}`}
                value={item?.TRANSPORDIFIRMA ? {
                    label: item.TRANSPORDIFIRMA,
                    value: item.TRANSPORDIFIRMA,
                } : null}
                options={TransportOptions}
                noOptionsMessage={() => t('Lisa uus transport')}
                onChange={(option) => setField('TRANSPORDIFIRMA', option.value)}
                placeholder={'Vali või sisesta'}
            />
            {
                item?.client?.transports?.length > 0 && item.client.transports[0].TRANSPORT ?
                    <SelectInput
                        label={t('Aadressid')}
                        value={item.client.transports.find(x => x.TRANSPORT === data.ADDRESS) || null}
                        options={item.client.transports}
                        getOptionLabel={(option) => option.TRANSPORT}
                        getOptionValue={(option) => option.TRANSPORT}
                        onChange={(option) => setField('ADDRESS', option.TRANSPORT)}
                    />
                    :
                    <></>
            }
            <Textarea
                label={t('Aadress')}
                value={item?.ADDRESS}
                onChange={(e) => setField('ADDRESS', e.target.value)}
            />
        </div>
    )
}

export default ClientTransport