import React, { useEffect, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetDeliveryNoteWithRows, GetProduction } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateInput from '../../components/DateInput'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Textarea from '../../components/Textarea'
import BoxWorkflow from '../../components/BoxWorkflow'
import { CreateDeliveryNote, CreateDeliveryNoteRow, SendToBookkeeping, UpdateProductionItem } from '../../graphql/mutations'
import ActivityIndicator from '../../components/ActivityIndicator'
import { formatDate, parseDate } from '../../util/format'
import MaterialDeliveryRows from '../../components/MaterialDeliveryRows'
import Printouts from '../../components/Printouts'
import DeliveryNotesList from '../../components/DeliveryNotesList'
import FactoryMaterialOrderRows from '../../components/FactoryMaterialOrderRows'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { useNotification } from '../../providers/Notification'
import PdfViewer from '../../components/PdfViewer'
import DeliveryNoteInvoiceDocument from '../../documents/deliverynote-invoice-document'
import { pdf } from '@react-pdf/renderer'
import { ProductionUpdated } from '../../graphql/subscriptions'
import TechnicalCard from '../../components/TechnicalCard'
import { useConstants } from '../../providers/Constants'

const ProductionScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const { dispatch } = useNotification()
    const { constants } = useConstants()
    const navigate = useNavigate()

    const [prodData, setProdData] = useState(null)
    const [modifiedFields, setModifiedFields] = useState([])
    const [noteCreated, setNoteCreated] = useState(null)
    const [notePallets, setNotePallets] = useState(1)
    const [notePaidPallets, setNotePaidPallets] = useState(false)
    const [noteDate, setNoteDate] = useState(new Date())
    const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false)
    const [showPdfModal, setShowPdfModal] = useState(false)

    const { loading, subscribeToMore } = useQuery(GetProduction, {
        variables: {
          id: parseInt(id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getProduction } = data
            if (getProduction) {
                setProdData(getProduction)
            }
        },
    })

    useEffect(() => {
        const unsub = subscribeToMore({
            document: ProductionUpdated,
            variables: {
                id: parseInt(id),
            },
            updateQuery: (prev, { subscriptionData }) => {
                const newData = subscriptionData?.data?.productionUpdated
                if (!newData) return

                setProdData(newData)
            },
        })

        return () => unsub()
    }, [])

    const { refetch: getNote } = useQuery(GetDeliveryNoteWithRows, {
        skip: true,
    })
    
    const [updateProductionItem] = useMutation(UpdateProductionItem)
    const [createNote] = useMutation(CreateDeliveryNote)
    const [createNoteRow] = useMutation(CreateDeliveryNoteRow)
    const [sendToBookkeeping] = useMutation(SendToBookkeeping)

    const setField = (field, value) => {
        setProdData({
            ...prodData,
            [field]: value,
        })
        
        if (!modifiedFields.includes(field)) { // add field to modifiedfields array if not present
            setModifiedFields([...modifiedFields,field])
        }
    }

    const updateProperty = async () => {
        if (modifiedFields.length === 0) {
            return
        }
        const changedData = {}
        modifiedFields.forEach(field => {
            changedData[field] = prodData[field]
        })
        try {
            const { data } = await updateProductionItem({
                variables: {
                    id: parseInt(id),  
                    data: changedData,  
                },
            })

            if (data?.updateProductionItem === 'Success') {
                setModifiedFields([])
            } 
            return
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (modifiedFields.length > 0) {
                event.preventDefault()
                event.returnValue = '' 
            }
        }
    
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [modifiedFields])


    const getMaterialAmount = () => {
        if (prodData && prodData.materialOrders) {
            const total = prodData.materialOrders.reduce((acc, curr) => {
                if (curr.AMOUNT) acc += curr.AMOUNT
                return acc
            }, 0)
            return total ? total : ''
        }
        return ''
    }

    const getMaterialDeliveryAmount = () => {
        if (prodData && prodData.materialDeliveries) {
            const total = prodData.materialDeliveries.reduce((acc, curr) => {
                if (curr.AMOUNT) acc += curr.AMOUNT
                return acc
            }, 0)
            return total ? total : ''
        }
        return ''
    }

    const getMaterialDelivery = () => {
        if (prodData && prodData.materialOrders) {
            const minDate = prodData.materialOrders.reduce((acc, curr) => {
                const prevDate = new Date(acc.DELIVERY_DATE)
                const currDate = new Date(curr.DELIVERY_DATE)
                if (prevDate < currDate) return prevDate
                return currDate
            }, '')

            return formatDate(minDate)
        }
        return ''
    }

    const getMaterialSize = () => {
        if (prodData && prodData.materialOrders && prodData.materialOrders[0]) {
            return prodData.materialOrders[0].SIZE
        }
        return ''
    }

    const getMaterialNuut = () => {
        if (prodData && prodData.materialOrders && prodData.materialOrders[0]) {
            return prodData.materialOrders[0].NUUT
        }
        return ''
    }

    const handleDeliveryNote = async () => {
        try {
            const { data: noteData } = await createNote({
                variables: {
                    data: {
                        PALLETS: parseInt(notePallets),
                        PALLET_COSTS: notePaidPallets ? 1 : 0,
                        CREATED_DATE: noteDate,
                        CLIENT_ID: parseInt(prodData?.client?.ID),
                    },
                },
            })

            if (noteData?.createDeliveryNote) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: `${t('Saateleht lisatud')} - ${noteData.createDeliveryNote}`
                    },
                })

                const noteId = noteData.createDeliveryNote

                await createNoteRow({
                    variables: {
                        data: {
                            INVOICE_ID: parseInt(noteId),
                            PRODUCT_ID: parseInt(prodData.ID),
                            AMOUNT: parseInt(prodData.PARTIAL_AMOUNT),
                        },
                    },
                })

                const note = await getNote({
                    id: parseInt(noteId),
                })

                const createdNote = note?.data?.getDeliveryNoteWithRows

                if (createdNote) {
                    setNoteCreated({
                        ...createdNote,
                        PALLETPRICE: constants?.PALLETPRICE,
                    })
                    return
                }
            }
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: t('Saatelehe lisamine ebaõnnestus')
                },
            })
        } catch (err) {
            console.log('handleDeliveryNote:', err)
        }
    }

    const closeDeliveryNoteModal = () => {
        setShowDeliveryNoteModal(false)
    }

    const resetNoteState = () => {
        setNoteCreated(null)
        setNotePaidPallets(false)
        setNotePallets(1)
        setNoteDate(new Date())
    }

    const renderModalActions = () => {
        if (noteCreated) return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Ei')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Ainult prindi')}
                    onClick={async () => {
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
                <Button
                    label={t('Jah')}
                    onClick={async () => {
                        await handleBookkeeping()
                        setShowDeliveryNoteModal(false)
                        setShowPdfModal(true)
                    }}
                />
            </div>
        )
        return (
            <div className='modal-actions'>
                <Button
                    className={'btn-cancel'}
                    label={t('Katkesta')}
                    onClick={() => {
                        resetNoteState()
                        closeDeliveryNoteModal()
                    }}
                />
                <Button
                    label={t('Loo saateleht')}
                    onClick={handleDeliveryNote}
                />
            </div>
        )
    }

    const handleBookkeeping = async () => {
        try {
            const pdfFile = pdf(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            pdfFile.updateContainer(<DeliveryNoteInvoiceDocument data={noteCreated} />)
            const blob = await pdfFile.toBlob()
            const file = new File([blob], 'file.pdf', { type: 'application/pdf'})

            const { data } = await sendToBookkeeping({
                variables: {
                    id: parseInt(noteCreated.ID),
                    file,
                },
            })

            if (data?.sendToBookkeeping === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        content: t('Edastatud raamatupidamisse'),
                        type: 'success',
                    },
                })
                return
            } 

            dispatch({
                type: 'ADD',
                payload: {
                    content: t(`Viga raamatupidamisse saatmisel: ${data?.sendToBookkeeping}`),
                    type: 'error',
                },
            })
        } catch (err) {
            console.log('handleBookkeeping:', err)
            dispatch({
                type: 'ADD',
                payload: {
                    content: err,
                    type: 'error',
                },
            })
        }
    }

    const handlePrevNext = async (direction) => {
        try {
            const rows = JSON.parse(localStorage.getItem('productions'))
            const currentIndex = rows.findIndex(x => x.ID === prodData.ID)

            if (direction === 'prev' && currentIndex <= 0) return
            if (direction === 'next' && currentIndex >= rows.length - 1) return

            const id = direction === 'next' ? rows[currentIndex + 1]?.ID : rows[currentIndex - 1]?.ID
            navigate(`/production/${id}`)
        } catch (err) {
            console.log('HandlePrevNext', err)
        }
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='production'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{ t('Tootmine') }</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('<')}
                            onClick={() => handlePrevNext('prev')}
                        />
                        <Button
                            label={t('>')}
                            onClick={() => handlePrevNext('next')}
                        />
                        <Button
                            label={t('Saateleht')}
                            onClick={async () => {
                                updateProperty()
                                resetNoteState()
                                setShowDeliveryNoteModal(true)
                                setNotePallets(prodData.ALUSEID ? prodData.ALUSEID : 1)
                            }}
                        />
                        <TechnicalCard
                            orderRowId={prodData?.ORDER_ROW_ID}
                            productionCode={prodData?.PRODUCTION_CODE}
                            production={prodData}
                        />
                        <Button
                            label={t('Salvesta')}
                            onClick={() => updateProperty()}
                            disabled={modifiedFields.length === 0 || loading}
                            loading={loading}
                        />

                    </div>
                </div>
                { prodData ?
                    <div className='production-fields'>
                        <div className='editable-fields'>
                            <div className='field-row'>
                                <div className='field-col production-fields--info'>
                                    <DateInput
                                        label={t('Reaalne tähtaeg')}
                                        value={prodData.NEW_DEADLINE && prodData.NEW_DEADLINE !== '0000-00-00' ? new Date(prodData.NEW_DEADLINE) : null}
                                        onChange={(val) => setField('NEW_DEADLINE', val)}
                                    />
                                    <DateInput
                                        label={t('Tootmise algus')}
                                        value={prodData.START_DATE && prodData.START_DATE !== '0000-00-00' ? new Date(prodData.START_DATE) : null}
                                        onChange={(val) => setField('START_DATE', val)}
                                    />
                                    <DateInput
                                        label={t('Tootmise lõpp')}
                                        value={prodData.FIN_DATE && prodData.FIN_DATE !== '0000-00-00' ? new Date(prodData.FIN_DATE) : null}
                                        onChange={(val) => setField('FIN_DATE', val)}
                                    />
                                    <DateInput
                                        label={t('Väljastatud')}
                                        value={prodData.SHIPPED  && prodData.SHIPPED !== '0000-00-00' ? new Date(prodData.SHIPPED) : null}
                                        onChange={(val) => setField('SHIPPED', val)}
                                    />
                                    <Input
                                        label={t('Aluseid')}
                                        type={'text'}
                                        value={prodData.ALUSEID}
                                        onChange={(e) => setField('ALUSEID', e.target.value)}
                                    />
                                    <Input
                                        label={t('Aluse mõõt')}
                                        type={'text'}
                                        value={prodData.ALUS_MOOT}
                                        onChange={(e) => setField('ALUS_MOOT', e.target.value)}
                                    />
                                    <Input
                                        label={t('Tegelik kogus')}
                                        type={'number'}
                                        value={prodData.RKOGUS}
                                        onChange={(e) => setField('RKOGUS', parseInt(e.target.value))}
                                    />
                                    <Input
                                        label={t('Väljastamiseks')}
                                        type={'number'}
                                        value={prodData.PARTIAL_AMOUNT}
                                        onChange={(e) => setField('PARTIAL_AMOUNT', parseInt(e.target.value))}
                                    />    
                                </div>
                                <div className='field-col production-fields--comment'>
                                    <Textarea
                                        label={t('Kommentaar')}
                                        value={prodData.PROD_COMMENT}
                                        onChange={(e) => setField('PROD_COMMENT', e.target.value)}
                                    />
                                    <Checkbox
                                        label={t('Laomaterjal')}
                                        value={prodData.STOCK_MAT === 1}
                                        onChange={(e) => setField('STOCK_MAT', e.target.checked ? 1 : 0)}
                                    />
                                    <Checkbox
                                        label={t('Mat. laaditud')}
                                        value={prodData.MAT_LOADED === 1}
                                        onChange={(e) => setField('MAT_LOADED', e.target.checked ? 1 : 0)}
                                    />
                                    <Checkbox
                                        label={t('Offset')}
                                        value={prodData.OFSET === 1}
                                        onChange={(e) => setField('OFSET', e.target.checked ? 1 : 0)}
                                    />
                                    <Input
                                        className={'measurement-item'}
                                        label={t('Mõõt')}
                                        type={'text'}
                                        value={prodData.STOCK_MOOT}
                                        onChange={(e) => setField('STOCK_MOOT', e.target.value)}
                                    />
                                    <Checkbox
                                        label={t('Eksporditud')}
                                        value={prodData.EXPORTED === 1}
                                        onChange={(e) => setField('EXPORTED', e.target.checked ? 1 : 0)}
                                    />
                                </div>
                                <div className='field-col production-fields--tables'>
                                    <div className='production-fields--printout'>
                                        <Printouts
                                            production={prodData}
                                            onUpdateField={updateProperty}
                                        />
                                    </div>
                                    <div className='production-fields--delivery-notes'>
                                        <h5>{ t('Saatelehed') }</h5>
                                        <DeliveryNotesList
                                            prodId={id}
                                        />
                                    </div>
                                    <div className='factory-materials'>
                                        <FactoryMaterialOrderRows
                                            prodId={id}
                                        />
                                    </div>
                                    <div className='material-delivery'>
                                        <MaterialDeliveryRows
                                            prodId={id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='field-row box-row'>
                            <div className='field-col'>
                                <h5>{ t('Karp') }</h5>
                                <div className='box-fields'>
                                    <div className='field-row'>
                                        <div className='field-col'>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Kogus') }</span>
                                                <span className='box-fields-item--value'>{ prodData.KOGUS }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('LxB kogus') }</span>
                                                <span className='box-fields-item--value'>{ getMaterialAmount() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Saabunud mat.') }</span>
                                                <span className='box-fields-item--value'>{ getMaterialDeliveryAmount() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'><strong>{ t('Vahe') }</strong></span>
                                                <span className='box-fields-item--value'>{ getMaterialAmount() - getMaterialDeliveryAmount() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Tüüp') }</span>
                                                <span className='box-fields-item--value'>{ prodData.BOX }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Trükk') }</span>
                                                <span className='box-fields-item--value'>{ prodData.TRYKK }</span>
                                            </div>
                                        </div>
                                        <div className='field-col'>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Materjal') }</span>
                                                <span className='box-fields-item--value'>{ prodData.MATERIAL }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('FSC') }</span>
                                                <span className='box-fields-item--value'>{ prodData.FSC }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Mõõt') }</span>
                                                <span className='box-fields-item--value'>{ prodData.MOOT }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Materjali suurus') }</span>
                                                <span className='box-fields-item--value'>{ getMaterialSize() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Nuudid') }</span>
                                                <span className='box-fields-item--value'>{ getMaterialNuut() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Materjali tarneaeg') }</span>
                                                <span className='box-fields-item--value'>{ getMaterialDelivery() }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Mat. tellimuse ridu') }</span>
                                                <span className='box-fields-item--value'>{ prodData.materialOrders && prodData.materialOrders.length }</span>
                                            </div>
                                            <div className='box-fields-item'>
                                                <span className='box-fields-item--label'>{ t('Toote kood') }</span>
                                                <span className='box-fields-item--value'>{ prodData.PRODUCTION_CODE }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='field-col'>
                                <div className='client-fields'>
                                    <h5>{ t('Klient') }</h5>
                                    <div className='field-row'>
                                        <div className='field-col'>
                                            <div className='client-fields-item'>
                                                <span className='client-fields-item--label'>{ `${t('Nimi')}:` }</span>
                                                <span className='client-fields-item--value'>{ prodData.client && prodData.client.NAME }</span>
                                            </div>
                                            <div className='client-fields-item'>
                                                <span className='client-fields-item--label'>{ `${t('Tellimuse number')}:` }</span>
                                                <span className='client-fields-item--value'>{ prodData.KLIENT_ORDER }</span>
                                            </div>
                                        </div>
                                        <div className='field-col'>
                                            <div className='sales-fields-item'>
                                                <span className='sales-fields-item--label'>{ t('Kommentaar') }</span>
                                                <span className='sales-fields-item--value'>{ prodData.KOMMENTAAR }</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sales-fields'>
                                        <h5>{ t('Müük') }</h5>
                                        <div className='field-row'>
                                            <div className='field-col'>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Number') }</span>
                                                    <span className='sales-fields-item--value'>{ prodData.ORDERNO }</span>
                                                </div>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Tellimuse kuupäev') }</span>
                                                    <span className='sales-fields-item--value'>{ formatDate(new Date(prodData.ORDER_DATE)) }</span>
                                                </div>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Tähtaeg') }</span>
                                                    <span className='sales-fields-item--value'>{ prodData.DEADLINE ? formatDate(new Date(prodData.DEADLINE)) : formatDate(new Date(prodData.DEADLINE)) }</span>
                                                </div>    
                                            </div>
                                            <div className='field-col'>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Stants') }</span>
                                                    <span className='sales-fields-item--value'>{ prodData.STANTS }</span>
                                                </div>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Klishee') }</span>
                                                    <span className='sales-fields-item--value'>{ prodData.KLISHEE }</span>
                                                </div>
                                                <div className='sales-fields-item'>
                                                    <span className='sales-fields-item--label'>{ t('Transport') }</span>
                                                    <span className='sales-fields-item--value'>{ prodData.TRANSPORT }</span>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{ t('Production not found') }</p>
                    </div>
                }
            </div>
            <Modal
                show={showDeliveryNoteModal}
                close={() => {
                    resetNoteState()
                    closeDeliveryNoteModal()
                }}
                renderActions={renderModalActions}
                className={'modal--create-deliverynote'}
            >
                {
                    !noteCreated ?
                    <div className='modal--create-deliverynote--content'>
                        <DateInput
                            label={t('Kuupäev')}
                            onChange={(val) => setNoteDate(new Date(val))}
                            value={noteDate}
                        />
                        <Input
                            label={t('Aluseid')}
                            value={notePallets}
                            onChange={(e) => setNotePallets(e.target.value)}
                            type={'number'}
                        />
                        <Checkbox
                            label={t('Tasulised alused')}
                            value={notePaidPallets}
                            onChange={(e) => setNotePaidPallets(e.target.checked)}
                        />
                    </div>
                    :
                    <p>
                        { t('Saateleht on tekitatud. Kas soovite seda välja trükkida ja raamatupidamisse saata?') }
                    </p>
                }
            </Modal>
            <Modal
                show={showPdfModal}
                className={'delivery-note-pdf-modal'}
                close={() => setShowPdfModal(false)}
            >
                {
                    noteCreated && noteCreated.ID &&
                    <div className='flex justify-content--space-between'>
                        <PdfViewer document={'delivery-note'} data={noteCreated} />
                        <PdfViewer document={'delivery-note-invoice'} data={noteCreated} />
                    </div>

                }
            </Modal>
        </div>
    )
}

export default ProductionScreen