import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import DateInput from './DateInput'
import Textarea from './Textarea'
import BlueprintModal from './BlueprintModal'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Checkbox from './Checkbox'
import { CreatePlan, RemovePlan, UpdatePlan } from '../graphql/mutations'
import { GetLatestPlanByMachineAndDate } from '../graphql/queries'
import { useTranslation } from 'react-i18next'
import PlanningStatus from './PlanningStatus'
import { useNotification } from '../providers/Notification'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

const InitialFields = {
    PLANNED_STARTDATE: '',
    LOADED: false,
    COMMENT: '',
}

const PlanningItemModal = ({
    planningItem,
    machine,
    show,
    close,
    onChange,
}) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [fields, setFields] = useState(InitialFields)
    const [removePlan] = useMutation(RemovePlan)
    const [savePlan] = useMutation(CreatePlan)
    const [updatePlan] = useMutation(UpdatePlan)
    const [loading, setLoading] = useState(false)
    const [latestPlanData, setLatestPlanData] = useState(null)

    const outputPerHour = parseInt(machine?.PERFORMANCE)
    const minutesPerUnit = 60 / outputPerHour

    useEffect(() => {
        setFields({
            ...fields,
            PLANNED_STARTDATE: planningItem?.PLANNED_STARTDATE,
            COMMENT: planningItem?.COMMENT,
            LOADED: planningItem?.LOADED,
        })

        return () => {
            setFields(InitialFields)
        }
    }, [planningItem])

    const setField = (field, value) => {
        setFields({ ...fields, [field]: value })
    }

    const { data, refetch } = useQuery(GetLatestPlanByMachineAndDate, {
        variables: {
            machineId: machine?.ID,
            date: fields.PLANNED_STARTDATE ? new Date(fields.PLANNED_STARTDATE) : undefined,
        },
        skip: !machine?.ID || !fields.PLANNED_STARTDATE,
    })

    const handleDateChange = async (newDate) => {
        setFields({ ...fields, PLANNED_STARTDATE: newDate })
        const { data } = await refetch({
            machineId: machine?.ID,
            date: newDate,
        })
        if (data && data.getLatestPlanByMachineAndDate && data.getLatestPlanByMachineAndDate.ID) {
            setLatestPlanData(data.getLatestPlanByMachineAndDate)
        } else {
            setLatestPlanData(null)
        }
    }

    const handleRemovePlan = async () => {
        const { data } = await removePlan({
            variables: {
                id: parseInt(planningItem.PLANNING_ID, 10),
            },
        })
        if (!data || data.removePlan?.status !== 200) {
            handleErrors(data.removePlan)
            return
        } 

        if (onChange) onChange()
        if (close) close()
    }

    const handleSavePlan = async () => {
        setLoading(true)

        try {
            const latestPlan = latestPlanData
            let plannedStartDate

            if (latestPlan && latestPlan.PLANNED_ENDDATE) {
                plannedStartDate = new Date(latestPlan.PLANNED_ENDDATE)
            } else {
                plannedStartDate = new Date(fields.PLANNED_STARTDATE)
            }

            const plannedEndDate = new Date(plannedStartDate.getTime() + (planningItem.KOGUS * minutesPerUnit * 60 * 1000))

            const updatedFields = {
                PLANNED_STARTDATE: DateTime.fromJSDate(plannedStartDate).toUTC(),
                PLANNED_ENDDATE: DateTime.fromJSDate(plannedEndDate).toUTC(),
                LOADED: fields.LOADED,
                COMMENT: fields.COMMENT,
            }
            const { data } = planningItem?.PLANNING_ID ?
                await updatePlan({
                    variables: {
                        id: parseInt(planningItem.PLANNING_ID, 10),
                        data: {
                                ...updatedFields,
                                MACHINE_ID: machine.ID,
                                PROD_ID: planningItem?.ISBUFFER ? undefined : planningItem.ID,
                                BUFFER_ID: planningItem?.ISBUFFER ? planningItem.ID : undefined,
                                PRODUCTION_CODE: planningItem?.PRODUCTION_CODE ? planningItem.PRODUCTION_CODE : undefined,
                            },
                        date: updatedFields.PLANNED_STARTDATE ? updatedFields.PLANNED_STARTDATE : undefined,
                        },
                }) :
                await savePlan({
                    variables: {
                        data: {
                            ...updatedFields,
                            MACHINE_ID: machine.ID,
                            PROD_ID: planningItem?.ISBUFFER ? undefined : planningItem.ID,
                            BUFFER_ID: planningItem?.ISBUFFER ? planningItem.ID : undefined,
                            PRODUCTION_CODE: planningItem?.PRODUCTION_CODE ? planningItem.PRODUCTION_CODE : undefined,
                        },
                        date: updatedFields.PLANNED_STARTDATE ? updatedFields.PLANNED_STARTDATE : undefined,
                    },
                })
                if (
                    (data?.createPlan?.status && data.createPlan.status !== 200) || 
                    (data?.updatePlan?.status && data.updatePlan.status !== 200)
                ) {
                    if (data?.createPlan?.error) {
                        handleErrors(data.createPlan)
                    }
                    else if (data?.updatePlan?.error) {
                        handleErrors(data.updatePlan)
                    }
                    return
                }
            if (onChange) onChange()
            if (close) close()
        } catch (e) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: 'Salvestamine ebaõnnestus. Kontrolli väljasid'
                },
            })
        } finally {
            setLoading(false)
        }
    }

    const handleErrors = (response) => {
        let errorMessage = null
        const error = response.error
        switch (error) {
            case 'INVALID_PLAN_START_DATE':
                errorMessage = `Plaan ei saa olla varasem eelmise masina planeeritud lõpukuupäevast ${response.data}`
                break
            case 'MACHINE_NOT_FIRST_IN_ORDER':
                const parsedData = JSON.parse(response.data)
                errorMessage = `Selle toote planeerimine algab masinast ${parsedData.label} (${parsedData.code})`
                break
            case 'MOVING_COMPLETED_PLAN_TO_UNPLANNED':
                errorMessage = `Lõppenud plaani ei saa planeerimata alasse liigutada`
                break
            case 'CANNOT_REMOVE_COMPLETED_PLAN':
                errorMessage = `Lõppenud plaani ei saa eemaldada`
                break
            default:
                break
        }
        dispatch({
            type: 'ADD',
            payload: {
                type: 'error',
                content: errorMessage,
            },
        })
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                {
                    planningItem?.PLANNING_ID ?
                        <Button
                            label={'Eemalda'}
                            className={'btn-cancel'}
                            onClick={handleRemovePlan}
                        />
                        :
                        <></>
                }
                <Button
                    label={'Salvesta'}
                    onClick={handleSavePlan}
                    loading={loading}
                />
            </div>
        )
    }

    return (
        <Modal
            show={show}
            close={close}
            renderActions={renderModalActions}
            className={'planning-item-modal'}
        >
            <PlanningStatus
                planId={planningItem?.PLANNING_ID}
                productionCode={planningItem?.PRODUCTION_CODE}
            />
            <DateInput
                label={t('Kuupäev')}
                onChange={handleDateChange}
                dateFormat='dd.MM.yyyy HH:mm'
                value={fields?.PLANNED_STARTDATE ? new Date(fields.PLANNED_STARTDATE) : undefined}
                showTimeSelect
            />
            <Textarea
                label={t('Kommentaar')}
                onChange={(e) => setField('COMMENT', e.target.value)}
                value={fields.COMMENT}
            />
            <Checkbox
                label={'Laetud'}
                onChange={(e) => setField('LOADED', e.target.checked)}
                value={fields?.LOADED}
            />
            {
                planningItem?.ORDER_ROW_ID && show ?
                    <BlueprintModal
                        orderRowId={planningItem?.ORDER_ROW_ID}
                        productionCode={planningItem?.PRODUCTION_CODE}
                        production={planningItem ? {
                            ...planningItem,
                            materialOrders: [{
                                MARK: planningItem?.MATERIAL,
                            }]
                        } : undefined}
                    />
                    :
                    <></>
            }
        </Modal>
    )
}

export default PlanningItemModal