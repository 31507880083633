import React, { useRef, useState } from 'react'
import './style.scss'
import { FactoryOrdersQuery } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import { formatDate } from '../../util/format'
import { MaterialCreated, MaterialsUpdated } from '../../graphql/subscriptions'
import MaterialDeliveryModal from '../../components/MaterialDeliveryModal'
import MaterialDeliveryFilters from '../../components/MaterialDeliveryFilters'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Tellimuse nr',
    },
    {
        value: 'ORDER_NUMBER',
        label: 'Mat. tellimus',
    },
    {
        value: 'NUUT',
        label: 'Nuudid',
    },
    {
        value: 'MARK',
        label: 'Mark',
    },
    {
        value: 'ARRIVAL',
        label: 'Tellimuse kuupäev',
    },
    {
        value: 'COMPLETED',
        label: 'Kinnitus',
    },
    {
        value: 'SIZE',
        label: 'LxB',
    },
    {
        value: 'MOOT',
        label: 'Pakendi mõõt',
    },
    {
        value: 'AMOUNT',
        label: 'Kogus',
    },
    {
        value: 'DELIVERED_AMOUNT',
        label: 'Saabunud',
    },
    {
        value: 'difference',
        label: 'Vahe',
    },
    {
        value: 'CLIENT',
        label: 'Klient',
    },
    {
        value: 'factory',
        label: 'Tehas',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.materialsUpdated
    if (!newData || !prev.materialOrdersQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    const itemRows = prev.materialOrdersQuery.rows.map(x => {
        if (x.ID !== newData.ID) return x
        return newData
    })

    return Object.assign({}, prev, {
        materialOrdersQuery: {
            ...prev.materialOrdersQuery,
            rows: itemRows,
        },
    })
}

const handleCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.materialCreated
    if (!newData || prev.materialOrdersQuery?.rows?.some(x => x.ID === newData.ID)) return prev

    return Object.assign({}, prev, {
        materialOrdersQuery: {
            ...prev.materialOrdersQuery,
            rows: [
                newData,
                ...prev.materialOrdersQuery.rows,
            ],
        },
    })
}

const subscriptions = [
    {
        document: MaterialsUpdated,
        updateQuery: handleUpdateSubscription,
    },
    {
        document: MaterialCreated,
        updateQuery: handleCreateSubscription,
    },
]

const MaterialDeliveriesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()
    const [showDeliveryModal, setShowDeliveryModal] = useState(false)
    const [extraFilters, setExtraFilters] = useState()

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'ORDERNO':
                return row?.production?.ORDERNO || row?.buffer?.ORDERNO
            case 'ARRIVAL':
                return formatDate(new Date(row?.factoryOrder?.ARRIVAL))
            case 'COMPLETED':
                return formatDate(new Date(row?.factoryOrder?.COMPLETED))
            case 'factory':
                return row?.factory?.FACTORY_NAME
            case 'ORDER_NUMBER':
                return row?.materialOrder?.ORDER_NUMBER
            case 'difference':
                if (row?.DELIVERED_AMOUNT && row.DELIVERED_AMOUNT > row.AMOUNT) return row.DELIVERED_AMOUNT - row.AMOUNT
                return row?.DELIVERED_AMOUNT ? row.AMOUNT - row.DELIVERED_AMOUNT : 0 - row.AMOUNT
            case 'MOOT':
                return row?.production?.MOOT || row?.buffer?.MOOT
            default:
                return row[field]
        }
    }

    const handleRowClick = (item) => {
        setSelectedRow(item)
        setShowDeliveryModal(true)
    }

    const handleCloseDeliveryModal = () => {
        setShowDeliveryModal(false)
        setSelectedRow(null)
    }

    const handleSuccess = () => {
        setShowDeliveryModal(false)
        setSelectedRow(null)
        tableRef.current?.refresh()
    }

    const handleChange = () => {
        if (!selectedRow) return
        setSelectedRow(null)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters({
            ...filters,
            arrived: filters.arrived === 'true' ? true : false,
        })
    }

    return (
        <div className='material-deliveries'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Materjali saabumised')}</h2>
                    {/* <div className='inner-title--actions'>
                        <Button
                            label={t('Lisa saabumine')}
                            onClick={() => setShowDeliveryModal(true)}
                        />
                    </div> */}
                </div>
                <MaterialDeliveryFilters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={FactoryOrdersQuery}
                        queryKey={'factoryOrdersQuery'}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'ORDER_ID'}
                        statusColorEnabled={true}
                        activeRows={selectedRow ? [selectedRow] : []}
                        onRowClick={handleRowClick}
                        onFiltersChanged={handleChange}
                        useExtraFilters={true}
                        extraFilters={extraFilters}
                        rowKey={'ID'}
                    />
                </div>
            </div>

            <MaterialDeliveryModal
                show={showDeliveryModal}
                close={handleCloseDeliveryModal}
                onSuccess={handleSuccess}
                data={selectedRow}
            />
        </div>
    )
}

export default MaterialDeliveriesScreen