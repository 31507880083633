import { isValidDate } from './helpers'

const parseDate = (input) => {
    if (!input) return null
    return input.toISOString()
}

const formatDate = (input, time = false) => {
    if (!input || !isValidDate(input) || input < new Date('1970-1-2')) return ''
    if (time) {
        return input.toLocaleString('et-EE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
    }
    return input.toLocaleString('et-EE', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    })
}

const removeTags = (input) => {
    if ((input === null) || (input === '')) return ''
    return input.replace( /(<([^>]+)>)/ig, '')
}

const cleanMaterial = (material) => material?.replace(/\D+$/g, '')

const getUTCDate = (date) => {
    if (!date) return null
    return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
    )
}

export {
    parseDate,
    formatDate,
    removeTags,
    cleanMaterial,
    getUTCDate,
}