import React from 'react'
import ReactDOM from 'react-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './styles/colors.scss'
import './styles/classes.scss'
import './styles/global.scss'
import './styles/elements.scss'
import './styles/components.scss'
import './styles/header.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import 'react-activity/dist/Bounce.css'
import ErrorBoundary from './components/ErrorBoundary'
import { ProvideAuth } from './providers/Auth'


i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: 'et',
    fallbackLng: 'et',

    interpolation: {
      escapeValue: false
    }
  })

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
