import React, { useRef, useState } from 'react'
import Creatable from 'react-select/creatable'
import { components } from 'react-select'
import AsyncCreatable from 'react-select/async-creatable'

const Input = (props) => <components.Input {...props} isHidden={false} />

const CreatableInput = ({
  label,
  value,
  options,
  onInputChange,
  onChange,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  defaultInputValue,
  defaultValue,
  load,
  placeholder,
}) => {

  const selectRef = useRef()

  const [innerValue, setInnerValue] = useState(value);
  const [inputValue, setInputValue] = useState(value?.label ? value.label : '');

  const handleInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    if (action === 'input-blur') {
      console.log('input blur:', inputValue)
      setInputValue(innerValue ? innerValue.label : '')
    }

    // onInputChange => update inputValue
    else if (action === 'input-change') {
      setInputValue(inputValue)
    }
  }

  const handleChange = (option) => {
    setInnerValue(option)
    setInputValue(option ? option.label : '')
    if (onChange) onChange(option)
  }

  const handleFocus = () => innerValue && selectRef.current?.inputRef.select()

  return (
    <div className={`input-wrapper${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        {
          load ?
          <AsyncCreatable
            ref={selectRef}
            loadOptions={load}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            value={innerValue}
            inputValue={inputValue}
            onChange={onChange}
            defaultInputValue={defaultInputValue}
            defaultValue={defaultValue}
            onInputChange={handleInputChange}
            placeholder={placeholder}
            formatCreateLabel={(input) => `Kinnita: ${input}`}
            
          />
          :
          <Creatable
            ref={selectRef}
            onFocus={handleFocus}
            options={options}
            onChange={handleChange}
            value={innerValue}
            inputValue={inputValue}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            filterOption={filterOption}
            onInputChange={handleInputChange}
            placeholder={placeholder}
            formatCreateLabel={(input) => `Kinnita: ${input}`}
            components={{
              Input
            }}
          />
        }
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default CreatableInput